import { msalConfig } from "./config/msalConfig";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import "./i18n";
import React from "react";
import App from "./App";
import { createRoot } from "react-dom/client";

export const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
    localStorage.setItem("jwt", payload.accessToken);
  }
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <MsalProvider instance={msalInstance}>
    <App pca={msalInstance} />
  </MsalProvider>
);
