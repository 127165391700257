import React from "react";
import { useTranslation } from "react-i18next";
import Dropdown, { Option } from "react-dropdown";
import { Grid, Typography } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStoreState, useStoreActions } from "../../../../store/hooks";
import { useModalVisibility } from "../../../../feature/modal";
import { getFontAwesomeIcon } from "../../../../helpers/iconFabric";
import { getHeaderStyles } from "./styles";
import { RhimDialog } from "rhim-ui";
import { LanguageItem } from "../../../../feature/languages/interface/LanguageItem";
import { useMsal } from "@azure/msal-react";

const HeaderContent = () => {
  const { instance } = useMsal();
  const userData = useStoreState((state) => state.auth.userData);
  const languages = useStoreState((state) => state.languages.items).sort((a, b) => {
    if (a.description > b.description) return 1;
    else if (b.description > a.description) return -1;
    else return 0;
  });
  const setSelectedLanguage = useStoreActions((action) => action.languages.setSelectedAppLanguage);
  const { t, i18n } = useTranslation();
  const { modalProperties, setModalProperties } = useModalVisibility({
    visible: false
  });
  const classes = getHeaderStyles();

  const formatUserName = (userName: string) => {
    if (userName) {
      let aux = userName.split(" ");
      let formatedUserName = aux[0] + " " + aux[1][0] + ".";
      return formatedUserName;
    }
    return "";
  };

  return (
    <React.Fragment>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Dropdown
          className={classes.languages}
          controlClassName="dropdown-languages"
          options={languages.map(
            (l) =>
              ({
                label: l.isoCode.split("-")[0].toUpperCase(),
                value: l.isoCode
              } as Option)
          )}
          arrowOpen={
            <FontAwesomeIcon
              className="open-close-arrow"
              icon={getFontAwesomeIcon("fa-chevronUp")}
              size="sm"
            />
          }
          arrowClosed={
            <FontAwesomeIcon
              className="open-close-arrow"
              icon={getFontAwesomeIcon("fa-chevronDown")}
              size="sm"
            />
          }
          onChange={(arg: Option) => {
            let language = languages.find(
              (language: LanguageItem) => language.isoCode === arg.value
            );
            setSelectedLanguage(language);
          }}
          value={
            {
              label: i18n.language.split("-")[0].toUpperCase(),
              value: i18n.language
            } as Option
          }
          placeholder=""
        />
        <span className={classes.separationBar} />
        <Typography className={classes.userName} color="primary">
          {formatUserName(userData?.name.toString() || "")}
        </Typography>
        <ExitToApp
          color="primary"
          fontSize="small"
          className={classes.logoutButton}
          onClick={() => {
            setModalProperties({ visible: true });
          }}
        />
      </Grid>

      <RhimDialog
        isMobile={false}
        title={<p className="modal-title">{t("common.sign_out")}</p>}
        isOpen={modalProperties.visible}
        confirm={{
          title: t("common.sign_out").toUpperCase(),
          handleFunction: () => {
            instance.logout();
            setModalProperties({ visible: !modalProperties.visible });
          }
        }}
        cancel={{
          title: t("common.cancel").toUpperCase(),
          handleFunction: () => setModalProperties({ visible: !modalProperties.visible })
        }}
        content={<p className="modal-text">{t("messages.sign_out")}</p>}
      />
    </React.Fragment>
  );
};

export default HeaderContent;
