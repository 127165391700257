import * as _ from "lodash";
import { languageOptions } from "../../../helpers/util";
import { Dictionary } from "../../../models/Dictionary";
import FormLocales from "../../../models/Form/FormLocales";
import { FormSectionLocaleModel } from "../../../models/Section/FormSectionLocaleModel";
import { Section } from "../../../models/Section/Section";
import { FormOption, FormOptionLocales, ValidationResult } from "../../../repositories/interfaces";
import { CatalogItem, FormFieldLocaleModel } from "../../catalog/interface/CatalogItem";
import { LanguageItem } from "../../languages/interface/LanguageItem";
import { FormRule } from "../../rules/interface/rulesInterface";
import {
  deleteForm,
  duplicateForm,
  formHasAnswers,
  getFormById,
  getFormsByOwner,
  postForm,
  putForm,
  toggleFormActive
} from "../FormRepository";
import {
  convertFormToSectionsAndFields,
  fieldListToFormField,
  parseFormApiToStore,
  sectionToSectionForm
} from "../FormUtil";
import { Form } from "../interface/Form";
import { FormField } from "../interface/FormField";
import { FormSection } from "../interface/FormSection";
import { VerifyScoreWeight } from "./utils/verifyScoreWeight";

export const getUserForms = async () => {
  const forms = await getFormsByOwner();
  return _.orderBy(forms, ["enable", "createdAt"], ["desc", "desc"]);
};

export const updateForm = async (form: Form) => {
  return putForm(form);
};

export const saveForm = async (form: Form) => {
  return postForm(form);
};

export const getForm = async (formId: number) => {
  return await getFormById(formId);
};

export const toggleActive = async (formId: number) => {
  return await toggleFormActive(formId);
};

export const duplicate = async (formId: number, formTitle: string) => {
  return await duplicateForm(formId, formTitle);
};

export const delete_form = async (formId: number) => {
  return await deleteForm(formId);
};

export const form_has_answers = async (formId: number) => {
  const result = await formHasAnswers(formId);
  if (result.success) {
    return result.data;
  } else {
    return false;
  }
};

export const getNewForm = () => {
  return {
    formId: 0,
    title: "",
    formCode: "",
    description: "",
    defaultLanguage: "en-US",
    ownerEmail: "",
    formSections: [],
    enable: true,
    version: "",
    formRules: [],
    formUsers: [],
    formCoOwners: [],
    createdAt: "",
    formLocales: [],
    updateBy: "",
    maintainUpdatedAt: false
  };
};

export const convertSectionAttributes = (
  sections: Section[],
  fields: Dictionary<any>,
  languages: LanguageItem[],
  defaultLanguage: string,
  t?: Function
) => {
  return sections.map((section: Section, index: number) => {
    const formSection = sectionToSectionForm(section, index, t) as any;
    formSection.formSectionLocales.forEach((locale: FormSectionLocaleModel) => {
      let isoCodeLocale = languages.find((language) => language.id === locale.cultureId)?.isoCode;
      if (isoCodeLocale !== defaultLanguage) {
        formSection[`title-${isoCodeLocale}`] = locale.title;
      } else {
        formSection["title"] = locale.title;
      }
    });
    const fieldList = fields[section.uuid];
    const formFields = fieldList ? fieldListToFormField(fieldList, t) || [] : [];
    formSection.formFields = formFields || [];
    return formSection;
  });
};

export const convertFormAttributes = (form: Form) => {
  const { formSections } = form;
  form.formUsers = form.formUsers || [];
  return convertFormToSectionsAndFields(formSections);
};

export const getFieldsInDescription = (fields: Dictionary<CatalogItem[]>): CatalogItem[] => {
  const convertedFields = Object.values(fields);
  const allFields = convertedFields.flatMap((arr) => arr);
  const array = [];
  const fieldsInDescription = allFields.filter((field) => {
    const hasPreference = field.preferences.some(
      (preference) =>
        preference.key === "Fieldsinformdescription" &&
        (preference.value === true || preference.value === "true")
    );
    return hasPreference;
  });

  return fieldsInDescription;
};

export const convertForm = (form: Form, languageList: LanguageItem[]) => {
  return parseFormApiToStore(form, languageList);
};

export const getFormLanguageList = (form: Form, languageList: LanguageItem[]): LanguageItem[] => {
  let formLanguageList: LanguageItem[] = [];
  let formLocales: FormLocales[] = form.formLocales;

  if (formLocales.length > 0) {
    formLanguageList = languageList.filter((language: LanguageItem) =>
      formLocales.find((locale: FormLocales) => locale.cultureId === language.id)
    );
  } else {
    let formDefaultLanguage = form.defaultLanguage;
    formLanguageList = languageList.filter(
      (language: LanguageItem) => language.isoCode === formDefaultLanguage
    );
  }

  return formLanguageList;
};

export const getFormWithDefaultFormLocale = (form: Form, languageList: LanguageItem[]) => {
  let defaultLanguageIsoCode = form.defaultLanguage;
  let defaultLanguage = languageList.find(
    (language: LanguageItem) => language.isoCode === defaultLanguageIsoCode
  );
  let defaultLocale: FormLocales[] = [
    {
      formId: 0,
      cultureId: defaultLanguage?.id ?? 2,
      title: "",
      description: ""
    }
  ];
  const formUpdated = { ...form, formLocales: defaultLocale };
  return formUpdated;
};

export const validateForm = (
  form: Form,
  sections: Section[],
  fields: Dictionary<CatalogItem[]>,
  t: Function
) => {
  let validateResult: ValidationResult = {
    title: t("common.success"),
    success: true,
    message: `${t("common.ok").toUpperCase()}!`
  };

  validateResult = verifyEqualSectionName(form, validateResult, t);
  validateResult = verifyEqualFieldId(form, validateResult, t);
  validateResult = verifyScoreWeight(sections, fields, validateResult, t);
  validateResult = verifySingleOptionFieldsWithNoOptions(form, validateResult, t);
  validateResult = verifyDropdownFieldsWithNoOptions(form, validateResult, t);
  validateResult = verifyMultiphotoPreferences(form, validateResult, t);
  validateResult = verifyBarOrQrCodeWithNoOptions(form, validateResult, t);
  return validateResult;
};

const verifyDropdownFieldsWithNoOptions = (
  form: Form,
  validateResult: ValidationResult,
  t: Function
) => {
  const errorTitle = t("messages.error_field_no_options");
  const errorMessage = t("messages.error_field_dropdown.title");

  const fields = form.formSections.flatMap((section) => section.formFields);

  const hasError = fields.some((field) => field.type === "dropdown" && field.options.length === 0);

  if (hasError) {
    validateResult.title = errorTitle;
    validateResult.message = errorMessage;
    validateResult.success = false;
  }

  return validateResult;
};

interface ErrorFieldsRepeatedIdArgs {
  fieldIdA: number;
  sectionIdA: number;
  fieldIdB: number;
  sectionIdB: number;
}

const errorFieldsRepeatedId = (
  { fieldIdA, sectionIdA, fieldIdB, sectionIdB }: ErrorFieldsRepeatedIdArgs,
  t: Function
) => {
  return t("messages.error_fields_repeated_id", {
    fieldIdA,
    sectionIdA,
    fieldIdB,
    sectionIdB
  });
};

export const verifyEqualFieldId = (
  form: Form,
  validateResult: ValidationResult,
  t: Function
): ValidationResult => {
  const visitedFieldIds = new Set();

  for (const section of form.formSections) {
    for (const field of section.formFields) {
      if (!field.fieldId) {
        continue;
      }

      if (visitedFieldIds.has(field.fieldId)) {
        validateResult.success = false;
        validateResult.title = t("messages.error_field_id");
        validateResult.message = errorFieldsRepeatedId(
          {
            fieldIdA: field.id,
            sectionIdA: section.id,
            fieldIdB: field.id,
            sectionIdB: section.id
          },
          t
        );
        return validateResult;
      }

      visitedFieldIds.add(field.fieldId);
    }
  }

  return validateResult;
};

export const verifyEqualSectionName = (
  form: Form,
  validateResult: ValidationResult,
  t: Function
) => {
  const errorSectionName = t("messages.error_section_repeated_name");
  let titles: string[] = [];

  form.formSections.forEach((section) => {
    if (section.title === "" || section.title === undefined) {
      if (validateResult.success) {
        validateResult.title = t("messages.error_section_name");
        validateResult.success = false;
        validateResult.message = section.sectionId;
      }
    }
    titles.push(section.title);
  });
  if (new Set(titles).size !== titles.length)
    if (validateResult.success) {
      validateResult.title = t("messages.error_section_name");
      validateResult.success = false;
      validateResult.message = errorSectionName;
    }
  return validateResult;
};
export default function verifyScoreWeight(
  sections: Section[],
  fields: Dictionary<CatalogItem[]>,
  validateResult: ValidationResult,
  t: Function
) {
  const errorMessage = t("messages.error_field_weight");
  const verifyScoreWeight = new VerifyScoreWeight(fields);
  const isInvalidSection = sections.some((section) => !verifyScoreWeight.checkSection(section));

  if (isInvalidSection) {
    return {
      success: false,
      title: errorMessage,
      message: t("messages.error_all_field_weight")
    };
  }

  return validateResult;
}

const verifyMultiphotoPreferences = (form: Form, validateResult: ValidationResult, t: Function) => {
  const errorTitle = t("messages.error_multiphotos_preferences");
  const errorMessage = t("messages.error_invalid_preference");

  const fields = form.formSections.flatMap((section) => section.formFields);

  const hasError = fields.some((field) => {
    if (field.type === "pictureFile") {
      const preferences = field.preferences ? JSON.parse(field.preferences) : [];
      const minMaxError = preferences["max"] === null || +preferences["max"] < +preferences["min"];
      return minMaxError;
    }
  });

  if (hasError) {
    validateResult.title = errorTitle;
    validateResult.message = errorMessage;
    validateResult.success = false;
  }

  return validateResult;
};

const verifyBarOrQrCodeWithNoOptions = (
  form: Form,
  validateResult: ValidationResult,
  t: Function
) => {
  const errorTitle = t("messages.error_field_preference_no_options");
  const errorMessage = t("messages.error_qrbarcode_field_preference_no_options");
  const fields = form.formSections.flatMap((section) => section.formFields);

  const hasError = fields.some((field) => {
    if (field.type === "qrbarcode") {
      const preferences = field.preferences ? JSON.parse(field.preferences) : [];
      const isBarOrQrCodeWithNoOptions = preferences["qrorbarcode"] === "";
      return isBarOrQrCodeWithNoOptions;
    }
  });

  if (hasError) {
    validateResult.title = errorTitle;
    validateResult.message = errorMessage;
    validateResult.success = false;
  }

  return validateResult;
};

export const compareSections = (sections: FormSection[], pastSections: FormSection[]) => {
  const newSectionLocalesMap = new Map<string, FormSectionLocaleModel[]>();
  const newTitleSectionMap = new Map<string, {}>();
  const newFieldLocalesMap = new Map<string, FormFieldLocaleModel[]>();
  const newOptionLocalesMap = new Map<string, FormOptionLocales[]>();
  const newFieldOptionLocalesMap = new Map<string, FormOptionLocales[]>();
  const newFieldPreferences = new Map<string, string>();

  const olderSectionLocalesMap = new Map<string, FormSectionLocaleModel[]>();
  const olderTitleSectionMap = new Map<string, {}>();
  const olderFieldLocalesMap = new Map<string, FormFieldLocaleModel[]>();
  const olderOptionLocalesMap = new Map<string, FormOptionLocales[]>();
  const olderFieldOptionLocalesMap = new Map<string, FormOptionLocales[]>();
  const olderFieldPreferences = new Map<string, string>();

  const removeLocales = (
    newSections: FormSection[],
    formSectionLocalesMap: Map<string, FormSectionLocaleModel[]>,
    titleSectionMap: Map<string, {}>,
    formFieldLocalesMap: Map<string, FormFieldLocaleModel[]>,
    formOptionLocalesMap: Map<string, FormOptionLocales[]>,
    formFieldOptionLocalesMap: Map<string, FormOptionLocales[]>,
    formFieldPreferences: Map<string, string | null>
  ) => {
    newSections.forEach((section: FormSection | any, sectionIndex: number) => {
      formSectionLocalesMap.set(`${sectionIndex}`, [...section.formSectionLocales]);
      section.formSectionLocales = [];
      let languageTitles: { [x: string]: string };
      languageTitles = {
        title: section.title
      };
      languageOptions.forEach((language) => {
        const titleLanguage = `title-${language}`;
        const value = section[titleLanguage];
        if (typeof value == "string") {
          languageTitles[titleLanguage] = value;
          delete section[titleLanguage];
        }
      });
      titleSectionMap.set(`${sectionIndex}`, languageTitles);
      section.title = "";
      section.formFields.forEach((field: FormField, fieldIndex: number) => {
        formFieldLocalesMap.set(`${sectionIndex}-${fieldIndex}`, [...field.formFieldLocales]);
        field.formFieldLocales = [];
        formFieldPreferences.set(`${sectionIndex}-${fieldIndex}`, field.preferences);
        field.preferences = "";
        field.options.forEach((option: FormOption, optionIndex: number) => {
          formOptionLocalesMap.set(`${sectionIndex}-${fieldIndex}-${optionIndex}`, [
            ...option.formOptionLocales
          ]);
          option.formOptionLocales = [];
        });
        field.fieldOptions.forEach((option: FormOption, optionIndex: number) => {
          formFieldOptionLocalesMap.set(`${sectionIndex}-${fieldIndex}-${optionIndex}`, [
            ...option.formOptionLocales
          ]);
          option.formOptionLocales = [];
        });
      });
    });
  };

  const addLocales = (
    newSections: FormSection[],
    formSectionLocalesMap: Map<string, FormSectionLocaleModel[]>,
    titleSectionMap: Map<string, {}>,
    formFieldLocalesMap: Map<string, FormFieldLocaleModel[]>,
    formOptionLocalesMap: Map<string, FormOptionLocales[]>,
    formFieldOptionLocalesMap: Map<string, FormOptionLocales[]>,
    formFieldPreferences: Map<string, string | null>
  ) => {
    formSectionLocalesMap.forEach((value: FormSectionLocaleModel[], key: string) => {
      const sectionId = parseInt(key);
      newSections[sectionId].formSectionLocales = value;
    });
    titleSectionMap.forEach((value: {}, key: string) => {
      const sectionId = parseInt(key);
      newSections[sectionId] = {
        ...newSections[sectionId],
        ...value
      };
    });
    formFieldLocalesMap.forEach((value: FormFieldLocaleModel[], key: string) => {
      const sectionAndField = key.split("-");
      newSections[parseInt(sectionAndField[0])].formFields[
        parseInt(sectionAndField[1])
      ].formFieldLocales = value;
    });
    formOptionLocalesMap.forEach((value: FormOptionLocales[], key: string) => {
      const sectionAndFieldAndOption = key.split("-");
      newSections[parseInt(sectionAndFieldAndOption[0])].formFields[
        parseInt(sectionAndFieldAndOption[1])
      ].options[parseInt(sectionAndFieldAndOption[2])].formOptionLocales = value;
    });
    formFieldOptionLocalesMap.forEach((value: FormOptionLocales[], key: string) => {
      const sectionAndFieldAndOption = key.split("-");
      newSections[parseInt(sectionAndFieldAndOption[0])].formFields[
        parseInt(sectionAndFieldAndOption[1])
      ].fieldOptions[parseInt(sectionAndFieldAndOption[2])].formOptionLocales = value;
    });
    formFieldPreferences.forEach((value: string | null, key: string) => {
      const sectionAndField = key.split("-");
      newSections[parseInt(sectionAndField[0])].formFields[
        parseInt(sectionAndField[1])
      ].preferences = value;
    });
  };

  removeLocales(
    sections,
    newSectionLocalesMap,
    newTitleSectionMap,
    newFieldLocalesMap,
    newOptionLocalesMap,
    newFieldOptionLocalesMap,
    newFieldPreferences
  );
  removeLocales(
    pastSections,
    olderSectionLocalesMap,
    olderTitleSectionMap,
    olderFieldLocalesMap,
    olderOptionLocalesMap,
    olderFieldOptionLocalesMap,
    olderFieldPreferences
  );

  const sectionsAsText = JSON.stringify(sections);
  const pastSectionsAsText = JSON.stringify(pastSections);
  const isSectionsChanged = sectionsAsText !== pastSectionsAsText;

  addLocales(
    sections,
    newSectionLocalesMap,
    newTitleSectionMap,
    newFieldLocalesMap,
    newOptionLocalesMap,
    newFieldOptionLocalesMap,
    newFieldPreferences
  );
  addLocales(
    pastSections,
    olderSectionLocalesMap,
    olderTitleSectionMap,
    olderFieldLocalesMap,
    olderOptionLocalesMap,
    olderFieldOptionLocalesMap,
    olderFieldPreferences
  );

  return isSectionsChanged;
};

export const compareRules = (rules: FormRule[], pastRules: FormRule[]) => {
  const newRulesTitles: string[] = [];
  const olderRulesTitles: string[] = [];

  const deleteTitleFromRule = (newRules: FormRule[], titles: string[]) => {
    newRules.forEach((rule) => {
      titles.push(rule.name);
      rule.name = "";
    });
  };

  const addTitleToRule = (newRules: FormRule[], titles: string[]) => {
    newRules.forEach((rule, index) => {
      rule.name = titles[index];
    });
  };

  deleteTitleFromRule(rules, newRulesTitles);
  deleteTitleFromRule(pastRules, olderRulesTitles);

  const rulesAsText = JSON.stringify(rules);
  const pastRulesAsText = JSON.stringify(pastRules);
  const isRulesChanged = rulesAsText !== pastRulesAsText;

  addTitleToRule(rules, newRulesTitles);
  addTitleToRule(pastRules, olderRulesTitles);

  return isRulesChanged;
};

export const verifySingleOptionFieldsWithNoOptions = (
  form: Form,
  validateResult: ValidationResult,
  t: Function
) => {
  const errorTitle = t("messages.error_field_no_options");
  const errorMessage = t("messages.error_single_option_no_options");

  const fields = form.formSections.flatMap((section) => section.formFields);

  const hasError = fields.some((field) => field.type === "boolean" && field.options.length < 2);

  if (hasError) {
    validateResult.title = errorTitle;
    validateResult.message = errorMessage;
    validateResult.success = false;
  }

  return validateResult;
};
