import React from "react";
import "./IconButton.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IconButtonProps {
  icon: any;
  size?: any;
  text?: string;
  color?: string;
  textSide?: string;
  customStyle?: any;
  onClickFunction?: () => any;
}

const IconButton = (props: IconButtonProps) => {
  const { icon, size, text, color, textSide, onClickFunction, customStyle } = props;
  if (textSide === "left") {
    return (
      <div className="icon-button-atom" style={{ ...customStyle }} onClick={onClickFunction}>
        {text}
        {size !== undefined ? (
          <FontAwesomeIcon
            icon={icon}
            color={color}
            className="font-awesome-icon-button"
            size={size}
          />
        ) : (
          <FontAwesomeIcon
            icon={icon}
            color={color}
            className="font-awesome-icon-button"
            size="lg"
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="icon-button-atom" style={{ ...customStyle }} onClick={onClickFunction}>
        {size !== undefined ? (
          <FontAwesomeIcon
            icon={icon}
            color={color}
            className="font-awesome-icon-button"
            size={size}
          />
        ) : (
          <FontAwesomeIcon
            icon={icon}
            color={color}
            className="font-awesome-icon-button"
            size="lg"
          />
        )}
        {text}
      </div>
    );
  }
};

export default IconButton;
