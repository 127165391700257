import axios from "axios";
import { getJwt } from "../../helpers/LoginHelper";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { loginRequest } from "../../config/msalConfig";
import { msalInstance } from "../..";

let retryCount = 0;

declare global {
  interface Window {
    env: any;
  }
}

const axiosInstance = axios.create({
  baseURL: window.env.apiURL,
  responseType: "json",
  headers: { "content-type": "application/json" },
  timeout: 80000
});

const requestHandler = (request: any) => {
  const token = getJwt();
  request.headers.Authorization = "Bearer " + token;
  return request;
};

axiosInstance.interceptors.request.use((request) => requestHandler(request));

const refreshAuthLogic = (error: any) => {
  const instance = msalInstance;
  if (retryCount < 1) {
    retryCount++;
    return new Promise<void>((resolve) => {
      instance.acquireTokenSilent(loginRequest).then((tokenResponse) => {
        error.config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
        localStorage.setItem("jwt", tokenResponse.accessToken);
        axiosInstance.request(error.config);
        resolve();
      });
    });
  } else {
    return new Promise<void>(() => {
      retryCount = 0;
      instance.logoutRedirect();
    });
  }
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
  statusCodes: [401]
});

export const v1 = (path: string) => {
  return window.env.apiV1 + path;
};

export const v2 = (path: string) => {
  return window.env.apiV2 + path;
};

export default axiosInstance;
