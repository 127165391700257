import React from "react";
import "./RangeSliderPreference.scss";
import TitleColumn from "../../atoms/TitleColumn/TitleColumn";
import { ItemPreference } from "../../../../repositories/interfaces";
import { CatalogItem } from "../../../../feature/catalog/interface/CatalogItem";
import { useTranslation } from "react-i18next";
import { Slider } from "@material-ui/core";
import { InfoRounded } from "@material-ui/icons";

interface RangeSliderPreferenceProps {
  title: string;
  onChangeFunction: (preference: ItemPreference) => void;
  preference: ItemPreference;
  field?: CatalogItem;
}

const RangeSliderPreference = (props: RangeSliderPreferenceProps) => {
  const { t } = useTranslation();
  const { title, onChangeFunction, preference } = props;
  let min = 1;

  let fieldPreferenceOptions = props.field?.preferences.find((preference: ItemPreference) =>
    preference.key.includes("Isnumberinteger")
  );
  const isFieldNumber = props.field?.type === "number";
  const fieldNumberPreferenceIsDecimal = fieldPreferenceOptions?.value === "false";
  if (isFieldNumber && fieldNumberPreferenceIsDecimal) {
    min = 3;
    if (Number(preference.value) < min) {
      preference.value = min.toString();
      onChangeFunction(preference);
    }
  }
  return (
    <section id="photos-number-preference">
      <div className="photos-number-preference-title">
        <TitleColumn text={t(title)} />
        {isFieldNumber ? (
          <div className="menu-item">
            <InfoRounded className="info" />
            <div className="tooltip-action tooltip-action-builder">
              <span>{t("messages.tooltip_decimal_minimum_alert")}</span>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="photos-number-preference-body">
        <Slider
          value={Number(preference.value)}
          onChange={(event: any, value: any) => {
            preference.value = value.toString();
            onChangeFunction(preference);
          }}
          min={min}
          valueLabelDisplay="auto"
        />
      </div>
    </section>
  );
};

export default RangeSliderPreference;
