import React, { useState, useEffect } from "react";
import "./RadioButtonBuilder.scss";

interface RadioButtonBuilderProps {
  title: string;
  mandatory: boolean;
  optionsArray: any[];
}

const RadioButtonBuilder = (props: RadioButtonBuilderProps) => {
  const { title, mandatory, optionsArray } = props;
  const [options, setOptions] = useState([""]);

  useEffect(() => {
    if (optionsArray) {
      const optionsValue = optionsArray.map((o: any) => o.option);
      setOptions(optionsValue);
    }
  }, [optionsArray]);

  return (
    <section className="radio-button-builder field-item">
      <h2 className="title">
        {title}
        {mandatory && <span className="mandatory-signal">* </span>}
      </h2>
      <article className="content">
        <div className="item">
          <input
            className="text"
            readOnly
            placeholder="Option A"
            value={
              options[0] !== null && options[0] !== undefined && options[0] !== "" ? options[0] : ""
            }
          />
        </div>
        <div className="item">
          <input
            className="text"
            readOnly
            placeholder="Option B"
            value={
              options[1] !== null && options[1] !== undefined && options[1] !== "" ? options[1] : ""
            }
          />
        </div>
      </article>
    </section>
  );
};

export default RadioButtonBuilder;
