import { action } from "easy-peasy";
import { LoadingModelInterface } from "./LoadingModelInterface";

const loadingModel: LoadingModelInterface = {
  isLoading: false,
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  })
};

export default loadingModel;
