import React from "react";
import { useStoreActions } from "../../../store/hooks";
import { Form } from "../interface/Form";
import NewFormPage from "./NewFormPage";

const NewFormContainer = () => {
  const createForm = useStoreActions((action) => action.form.createForm);
  const saveForm = async (form: Form) => {
    const model: Form = form;
    model.formSections = [];
    const newForm = await createForm(model);
    return newForm;
  };

  return <NewFormPage saveForm={saveForm} createForm={createForm} />;
};

export default NewFormContainer;
