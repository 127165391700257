import { LanguageItem } from "./interface/LanguageItem";
import FormLocales from "../../models/Form/FormLocales";

export const arrayContains = (array: LanguageItem[], item: LanguageItem) => {
  return array.find((arrayItem) => item.id === arrayItem.id) !== undefined;
};

export const getSortedLanguageItems = (activeItems: LanguageItem[], allItems: LanguageItem[]) => {
  const sortedItems: LanguageItem[] = activeItems.slice();
  allItems.forEach((item) => {
    if (!arrayContains(activeItems, item)) {
      sortedItems.push(item);
    }
  });
  return sortedItems;
};

export const existsLanguageInTranslations = (
  item: LanguageItem,
  formLocales: FormLocales[] | undefined
) => formLocales?.find((locale) => locale.cultureId === item.id) !== undefined;
