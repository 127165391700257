import React from "react";
import "./InputTextBuilder.scss";
import { InputTextBuilderProps } from "../../../../repositories/interfaces";

const InputTextBuilder = (props: InputTextBuilderProps) => {
  const { title, maxLength, defaultValue, mandatory } = props;

  return (
    <div className="short-text-builder field-item">
      <h2 className="title">
        {title}
        {mandatory && <span className="mandatory-signal">* </span>}
      </h2>
      <input className="content" type="text" maxLength={maxLength} value={defaultValue} readOnly />
    </div>
  );
};

export default InputTextBuilder;
