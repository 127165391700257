import React from "react";
import "./UserList.scss";
import { UserListItem } from "../../atoms";
import { FormUser } from "../../../../feature/form/interface/FormUser";

export interface UserListProps {
  items: FormUser[];
  formEmailsType: string;
}

const UserList = (props: UserListProps) => {
  const { items } = props;

  return (
    <div className="form-associated-users-container">
      {items?.map((formUser: any, index: number) => {
        return <UserListItem user={formUser} formEmailsType={props.formEmailsType} key={index} />;
      })}
    </div>
  );
};

export default UserList;
