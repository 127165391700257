import { action } from "easy-peasy";
import { NotificationModelInterface } from "./NotificationModelInterface";
import { ModalType } from "../../modal/enum/ModalType";

const notificationModel: NotificationModelInterface = {
  properties: {
    title: "",
    confirmText: "",
    message: "",
    visible: false,
    type: ModalType.Info
  },
  setProperties: action((state, payload) => {
    state.properties = payload;
  })
};

export default notificationModel;
