import React from "react";
import "./TitleColumn.scss";

export interface TitleColumnProps {
  text: string;
}
const TitleColumn = (props: TitleColumnProps) => {
  const { text } = props;
  return <p className="TitleColumn">{text}</p>;
};

export default TitleColumn;
