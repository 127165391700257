import React from "react";
import "./TextareaBuilder.scss";
import { TextareaBuilderProps } from "../../../../repositories/interfaces";

const TextareaBuilder = (props: TextareaBuilderProps) => {
  const { title } = props;
  const { maxLines } = props;
  const { maxLength } = props;
  const { defaultValue } = props;
  const { mandatory } = props;
  return (
    <div className="input-textarea-builder field-item">
      <h2 className="title">
        {title}
        {mandatory && <span className="mandatory-signal">* </span>}
      </h2>
      <textarea
        className="content"
        rows={maxLines}
        maxLength={maxLength}
        value={defaultValue}
        readOnly
      />
    </div>
  );
};

export default TextareaBuilder;
