import { FormField } from "../feature/form/interface/FormField";
import { Section } from "../models/Section/Section";
import { CatalogItem } from "../feature/catalog/interface/CatalogItem";
import { LanguageItem } from "../feature/languages/interface/LanguageItem";

export const languageOptions = ["de-AT", "en-US", "es-ES", "fr-FR", "pt-BR", "zh-CHS", "tr-TR", "cs-CZ", "pl-PL", "sl-SI", "sv-SE", "sq-KV"];

export const getPlaceholderLanguage = (selectedLanguage: string | undefined) => {
  switch (selectedLanguage) {
    case "de-AT":
      return "languages.german";
    case "en-US":
      return "languages.english";
    case "es-ES":
      return "languages.spanish";
    case "fr-FR":
      return "languages.french";
    case "pt-BR":
      return "languages.portuguese";
    case "zh-CHS":
      return "languages.mandarin";
    case "tr-TR":
      return "languages.turkish";
    case "cs-CZ":
      return "languages.czech";
    case "pl-PL":
      return "languages.polish";
    case "sl-SI":
      return "languages.slovenian";
    case "sv-SE":
      return "languages.swedish";
    case "sq-KV":
      return "languages.kosovo_albanian";
    default:
      return "languages.english";
  }
};

export const getUniqueId = (type: string, title: string, allIds: string[]) => {
  const prefix = getIdPrefixByType(type);
  let uniqueId = prefix;

  title = title.toLocaleLowerCase();
  const titlesSplit = title.split(" ").filter(Boolean);

  const numWordsTitle = titlesSplit.length <= 3 ? titlesSplit.length : 3;
  for (let i = 0; i < numWordsTitle; i++) {
    const sizeWord = titlesSplit[i].length <= 5 ? titlesSplit[i].length : 5;
    uniqueId += "_" + titlesSplit[i].substring(0, sizeWord);
  }

  if (allIds.length > 0 && allIds.includes(uniqueId)) {
    let count = 1;
    do {
      count++;
    } while (allIds.includes(uniqueId + "_" + count));
    if (count > 1) {
      uniqueId += "_" + count;
    }
  }

  return uniqueId;
};

export const getIdPrefixByType = (type?: string) => {
  switch (type) {
    case "datetime":
      return "dh";
    case "dropdown":
      return "dpd";
    case "number":
      return "num";
    case "pictureFile":
      return "ph";
    case "qrbarcode":
      return "qbc";
    case "signature-qrcode":
      return "sqc";
    case "boolean":
      return "so";
    case "text":
      return "st";
    case "textarea":
      return "lt";
    case "dropdown-campaign":
      return "cmp";
    case "dropdown-customer":
      return "ctm";
    case "dropdown-campaign-end-reason":
      return "er";
    case "dropdown-equipment":
      return "eqp";
    case "check-list":
      return "ckl";
    default:
      return "sc";
  }
};

export const getAllFieldIds = (fields: FormField[]) => {
  return fields.map((field) => {
    return field.fieldId;
  });
};

export const getAllSectionIds = (sections: Section[]) => {
  return sections.map((section) => {
    return section.sectionId;
  });
};

export const getTypeByFieldId = (fields: CatalogItem[], fieldId: string) =>
  fields.filter((f) => f.fieldId === fieldId)[0]?.type;

export const addSectionLocalesToNewSection = (
  section: Section,
  languageList: LanguageItem[],
  defaultLanguage: LanguageItem | undefined,
  t: Function
) => {
  section.formSectionLocales = [];
  languageList.forEach((language: LanguageItem) => {
    if (defaultLanguage?.id === language.id) {
      section.formSectionLocales.push({
        cultureId: language.id,
        formSectionId: 0,
        title: t(section.title)
      });
    } else {
      section.formSectionLocales.push({
        cultureId: language.id,
        formSectionId: 0,
        title: ""
      });
    }
  });
  return section;
};
