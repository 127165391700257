import api, { v1 } from "./API/Axios.api";

export const getMRK = async (language: string) => {
  let response: any;

  response = await api
    .get(v1("ComponentCatalog/mrk"), { headers: { languageIsoCode: language } })
    .catch((error) => console.log(error));

  return response.data;
};
