import React, { useEffect } from "react";
import FormLocales from "../../../../models/Form/FormLocales";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import "./FormName.scss";

const FormName = () => {
  const form = useStoreState((state) => state.form.form!);
  useEffect(() => {}, [form]);
  const updateForm = useStoreActions((actions) => actions.form.updateForm);
  const isSaved = useStoreState((state) => state.form.isSaved);
  const currentLanguage = useStoreState((state) => state.languages.selectedLanguage);

  const updateFormTitle = (newTitle: string) => {
    let localeToUpdate = form.formLocales.find(
      (locale: FormLocales) => locale.cultureId === currentLanguage?.id
    );
    if (localeToUpdate) {
      localeToUpdate.title = newTitle;
    }

    form.title = newTitle;
    updateForm(form);
  };

  return (
    <span>
      <input
        className="form-name-input"
        defaultValue={form.title}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          updateFormTitle(event.target.value)
        }
        size={Math.min(form.title.length, 50)}
      />
    </span>
  );
};

export default FormName;
