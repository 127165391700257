export interface MenuItem {
  id: string;
  label: string;
  icon: string;
  uri: string;
  isSingleMenu: boolean;
  items: [];
}

export const getMenuOptions = () => {
  let menuOptions: MenuItem[] = [
    {
      icon: "new_form",
      id: "0",
      isSingleMenu: true,
      items: [],
      label: "pages.new_form",
      uri: "/NewForm"
    },
    {
      icon: "my_forms",
      id: "1",
      isSingleMenu: true,
      items: [],
      label: "pages.my_forms",
      uri: "/MyForms"
    },
    {
      icon: "feedback",
      id: "2",
      isSingleMenu: true,
      items: [],
      label: "pages.feedback",
      uri: window.env.feedbackUrl
    }
  ];
  return menuOptions;
};

export const isMenuItemActive = (selectedMenuItem = "", itemId = "") => {
  return selectedMenuItem === itemId || selectedMenuItem.split(".")[0] === itemId;
};
