import {
  faSignOutAlt,
  faCalendarAlt,
  faImage,
  faCaretSquareDown,
  faUser,
  faDotCircle,
  faAlignLeft,
  faGripLines,
  faEdit,
  faQuestion,
  faPlusSquare,
  faChevronUp,
  faChevronDown,
  faPlus,
  faTrashAlt,
  faTrash,
  faChevronCircleDown,
  faChevronCircleUp,
  faBars,
  faHome,
  faSignInAlt,
  faTimes,
  faImages,
  faFileAlt,
  faSearch,
  faEllipsisH,
  faCheck,
  faGlobeAmericas,
  faUserEdit,
  faCog,
  faArrowRight,
  faAngleLeft,
  faPencilAlt,
  faLink,
  faSortNumericUp,
  faInfoCircle,
  faTools,
  faQrcode,
  faCalendarPlus,
  faFillDrip,
  faIndustry,
  faBan,
  faBullhorn,
  faAddressCard,
  faPlay,
  faStop,
  faFileExport,
  faClone
} from "@fortawesome/free-solid-svg-icons";

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface IconMapProps {
  [key: string]: IconDefinition;
}
const iconMap: IconMapProps = {
  "fa-edit": faEdit,
  "fa-logout": faSignOutAlt,
  "fa-login": faSignInAlt,
  "fa-datetime": faCalendarAlt,
  "fa-photo": faImage,
  "fa-dropdown": faCaretSquareDown,
  "fa-author": faUser,
  "fa-radio": faDotCircle,
  "fa-text": faGripLines,
  "fa-textarea": faAlignLeft,
  "fa-plus-square": faPlusSquare,
  "fa-chevronUp": faChevronUp,
  "fa-chevronDown": faChevronDown,
  "fa-plus": faPlus,
  "fa-trashAlt": faTrashAlt,
  "fa-trash": faTrash,
  "fa-sortUp": faChevronCircleUp,
  "fa-sortDown": faChevronCircleDown,
  "fa-bars": faBars,
  "fa-home": faHome,
  "fa-times": faTimes,
  "fa-image": faImages,
  "fa-forms": faFileAlt,
  "fa-search": faSearch,
  "fa-ellipsisH": faEllipsisH,
  "fa-check": faCheck,
  "fa-globe": faGlobeAmericas,
  "fa-association": faUserEdit,
  "fa-config": faCog,
  "fa-arrowRight": faArrowRight,
  "fa-angleLeft": faAngleLeft,
  "fa-pencil": faPencilAlt,
  "fa-link": faLink,
  "fa-number": faSortNumericUp,
  "fa-infoCircle": faInfoCircle,
  "fa-tools": faTools,
  "fa-qrcode": faQrcode,
  "fa-campaign": faCalendarPlus,
  "fa-equipment": faFillDrip,
  "fa-customer": faIndustry,
  "fa-endingReason": faBan,
  "fa-bullhorn": faBullhorn,
  "fa-address-card": faAddressCard,
  "fa-play": faPlay,
  "fa-stop": faStop,
  "fa-file-export": faFileExport,
  "fa-clone": faClone
};
export const getFontAwesomeIcon = (title: string) => {
  return iconMap[title] || faQuestion;
};
