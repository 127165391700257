import React from "react";
import "../styles/Rules.scss";
import { DropdownItemProps } from "../interface/rulesInterface";
import Dropdown from "react-dropdown";
import { Icon } from "@material-ui/core";

const DropdownItem = (props: DropdownItemProps) => {
  const { options, value, onChange } = props;

  return (
    <Dropdown
      className="rule-dropdown"
      controlClassName="rule-dropdown-control"
      arrowClassName="rule-dropdown-arrow"
      options={options}
      value={value}
      placeholder={""}
      arrowOpen={<Icon>expand_less</Icon>}
      arrowClosed={<Icon>expand_more</Icon>}
      onChange={onChange}
    />
  );
};

export default DropdownItem;
