import "./DataList.scss";

import { GraphUser } from "../../../../models/Graph/GraphUser";
import React from "react";

export interface DataListProps {
  items: GraphUser[];
  visibility: boolean;
  render: (items: GraphUser[]) => JSX.Element[];
}

const DataList = (props: DataListProps) => {
  const { render, items, visibility } = props;
  const visibilityClass = visibility && items.length > 0 ? "visible" : "invisible";
  return <ul className={"data-list " + visibilityClass}>{render(items)}</ul>;
};

export default DataList;
