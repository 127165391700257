import React from "react";
import "./InputNumberBuilder.scss";
import { InputNumberBuilderProps } from "../../../../repositories/interfaces";

const InputNumberBuilder = (props: InputNumberBuilderProps) => {
  const { title, maxLength, defaultValue, mandatory } = props;
  return (
    <div className="numeric-builder field-item">
      <h2 className="title">
        {title}
        {mandatory && <span className="mandatory-signal">* </span>}
      </h2>
      <input
        className="content"
        type="number"
        maxLength={maxLength}
        value={defaultValue}
        readOnly
      />
    </div>
  );
};

export default InputNumberBuilder;
