import React from "react";
import "./YesNoPreference.scss";
import { ItemPreference } from "../../../../repositories/interfaces";
import TitleColumn from "../../atoms/TitleColumn/TitleColumn";
import { useTranslation } from "react-i18next";
import { RhimButton } from "rhim-ui";
import Subtitle from "./Subtitle";

interface YesNoPreferenceProps {
  title: string;
  preference: ItemPreference;
  changePreference: (preference: ItemPreference) => void;
  selectedSectionUuid?: string;
  subtitle?: string;
  disabled?: boolean;
  numberOfFieldsInDescription?: number;
  MAXIMUM_FIELDS_IN_DESCRIPTION?: number;
  showSubTitle?: boolean;
}

const YesNoPreference = (props: YesNoPreferenceProps) => {
  const { t } = useTranslation();
  const {
    title,
    changePreference,
    preference,
    MAXIMUM_FIELDS_IN_DESCRIPTION,
    disabled,
    subtitle,
    numberOfFieldsInDescription,
    showSubTitle
  } = props;
  const { value } = preference;
  const positiveAnswer = typeof value === "string" ? value === "true" : value;

  const subtitleMessage =
    subtitle != undefined
      ? subtitle
      : t("messages.fieldsInformDescriptionPreference", {
          numberOfFieldsInDescription,
          MAXIMUM_FIELDS_IN_DESCRIPTION
        });

  return (
    <section id="yesNoPreference">
      <div className="yesNoPreferenceTitle">
        <TitleColumn text={title} />
      </div>
      {showSubTitle && (
        <div className="yesNoPreferenceSubTitle">
          <Subtitle message={subtitleMessage} />
        </div>
      )}
      <div className="yesNoPreferenceBody">
        <RhimButton
          className={`yesNoPreferenceButton ${positiveAnswer ? "enable" : "disable"}`}
          variant={positiveAnswer ? "contained" : "outlined"}
          title={t("common.yes").toUpperCase()}
          onClick={() => {
            preference.value = true;
            changePreference(preference);
          }}
          disabled={disabled}
        />
        <RhimButton
          className={`yesNoPreferenceButton ${positiveAnswer ? "disable" : "enable"}`}
          variant={positiveAnswer ? "outlined" : "contained"}
          title={t("common.no").toUpperCase()}
          onClick={() => {
            preference.value = false;
            changePreference(preference);
            if (
              props.numberOfFieldsInDescription! < props.MAXIMUM_FIELDS_IN_DESCRIPTION! &&
              preference.key === "Fieldsinformdescription"
            ) {
              changePreference(preference);
            }
          }}
          disabled={disabled}
        />
      </div>
    </section>
  );
};

export default YesNoPreference;
