import "./ChecklistBuilder.scss";

import React, { useEffect, useState } from "react";

import { CheckBoxOutlineBlankOutlined } from "@material-ui/icons";
import { FormOption } from "../../../../repositories/interfaces";
import { useStoreState } from "../../../../store/hooks";

interface ChecklistBuilderProps {
  title: string;
  mandatory: boolean;
  optionsArray: any[];
}

const ChecklistBuilder = (props: ChecklistBuilderProps) => {
  const { title, mandatory, optionsArray } = props;
  const selectedField = useStoreState((state) => state.fields.selectedField);
  const [options, setOptions] = useState<FormOption[]>([
    {
      option: "",
      index: 0,
      formOptionLocales: [],
      fieldId: selectedField?.id ?? 0,
      formFieldId: selectedField?.id ?? 0
    }
  ]);

  const getLength = (arrayAux: FormOption[]) =>
    arrayAux.filter((option) => option !== undefined).length;

  const getOptions = (arrayOpts: any[]) => {
    let optionsElements: JSX.Element[] = [];
    if (getLength(arrayOpts) > 0) {
      for (let i = 0; i < getLength(arrayOpts); i++) {
        optionsElements.push(
          <span className="option" key={i}>
            <p>
              <CheckBoxOutlineBlankOutlined className="tools-icon" />
              {arrayOpts[i].option}
            </p>
          </span>
        );
      }
    }
    return optionsElements;
  };

  useEffect(() => {
    setOptions(optionsArray || []);
  }, [optionsArray]);

  return (
    <div className="checklist-builder field-item">
      <h2 className="title">
        {title}
        {mandatory && <span className="mandatory-signal">* </span>}
      </h2>
      <div className="content-field-item content">
        <div
          className={options[0] && options[0].option !== undefined ? "options" : "options empty"}>
          {getOptions(options)}
        </div>
      </div>
    </div>
  );
};

export default ChecklistBuilder;
