import React from "react";
import "./EquipmentMRKPreference.scss";
import { ItemPreference } from "../../../../repositories/interfaces";
import { TitleColumn, DropdownComponent } from "../../atoms";
import { useTranslation } from "react-i18next";

interface EquipmentMRKPreferenceProps {
  onChangeFunction: (preference: ItemPreference) => void;
  preference: ItemPreference;
}

const EquipmentMRKPreference = (props: EquipmentMRKPreferenceProps) => {
  const { t } = useTranslation();
  const { preference, onChangeFunction } = props;

  return (
    <section className="equipment-mrk-preference">
      <TitleColumn text={t("constructor.mrk")} />
      <DropdownComponent onChangeFunction={onChangeFunction} preference={preference} />
    </section>
  );
};

export default EquipmentMRKPreference;
