import { makeStyles, createStyles } from "@material-ui/core/styles";
import { gathererTheme } from "../../../../helpers/theme";

export const useStyles = (width: number, isMobile = false, menuOpened = false) => {
  const { layout } = gathererTheme;

  let marginLeft = 0;
  if (isMobile && !menuOpened) {
    marginLeft = 0;
  } else if (menuOpened) {
    marginLeft = layout.menuOpenedDesk;
  } else {
    marginLeft = layout.menuClosedDesk;
  }

  const maxWidth = isMobile ? width : width - layout.menuClosedDesk;

  return makeStyles((theme) =>
    createStyles({
      containerMainPage: {
        margin: 0,
        backgroundColor: theme.palette.common.white,
        width: "100%"
      },
      container: {
        marginLeft,
        backgroundColor: "transparent",
        overflow: "hidden",
        transition: "0.4s",
        zIndex: 0
      },
      contentPage: {
        zIndex: 0
      },
      page: {
        height: window.innerHeight - layout.headerHeight,
        overflowY: "auto",
        width: maxWidth
      }
    })
  );
};
