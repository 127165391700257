import { Icon } from "@material-ui/core";
import React, { useState } from "react";
import { Form } from "../../../../feature/form/interface/Form";
import { GraphUser } from "../../../../models/Graph/GraphUser";
import { getUser } from "../../../../repositories/Graph.repository";
import { useStoreActions, useStoreState } from "../../../../store/hooks";

const MultipleUsersAssociationInput = () => {
  const form = useStoreState((state) => state.form.form!);
  const updateForm = useStoreActions((action) => action.form.updateForm);

  const [associationValue, setAssociationValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [usersNotFound, setUsersNotFound] = useState([] as string[]);

  const associateUser = (userData: any) => {
    if (!userData) return;
    const formUp: Form = form;
    const newAssoc = {
      formId: form.formId,
      userEmail: userData.userPrincipalName,
      userGuid: userData.id
    };
    const isUserToAssociate =
      formUp.formUsers.find((user) => user.userEmail === newAssoc.userEmail) === undefined;
    if (isUserToAssociate) {
      formUp.formUsers.push(newAssoc);
    }
    // @ts-ignore
    updateForm(formUp);
  };

  async function processAssociationValue() {
    setIsLoading(!!associationValue);
    if (!associationValue) return;

    const users = associationValue.split(";");
    for await (const user of users){
      const userEmail = user.trim();
      const userData: GraphUser = await getUser(userEmail);
      userData ? associateUser(userData) : setUsersNotFound([...usersNotFound, userEmail]);
    }; 

    setIsLoading(false);
  }

  const icon = isLoading ? "stop" : "play_arrow";

  return (
    <React.Fragment>
      <div className="data-list-container">
        <div className="data-list-input-container">
          <input
            type="text"
            className="data-list-input"
            value={associationValue}
            onChange={(event) => setAssociationValue(event.target.value)}
          />
          <Icon
            className="add-user-icon"
            onClick={processAssociationValue}
          >
            {icon}
          </Icon>
        </div>
        <div>
          {usersNotFound.length > 0 && <div style={{ marginTop: "10px" }}>Not found users:</div>}
          <ul>
            {usersNotFound.map((user) => (
              <li key={user}>{user}</li>
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MultipleUsersAssociationInput;
