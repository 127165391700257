import React from "react";
import { useTranslation } from "react-i18next";
import { LanguageItem } from "../../../../feature/languages/interface/LanguageItem";
import { FormOptionLocales, ItemPreference } from "../../../../repositories/interfaces";
import {
  DateTimeBuilder,
  DropdownBuilder,
  InputNumberBuilder,
  InputTextBuilder,
  QRBarCodeBuilder,
  TextareaBuilder,
  UniquePhotoBuilder
} from "../../atoms";
import ChecklistBuilder from "../ChecklistBuilder";
import RadioButtonBuilder from "../RadioButtonBuilder/index";

interface GetFieldsComponentsProps {
  fieldType: string;
  fieldPreferences: ItemPreference[];
  formLanguage: LanguageItem | undefined;
}

const GetFieldComponent = (props: GetFieldsComponentsProps) => {
  const { t } = useTranslation();
  const { fieldType, fieldPreferences, formLanguage } = props;

  function getTitle(fieldPreferences: ItemPreference[]): string {
    let title: string = "";
    fieldPreferences?.forEach((itemMap: ItemPreference) => {
      if (itemMap.key === "Title") {
        title = itemMap.value;
      }
    });
    return title;
  }

  function getMandatory(fieldPreferences: ItemPreference[]): boolean {
    let mandatory: boolean = false;
    fieldPreferences?.forEach((itemMap: ItemPreference) => {
      if (itemMap.key === "Mandatory") {
        mandatory = JSON.parse(itemMap.value);
      }
    });
    return mandatory;
  }

  function getListItems(fieldPreferences: ItemPreference[]) {
    const preferenceListItem = fieldPreferences.find((itemMap: ItemPreference) =>
      itemMap.key.includes("ListItems")
    );
    const listItems = preferenceListItem?.value ? JSON.parse(preferenceListItem.value) : [];

    const optionLocales = listItems.map(getOptionLocale);

    return optionLocales;
  }

  function getOptionLocale(option: any) {
    const locale = option.formOptionLocales
      ? option.formOptionLocales.find(
          (locale: FormOptionLocales) => locale.cultureId === formLanguage?.id
        )
      : option;

    return locale;
  }

  const mandatory = getMandatory(fieldPreferences);
  const title = getTitle(fieldPreferences);

  const getTranslatedTitle = (message: string): string => {
    return title !== "" ? t(title) : t(message);
  };

  function selectTitleQrOrSignatureCode(): string | undefined {
    if (fieldType === "signature-qrcode") {
      return title !== "" ? t(title) : t("fields.signature_qr");
    }
    return title !== "" ? t(title) : t("fields.qr_code");
  }

  switch (fieldType) {
    case "text":
      return (
        <InputTextBuilder
          title={getTranslatedTitle("fields.text")}
          mandatory={mandatory}
          defaultValue="ABC"
        />
      );
    case "textarea":
      return (
        <TextareaBuilder
          title={getTranslatedTitle("fields.textarea")}
          mandatory={mandatory}
          defaultValue="ABC"
        />
      );
    case "number":
      return (
        <InputNumberBuilder
          title={getTranslatedTitle("fields.number")}
          mandatory={mandatory}
          defaultValue={123}
        />
      );
    case "datetime":
      return (
        <DateTimeBuilder title={getTranslatedTitle("fields.datetime")} mandatory={mandatory} />
      );
    case "pictureFile":
      return (
        <UniquePhotoBuilder title={getTranslatedTitle("fields.photo")} mandatory={mandatory} />
      );
    case "dropdown":
      return (
        <DropdownBuilder
          title={getTranslatedTitle("fields.dropdown")}
          mandatory={mandatory}
          optionsArray={getListItems(fieldPreferences)}
        />
      );
    case "check-list":
      return (
        <ChecklistBuilder
          mandatory={mandatory}
          title={getTranslatedTitle("fields.check_list")}
          optionsArray={getListItems(fieldPreferences)}
        />
      );
    case "boolean":
      let preferences = getListItems(fieldPreferences);
      return (
        <RadioButtonBuilder
          title={getTranslatedTitle("fields.single_option")}
          mandatory={mandatory}
          optionsArray={preferences}
        />
      );
    case "dropdown-customer":
      return (
        <DropdownBuilder
          title={getTranslatedTitle("fields.customer")}
          mandatory={mandatory}
          optionsArray={getListItems(fieldPreferences)}
        />
      );
    case "dropdown-equipment":
      return (
        <DropdownBuilder
          title={getTranslatedTitle("fields.equipment")}
          mandatory={mandatory}
          optionsArray={getListItems(fieldPreferences)}
        />
      );
    case "dropdown-campaign":
      return (
        <DropdownBuilder
          title={getTranslatedTitle("fields.campaign")}
          mandatory={mandatory}
          optionsArray={getListItems(fieldPreferences)}
        />
      );
    case "dropdown-campaign-end-reason":
      return (
        <DropdownBuilder
          title={getTranslatedTitle("fields.end_reason")}
          mandatory={mandatory}
          optionsArray={getListItems(fieldPreferences)}
        />
      );
    case "qrbarcode":
    case "signature-qrcode":
      return (
        <QRBarCodeBuilder
          title={selectTitleQrOrSignatureCode()!}
          mandatory={mandatory}
          isSignature={fieldType === "signature-qrcode"}
        />
      );
    default:
      return null;
  }
};

export default GetFieldComponent;
