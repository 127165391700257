import { FormOption, ItemPreference } from "../../repositories/interfaces";

import { CatalogItem } from "../catalog/interface/CatalogItem";
import { Dictionary } from "../../models/Dictionary";
import { Form } from "./interface/Form";
import { FormField } from "./interface/FormField";
import { FormField as FormFieldModel } from "../../models/Form/FormField";
import FormLocales from "../../models/Form/FormLocales";
import { FormRule } from "../rules/interface/rulesInterface";
import { FormSection } from "./interface/FormSection";
import { LanguageItem } from "../languages/interface/LanguageItem";
import { Section } from "../../models/Section/Section";
import { v4 as uuidv4 } from "uuid";

export const sectionsToSectionForms = (
  sectionsInterfaceList: Section[],
  fieldItems: Dictionary<CatalogItem[]>
) =>
  sectionsInterfaceList.map((sectionInterface: Section, index: number) => {
    const sectionForm = sectionToSectionForm(sectionInterface, index);
    const fieldList = fieldItems[sectionInterface.uuid];
    const formFields = fieldListToFormField(fieldList);
    sectionForm.formFields = formFields || [];
    return sectionForm;
  });

export const sectionToSectionForm = (
  sectionInterface: Section,
  index: number,
  t?: Function
): FormSection =>
  ({
    ...sectionInterface,
    title: t ? t(sectionInterface.defaultLanguageTitle) : sectionInterface.defaultLanguageTitle,
    weight: sectionInterface.weight,
    formFields: [],
    id: sectionInterface.id || 0,
    sectionId: sectionInterface.sectionId,
    score: sectionInterface.score,
    index,
    formSectionLocales: sectionInterface.formSectionLocales
  } as FormSection);

export const catalogItemToFormField = (catalogItem: CatalogItem, index: number, t?: Function) => {
  const options = catalogItem.options?.filter((option: any) => option?.option) || [];

  const formField: any = {
    ...catalogItem,
    type: catalogItem.type,
    weight: catalogItem.weight,
    score: catalogItem.score ? catalogItem.score : false,
    fieldId: catalogItem.fieldId,
    title: " ",
    mandatory: "false",
    defaultValue: "",
    id: catalogItem.id,
    options,
    preferences: catalogItem?.preferences,
    index
  };

  const parsedPreferences = parseItemPreferenceKeys(catalogItem.preferences);
  const reducedPreferences = reducePreferences(formField, parsedPreferences);
  const formFieldWithPreferences = {
    ...formField,
    ...reducedPreferences,
    options
  };
  formFieldWithPreferences.fieldId = removeSpaces(catalogItem.fieldId) || "";

  return formFieldWithPreferences;
};

export const reducePreferences = (formField: FormFieldModel, preferences: ItemPreference[]) => {
  const initialAccValue: any = {};
  const formFieldKeys = Object.keys(formField);
  const result = preferences?.reduce((accumulator, preference) => {
    const key = preference.key.toLowerCase();
    const preferenceKeyValue = { [key]: preference.value };
    return formFieldKeys.includes(preference.key)
      ? { ...accumulator, ...preferenceKeyValue }
      : {
          ...accumulator,
          preferences: { ...accumulator.preferences, ...preferenceKeyValue }
        };
  }, initialAccValue);
  result.preferences = JSON.stringify(result.preferences);
  return result;
};

export const parseItemPreferenceKeys = (preferences: ItemPreference[]) => {
  return preferences?.map((preference) => {
    let key = preference.key;
    if (
      preference.key === "ListItemsDropdown" ||
      preference.key === "ListItemsRadio" ||
      preference.key === "FixedListItemsDropdown"
    ) {
      key = "field-options";
    } else if (preference.key === "FixedTitle") {
      key = "title";
    }
    else if (preference.key === "Titledescription") {
      key = "description";
    }
    return { key: key.toLowerCase(), value: preference.value };
  });
};

export const removeSpaces = (str: string) => {
  const splitString = str?.split(" ");
  return splitString?.join("");
};

export const fieldListToFormField = (fieldsList: CatalogItem[] | null, t?: Function) => {
  return fieldsList?.map((field: CatalogItem, index: number) =>
    catalogItemToFormField(field, index, t)
  );
};

export const convertFormToSectionsAndFields = (formSections: FormSection[]) => {
  const sections: Section[] = [];
  const fields: Dictionary<CatalogItem[]> = {};
  return formSections?.reduce(
    (accumulator, formSection, index) => {
      const section: Section = {
        ...formSection,
        index,
        title: formSection.title,
        uuid: uuidv4(),
        score: !!formSection.weight,
        weight: formSection.weight,
        sectionId: formSection.sectionId,
        formSectionLocales: formSection.formSectionLocales
      };
      const fieldList = convertFormFieldsToCatalogItems(section.uuid, formSection.formFields);
      const items: Dictionary<CatalogItem[]> = {};
      items[section.uuid] = fieldList;
      const sections = [...accumulator.sections, section];
      const fields = { ...accumulator.fields, ...items };
      return { sections, fields };
    },
    { sections, fields }
  );
};

export const convertFormFieldsToCatalogItems = (uuid: string, formFields: FormField[]) => {
  const catalogList: CatalogItem[] = [];
  return formFields.reduce((accumulator, fieldItem, index) => {
    let hasScore = false;
    const preferences = parsePreferences(fieldItem);
    const options = preferences?.find(
      (preference) => preference.key === "ListItemsRadio" || preference.key === "ListItemsDropdown"
    );
    if (options) {
      let optionObj = [] as FormOption[];

      if (options.value && options.value !== "null") {
        optionObj = JSON.parse(options?.value) as FormOption[];
      }
      hasScore = optionObj.some((option) => option.weight);
    }
    const field: CatalogItem = {
      ...fieldItem,
      description: "",
      fieldListUuid: uuid,
      icon: "",
      id: fieldItem.id,
      fieldId: fieldItem.fieldId,
      index,
      name: fieldItem.title,
      type: fieldItem.type,
      preferences: preferences,
      uuid: uuidv4(),
      relation: fieldItem.preferences ? JSON.parse(fieldItem?.preferences).relation : null,
      score: !!fieldItem.weight || hasScore,
      weight: fieldItem.weight,
      formFieldLocales: fieldItem.formFieldLocales
    };
    return [...accumulator, field];
  }, catalogList);
};

export const parsePreferences = (formField: FormField) => {
  const object: any = formField;
  const treatedObject = treatFormFieldObject(object);
  const keys = Object.keys(treatedObject);
  return keys.map((key) => {
    const preference: ItemPreference = { key, value: treatedObject[key] };
    return preference;
  });
};

export const treatFormFieldObject = (object: any) => {
  const keys = Object.keys(object);
  let newObject: any = {};
  const keysToReturn = [
    "title",
    "options",
    "mandatory",
    "preferences",
    "fieldOptions",
    "description",
  ];
  const filteredKeys = keys.filter((key) => keysToReturn.includes(key));
  for (let key of filteredKeys) {
    if (key === "options") {
      const convertedKey = convertOptions(object);
      if (convertedKey) {
        let options = JSON.stringify(object[key]);

        newObject[convertedKey] = options;
      }
    } else if (key === "preferences") {
      const convertedPreferences = convertPreferences(object[key]);
      newObject = { ...newObject, ...convertedPreferences };
    }
    else if (key === "description"){
      newObject["Titledescription"] = object[key];
    }
    else {
      const convertedKey = camelCase(key);
      newObject[convertedKey] = object[key];
    }
  }
  return newObject;
};

const convertOptions = (object: any) => {
  if (object["type"] === "dropdown" || object["type"] === "check-list") {
    return "ListItemsDropdown";
  } else if (
    object["type"] === "dropdown-campaign" ||
    object["type"] === "dropdown-customer" ||
    object["type"] === "dropdown-equipment" ||
    object["type"] === "dropdown-campaign-end-reason"
  ) {
    return "FixedListItemsDropdown";
  } else if (object["type"] === "boolean") {
    return "ListItemsRadio";
  }
  return null;
};

const convertPreferences = (object: string | null) => {
  const preferencesJSON = object ? JSON.parse(object) : {};
  const convertedPreferences: any = {};
  for (let key in preferencesJSON) {
    if (key === "equipmentmrk") {
      const convertedKey = "EquipmentMRK";
      convertedPreferences[convertedKey] = preferencesJSON[key];
    } else {
      const upperCasedKey = camelCase(key);
      convertedPreferences[upperCasedKey] = preferencesJSON[key];
    }
  }
  return convertedPreferences;
};

export const camelCase = (value: string) => {
  const splitValue = value.split("");
  splitValue[0] = splitValue[0].toUpperCase();
  return splitValue.join("");
};

export const download = (content: any, fileName: string, contentType: string) => {
  const a = document.createElement("a");
  const file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
};

export const selectLanguageByCultureId = (languageList: LanguageItem[], cultureId: number) => {
  let language = languageList?.find((language: LanguageItem) => language.id === cultureId);
  return language;
};

export const selectCultureIdByIsoCode = (languageList: LanguageItem[], isoCode: string) => {
  let cultureId = languageList?.find((language: LanguageItem) => language.isoCode === isoCode)?.id;
  return cultureId;
};

export const mapFormRules = (formRules: FormRule[]) => {
  return formRules.map((rule: FormRule, index: number) => ({
    name: rule.name,
    id: rule.id,
    formId: rule.formId,
    orderIndex: rule.orderIndex,
    agent: rule.agent,
    agentType: rule.agentType,
    logicalTest: rule.logicalTest,
    response: rule.response,
    action: rule.action,
    target: rule.target,
    targetType: rule.targetType,
    targetOptions: rule.targetOptions,
    index
  }));
};

export const stringfyFormRulesTargetOptions = (formRules: FormRule[]) => {
  return formRules.map((rule: FormRule) => ({
    ...rule,
    targetOptions: JSON.stringify(rule.targetOptions)
  }));
};

export const parseFormApiToStore = (form: Form, languageList: LanguageItem[]) => {
  const item = JSON.parse(JSON.stringify(form));
  let titles = [...item.title];
  let formLocale = [...item.formLocales];
  let defaultLocale = formLocale?.find(
    (locale: FormLocales) =>
      selectLanguageByCultureId(languageList, locale.cultureId)?.isoCode === item.defaultLanguage
  );
  item.title = defaultLocale.title;
  item.description = defaultLocale.description;
  titles = titles?.filter((title: any) => title.lang !== item.defaultLanguage);
  titles?.forEach((title: any) => {
    item[`title-${title.lang}`] = title.value;
  });
  item.formSections = item.formSections?.map((section: any) => {
    let sectionTitles = section.title;
    let sectionTitle = section.title?.find(
      (title: any) => title.lang === item.defaultLanguage
    )?.value;

    section.title = sectionTitle ?? section.title[0].value;
    sectionTitles = sectionTitles?.filter((title: any) => title.lang !== item.defaultLanguage);
    sectionTitles?.forEach((sectionTitle: any) => {
      section[`title-${sectionTitle.lang}`] = sectionTitle.value;
    });
    section.formFields?.forEach((field: any) => {
      let fieldTitles = field.title;
      let fieldTitle = field.title?.find(
        (title: any) => title.lang === item.defaultLanguage
      )?.value;

      field.title = fieldTitle ?? field.title[0].value;
      fieldTitles = fieldTitles?.filter((title: any) => title.lang !== item.defaultLanguage);
      fieldTitles?.forEach((fieldTitle: any) => {
        field[`title-${fieldTitle.lang}`] = fieldTitle.value;
      });
      let fieldDescriptions = field.description;
      let fieldDescription = field.description?.find(
        (title: any) => title.lang === item.defaultLanguage
      )?.value;

      field.description = fieldDescription ?? field.description[0].value;
      fieldDescriptions = fieldDescriptions?.filter(
        (description: any) => description.lang !== item.defaultLanguage
      );
      fieldDescriptions?.forEach((fieldDescription: any) => {
        field[`description-${fieldDescription.lang}`] = fieldDescription.value;
      });
      field.options?.forEach((option: any) => {
        let optionTitles = option.option;
        let optionData = option.option?.find(
          (title: any) => title.lang === item.defaultLanguage
        )?.value;
        option.option = optionData ?? option.option[0]?.value;
        optionTitles = optionTitles?.filter((title: any) => title.lang !== item.defaultLanguage);
        optionTitles?.forEach((optionTitle: any) => {
          option[`title-${optionTitle.lang}`] = optionTitle.value;
        });
      });
    });
    return section;
  });
  item.formRules?.forEach((rule: any) => {
    rule.targetOptions = JSON.parse(rule?.targetOptions);
  });

  return item;
};

const _configActiveLanguages = (
  activeLanguages:LanguageItem[],
  locales:any,
  defaultLocaleTitle:string,
  languagesList:LanguageItem[],
  id:number,
  defaultLocaleDescription?:string
  )=>{
  activeLanguages?.forEach((activeLanguage) => {
    let locale;

    if(locales){
      locale = locales?.find(
        (locale: any) =>
          selectLanguageByCultureId(languagesList, locale.cultureId)?.isoCode ===
          activeLanguage.isoCode
      );
    }else{
      locales = [];
    }

    if(locale && (locale.title===""|| locale.title===" ")){
      locale.title = defaultLocaleTitle;
    }
    if(locale && (locale.description===""|| locale.description===" ")){
      locale.description = defaultLocaleDescription;
    }

    if (!locale) {
      locales.push({
        cultureId: selectCultureIdByIsoCode(
          languagesList,
          activeLanguage.isoCode
        ),
        formId: id,
        title: defaultLocaleTitle,
        description: defaultLocaleDescription,
      });
    }

  });
  return locales;
}

const _getOldTitleObj = (activeLanguages: LanguageItem[], title: any, value: string) => {
  activeLanguages?.forEach((activeLanguage) => {
    //compatibilidade com form antigo
    title.push({
      lang: activeLanguage.isoCode,
      value: value
    });
  });
  return title;
};

const _getDefaultLanguageTitle = (
  title: string,
  defaultLanguageId: number,
  locales: any,
  t?: Function
) => {
  let formTitleDefaultLanguage: string = t ? t(title) : title;

  if (formTitleDefaultLanguage === "" || formTitleDefaultLanguage === " ") {
    formTitleDefaultLanguage = locales?.find(
      (locale: any) => locale.id === defaultLanguageId
    )?.title;
  }
  return formTitleDefaultLanguage;
};

const _getDefaultLanguageDescription = (description:string, defaultLanguageId:number, locales:any ,t?: Function)=>{

  let fieldDescriptionDefaultLanguage:string = t ? t(description) : description;

  if(fieldDescriptionDefaultLanguage===""|| fieldDescriptionDefaultLanguage===" "){
    fieldDescriptionDefaultLanguage = locales?.find((locale:any)=>
      locale.id===defaultLanguageId
    )?.description;
  }
  return fieldDescriptionDefaultLanguage;
}

const _configurePreferences = (preferences: any, defaultFormLanguageId:number) => {
  try{
    let fieldOptions:any[] = JSON.parse(preferences['field-options']);

    if (fieldOptions) {
      fieldOptions?.forEach((option: any) => {
        let defaultOptionLocale = option.formOptionLocales?.find(
          (locale: any) => locale.cultureId === defaultFormLanguageId
        );

        option.formOptionLocales?.forEach((locale: any) => {
          if (!locale.option || locale.option === "" || locale.option === " ") {
            locale.option = defaultOptionLocale.option;
          }
        });
      });
    }
    preferences["field-options"] = JSON.stringify(fieldOptions);
    return preferences;
  } catch (ex) {
    console.error(ex);
  }
  return preferences;
};

const configureFieldOptions = (field: any) => {
  const isSARField =
    field?.name === "fields.equipment" ||
    field?.name === "fields.customer" ||
    field?.name === "fields.campaign" ||
    field?.name === "fields.end_reason";
  try {
    if (isSARField) {
      let fieldOptionsParsed = JSON.parse(field?.fieldOptions);
      if (fieldOptionsParsed) {
        field.options = fieldOptionsParsed;
      }
    } else if (field.preferences) {
      let preferencesParsed = JSON.parse(field?.preferences);
      if (preferencesParsed["field-options"]) {
        let realOptions = JSON.parse(preferencesParsed["field-options"]);
        field.options = realOptions;
      }
    }
    return field;
  } catch (ex) {
    console.error("Error in parse fieldOptions");
    return field;
  }
};

export const parseFormStoreToApi = (
  form: any,
  languagesList: LanguageItem[],
  activeLanguages: LanguageItem[],
  t?: Function
) => {
  const englishLocaleId = 2;
  const defaultLanguageIsoCode = form.defaultLanguage;
  const defaultLanguageId =
    languagesList?.find((language: LanguageItem) => language.isoCode === defaultLanguageIsoCode)
      ?.id ?? englishLocaleId;

  const formTitleDefaultLanguage = _getDefaultLanguageTitle(
    form.title,
    defaultLanguageId,
    form.formLocales,
    t
  );

  delete form.title;
  form.title = [{ lang: defaultLanguageIsoCode, value: formTitleDefaultLanguage }];

  form.formLocales = _configActiveLanguages(
    activeLanguages,
    form.formLocales,
    formTitleDefaultLanguage,
    languagesList,
    form.id
  );
  form.title = _getOldTitleObj(activeLanguages, form.title, formTitleDefaultLanguage ?? "");

  form.description = [
    {
      lang: defaultLanguageIsoCode,
      value: form.description
    }
  ];

  form.formSections?.forEach((section: any, indexSection: number) => {
    const sectionTitleDefaultLanguage = _getDefaultLanguageTitle(
      section.title,
      defaultLanguageId,
      section.formSectionLocales,
      t
    );

    section.title = [{ lang: defaultLanguageIsoCode, value: sectionTitleDefaultLanguage }];
    section.index = indexSection;
    section.formId = form.formId;

    _configActiveLanguages(
      activeLanguages,
      section.formSectionLocales,
      sectionTitleDefaultLanguage,
      languagesList,
      section.id
    );

    _getOldTitleObj(activeLanguages, section.title, sectionTitleDefaultLanguage ?? "");

    section.formFields?.forEach((field: any, indexField: number) => {
      const fieldTitleDefaultLanguage =
       _getDefaultLanguageTitle(field.title,defaultLanguageId,field.formFieldLocales,t);

      field.title = [
        {
          lang: defaultLanguageIsoCode,
          value: fieldTitleDefaultLanguage
        },
      ];
      const fieldDescriptionDefaultLanguage = _getDefaultLanguageDescription(field.description,defaultLanguageId,field.formFieldLocales,t);

      
      field.description = [
        {
          lang: defaultLanguageIsoCode,
          value: fieldDescriptionDefaultLanguage
        },
      ];
      
      field.index = indexField;
      field.formId = form.formId;


      _configActiveLanguages(activeLanguages, field.formFieldLocales, fieldTitleDefaultLanguage, languagesList, field.id, fieldDescriptionDefaultLanguage);

      _getOldTitleObj(activeLanguages, field.title, fieldTitleDefaultLanguage ?? "");

      if (typeof field.options === "string") {
        field.options = [];
      }
      field = configureFieldOptions(field);

      const preferenceToSave = _configurePreferences(
        JSON.parse(field?.preferences),
        defaultLanguageId
      );
      field.preferences = JSON.stringify(preferenceToSave);

      field.options?.forEach((option: any, indexOpt: number) => {
        const optionTitleDefaultLanguage = t ? t(option.option) : option.option;

        option.option = [{ lang: defaultLanguageIsoCode, value: optionTitleDefaultLanguage }];

        activeLanguages?.forEach((activeLanguage) => {
          const fieldOptionLocale = option.formOptionLocales?.find(
            (locale: any) =>
              selectLanguageByCultureId(languagesList, locale.cultureId)?.isoCode ===
              activeLanguage.isoCode
          );

          if (!fieldOptionLocale) {
            option.formOptionLocales = option.formOptionLocales || [];

            option.formOptionLocales.push({
              cultureId: selectCultureIdByIsoCode(languagesList, activeLanguage.isoCode),
              formOptionId: option.id,
              option: option[`title-${activeLanguage.isoCode}`] || optionTitleDefaultLanguage
            });
          } else if (fieldOptionLocale.option === "") {
            fieldOptionLocale.option = optionTitleDefaultLanguage;
          }

          option.option.push({
            lang: activeLanguage.isoCode,
            value: option[`title-${activeLanguage.isoCode}`] || optionTitleDefaultLanguage
          });
        });

        option.index = indexOpt;
        option.formId = form.formId;
      });

      field.fieldOptions = field.options;
    });
  });
  return form;
};
