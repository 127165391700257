import React from "react";
import "./InputSearch.scss";
import { Icon } from "@material-ui/core";

export interface InputSeachProps {
  value: string;
  onChangeHandler: (value: string) => void;
}

const InputSearch = (props: InputSeachProps) => {
  const { onChangeHandler, value } = props;
  return (
    <div className="data-list-input-container">
      <input
        type="text"
        value={value}
        className="data-list-input"
        onChange={(event) => onChangeHandler(event.target.value)}
      />
      <Icon className="add-user-icon">add</Icon>
    </div>
  );
};

export default InputSearch;
