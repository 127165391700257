export default {
  "common.cancel": "Cancelar",
  "common.delete": "Deletar",
  "common.duplicate": "Duplicar",
  "common.title": "Título",
  "common.upload": "Carregar",
  "common.create": "Criar",
  "common.file": "Arquivo",
  "common.section": "Seção",
  "common.add": "Adicionar",
  "common.continue": "Continuar",
  "common.confirm": "Confirmar",
  "common.save": "Salvar",
  "common.saved": "Salvo",
  "common.yes": "Sim",
  "common.no": "Não",
  "common.skip": "Pular",
  "common.ok": "Ok",
  "common.success": "Sucesso",
  "common.sign_out": "Sair",
  "common.answer": "Resposta",
  "common.rule": "Regra",
  "common.formDescriptionPreference":
    "You have already selected {{numberOfFieldsInDescription}}/{{MAXIMUM_FIELDS_IN_DESCRIPTION}} fields for the description.",
  "message.createAnswer.success": "Resposta salva com sucesso!",
  "message.createAnswer.error.answer": "Erro ao criar resposta",
  "message.createAnswer.error.image": "Erro ao criar imagens",
  "message.createAnswer.error.image.type": "Tipo de imagem inválido",
  "message.getAnswers.error.invalidInput":
    "Por favor, insira um período máximo de x dias para filtrar as respostas.",
  "message.getAnswers.error.answer": "Erro no parse de AnswerData",
  "message.getAnswers.error.image": "Erro no parse de Pictures",
  "message.formValidation.missingTitle": "Por favor, preencha o campo Título",
  "message.formValidation.missingEmail": "Por favor, preencha o campo email",
  "message.formValidation.missingSectionTitle": "Por favor, preencha todos os títulos das seções",
  "message.formValidation.missingFieldTitle": "Por favor, preencha todos os títulos dos campos",
  "message.formValidation.missingFieldType": "Por favor, selecione o tipo de todos os campos",
  "message.formValidation.missingFieldId": "Por favor, coloque um Id para todos os campos.",
  "message.formValidation.missingEquipmentMrk":
    "Por favor, selecione o MRK do campo de equipamento",
  "message.formValidation.missingSection":
    "O formulário precisa de pelo menos uma seção para funcionar corretamente",
  "message.formValidation.multipleCustomerField": "O formulário só pode ter um campo de cliente",
  "message.formValidation.missingCustomerField":
    "Para criar um novo formulário com um Equipamento do SAR, por favor coloque o campo de Cliente do SAR antes",
  "message.formValidation.missingEquipmentField":
    "Para criar um novo formulário com Campanha/Motivo de Fechamento do SAR, por favor coloque o campo de Equipamento do SAR antes",
  "message.formValidation.wrongCustomerFieldPositionAfterEquipment":
    "Cliente do SAR precisa ser inserido antes do Equipamento do SAR",
  "message.formValidation.wrongCustomerFieldPositionAfterCampaign":
    "Cliente do SAR precisa ser inserido antes de uma Campanha do SAR",
  "message.formValidation.wrongCustomerFieldPositionAfterEndingReason":
    "Cliente do SAR precisa ser inserido antes de um Motivo de Afastamento do SAR",

  "message.formValidation.wrongEquipmentFieldPosition":
    "Equipamento do SAR precisa ser inserido antes da Campanha do SAR",
  "message.formValidation.wrongEquipmentFieldPositionAfterCampaign":
    "Equipamento do SAR precisa ser inserido antes da Campanha do SAR",
  "message.formValidation.wrongEquipmentFieldPositionAfterEndingReason":
    "Equipamento do SAR precisa ser inserido antes do Motivo de Afastamento do SAR",

  "message.formValidation.failureValidatingRules": "Falha ao validar as regras do formulário.",
  "message.formValidation.missingTargetOptionsInRules":
    "Faltam opções na questão das regras do formulário.",
  "message.createForm.success": "Formulário salvo com sucesso!",
  "message.createForm.error": "Falha ao salvar o formulário. Tente novamente.",
  "message.updateForm.success": "Formulário salvo com sucesso",
  "message.updateForm.error": "Erro ao salvar o formulário",
  "message.toggleFormEnable.success.enable": "Formulário habilitado com sucesso!",
  "message.toggleFormEnable.success.disable": "Formulário desabilitado com sucesso!",
  "message.toggleFormEnable.error": "Erro ao habilitar/desabilitar formulário",
  "message.hasAnswers": "Formulário tem respostas",
  "message.hasNoAnswers": "Formulário não tem respostas",
  "message.hasAnswers.error": "Erro ao conferir se o formulário tem respostas",
  "message.deleteForm.success": "Formulário deletado com sucesso",
  "message.deleteForm.error": "Erro ao deletar o formulário",
  "message.duplicate.modal.title": "Com qual nome você gostaria de salvar sua cópia?",
  "message.duplicateForm.success": "Formulário duplicado com sucesso",
  "message.duplicateForm.error": "Erro ao duplicar o formulário",
  "message.getMrk.error": "Erro ao buscar o MRK",
  "message.getCulture.error": "Idioma não suportado",

  "messages.oops": "Opa",
  "messages.link_copied": "Link copiado",
  "messages.confirm_delete":
    "Você tem certeza que deseja deletar {{formTitle}}? Essa ação não pode ser desfeita.",
  "messages.confirm_delete_answers":
    "Esse formulário já tem respostas registradas. Se prosseguir, todas as respostas serão excluídas e essa ação não pode ser desfeita. Você deseja prosseguir?",
  "messages.confirm_title_duplicate": "Será enviado para o topo da lista com o nome abaixo:",
  "messages.required_fields": "Por favor, preencha todos os campos obrigatórios.",
  "messages.error_support":
    "Um problema ocorreu. Se o erro persistir, por favor entre em contato com o suporte.",
  "messages.error_field_id": "Por favor, confira o Id do campo!",
  "messages.error_field_no_options": "Por favor, confira as opções dos campos!",
  "messages.error_field_dropdown.title": "Você não pode salvar uma lista suspensa sem opções.",
  "messages.error_field_weight": "Por favor, confira o peso dos campos!",
  "messages.error_all_field_weight": "Por favor, complete o peso de todos os campos!",
  "messages.error_fields_repeated_id":
    "O ID do campo {{fieldIdA}} da seção {{sectionIdA}} é igual ao ID do campo {{fieldIdB}} da seção {{sectionIdB}}.",
  "messages.error_section_repeated_id": "Há seções com o mesmo Id!",
  "messages.error_section_name": "Por favor, confira o nome da seção!",
  "messages.error_section_id": "Por favor, confira o id da seção!",
  "messages.error_section_repeated_name": "Há seções com o mesmo nome!",
  "messages.error_mrk": "MRK não encontrado.",
  "messages.error_multiphotos_preferences": "Por favor, revise as preferências do Multifotos!",
  "messages.error_invalid_preference": "Valores de preferencia inválidos",
  "messages.construction": "Essa seção está em construção para melhorar sua experiência",
  "messages.languages_note":
    "Se você deixar um campo vazio, ele será mostrado como o idioma padrão para o usuário.",
  "messages.users_not_found": "Usuários não encontrados",
  "messages.confirm_rules": "Você quer adicionar regras a esse formulário?",
  "messages.empty_rule": "Se você tem uma regra condicional, ela pode ser criada aqui",
  "messages.empty_section": "Selecione um campo para adicioná-lo a uma seção",
  "messages.saved":
    "Seu formulário foi salvo com sucesso! Você pode verificá-lo agora em Meus Formulários.",
  "messages.sign_out": "Você quer se desconectar do sistema?",
  "messages.cannot_change_form_status":
    "Você não pode ativar ou desativar este formulário, pois é um coproprietário.",
  "pages.my_forms": "Meus Formulários",
  "pages.new_form": "Novo Formulário",
  "pages.feedback": "Feedback",
  "pages.builder": "Construtor",
  "pages.rules": "Regras",
  "pages.languages": "Idiomas",
  "pages.users": "Usuários",
  "pages.usersAndCoOwners": "Usuários e coproprietários",
  "my_forms.id": "ID",
  "my_forms.form_code": "Código do Formulário",
  "my_forms.creation_date": "Data de criação",
  "my_forms.updated_date": "Últimas alterações",
  "my_forms.enable": "Habilitado",
  "my_forms.owner": "Proprietário",
  "my_forms.actions": "Ações",
  "actions.form": "Editar Formulário",
  "actions.rules": "Editar Regras",
  "actions.users": "Editar Usuários",
  "actions.languages": "Editar Idiomas",
  "actions.power_bi": "Link para Power BI",
  "actions.export": "Exportar Formulário",
  "actions.delete": "Deletar Formulário",
  "actions.duplicate": "Duplicar Formulário",
  "new_form.import": "Importar novo Formulário",
  "new_form.creation_language": "Idioma de criação",
  "languages.portuguese": "Português",
  "languages.english": "Inglês",
  "languages.spanish": "Espanhol",
  "languages.german": "Alemão",
  "languages.mandarin": "Mandarim",
  "languages.french": "Francês",
  "languages.turkish": "Turco",
  "languages.czech": "Tcheco",
  "languages.polish": "Polonês",
  "languages.slovenian": "Esloveno",
  "languages.swedish": "Sueco",
  "languages.kosovo_albanian": "Albanês",
  "fields.text": "Texto Curto",
  "fields.textarea": "Texto Longo",
  "fields.number": "Numérico",
  "fields.datetime": "Data e Hora",
  "fields.photo": "Foto",
  "fields.dropdown": "Lista Suspensa",
  "fields.check_list": "Checklist",
  "fields.single_option": "Opção Única",
  "fields.customer": "Cliente",
  "fields.equipment": "Equipamento",
  "fields.campaign": "Campanha",
  "fields.end_reason": "Motivo de Afastamento",
  "fields.qr_code": "QrCode/Código de Barras",
  "fields.signature_qr": "Assinatura QrCode",
  "fields.boolean": "Opção Única",
  "fields.dropdown-campaign": "Campanha",
  "fields.dropdown-campaign-end-reason": "Motivo de Afastamento",
  "fields.dropdown-customer": "Cliente",
  "fields.dropdown-equipment": "Equipamento",
  "fields.pictureFile": "Foto",
  "fields.qrbarcode": "QrCode/Código de Barras",
  "fields.signature-qrcode": "Assinatura QrCode",
  "constructor.fields": "Campos",
  "constructor.filter": "Filtrar",
  "constructor.by.equipment": "Filtrar por equipamento",
  "constructor.by.customer": "Filtrar por cliente",
  "constructor.basic": "Básicos",
  "preferences.checklistValue": "Máximo de respostas",
  "constructor.rhim": "Integração RHIM",
  "constructor.section_title": "Título da seção",
  "constructor.unsaves_changes": "Alterações não salvas",
  "constructor.mrk": "MRK de equipamento",
  "constructor.preferences": "Preferências",
  "preferences.placeholder": "Selecione um campo para editar suas preferências",
  "preferences.required": "Obrigatório",
  "preferences.score": "Pontuação",
  "preferences.options": "Opções",
  "preferences.automatic": "Automático",
  "preferences.automatic_id": "Id do Automático",
  "preferences.field_id": "Id do Campo",
  "preferences.field_weight": "Peso do Campo",
  "preferences.section_weight": "Peso da Seção",
  "preferences.section_id": "Id da Seção",
  "preferences.options_placeholder": "Digite os itens separando-os com enter",
  "preferences.mrk_placeholder": "Selecione um MRK",
  "preferences.max": "Maximum",
  "preferences.min": "Minimum",
  "preferences.formDescription": "Usar como descrição de formulário",
  "preferences.typeQrOrBarCode": "Tipo",
  "preferences.IntegerOrFloat": "Tipo",
  "preferences.decimalPlacesMin" : "Mínimo de casas decimais",
  "preferences.DecimalPlaces": "DecimalPlaces",
  "preferences.option_integer": "Inteiro",
  "preferences.option_decimal": "Decimal",
  "preferences.error_empty_qrcode": "Selecione no minimo 1 campo",
  "add_options.option": "Opção",
  "add_options.weight": "Peso",
  "add_options.response": "Resposta",
  "add_options.add_na": "Adicionar N/A",
  "construction.go_back": "Voltar",
  "languages.notes": "Notas",
  "users.email": "E-mail dos usuários",
  "coOwners.email": "E-mail dos coproprietários",
  "users.multiple_association":
    "Associação múltipla (preencha com a lista de emails separados por ponto e vírgula)",
  "users.finish": "Finalizar Formulário",

  "globalization.title": "Escreva seu título do formulário em",
  "globalization.section": "Escreva seu título da seção em",
  "globalization.field": "Escreva seu nome do campo em",
  "globalization.field.description": "Escreva a descrição do campo em",
  "globalization.option": "Escreva seu valor da opção em",

  "rules.if": "Se",
  "rules.status": "Status",
  "rules.response": "Resposta",
  "rules.action": "Ação",
  "rules.question": "Questão",
  "rules.options": "Opções",

  "option.is": "É",
  "option.isn't": "Não É",
  "option.hide.section": "Esconder Sessão",
  "option.hide.field": "Esconder Campo",
  "option.show.section": "Mostrar Sessão",
  "option.show.field": "Mostar Campo",
  "option.filter": "Filtrar",
  "option.required": "Obrigatório",

  "pagination.view": "Visualizando {{from}}-{{to}} de {{count}}",
  "pagination.rows_per_page": "Linhas por página:",
  "messages.error_form_access_denied":
    "Você não possui acesso a este formulário. Por favor, busque por um formulário em que você seja autor ou co-autor",
  "messages.page_not_found": "Página não encontrada",
  "messages.text_page_not_found": "Desculpe, a página que você está tentando acessar não existe.",
  "messages.go_back_my_forms": "Voltar para Meus Formulários",
  "messages.access_denied": "Acesso negado",
  "messages.text_access_denied":
    "Você não tem acesso a este formulário. Pesquise um formulário em que você seja o autor ou coautor.",
  "messages.form_not_found": "Formulário não encontrado",
  "messages.text_form_not_found":
    "Desculpe, o formulário que você está tentando acessar não existe. Por favor, busque um outro formulário ou crie um novo.",
  "message.formValidation.multipleEquipmentInSection":
    "O formulário só pode ter um campo SAR Equipment por seção",
  "preferences.description": "Descrição",
  "messages.fieldsInformDescriptionPreference":
    "Você já selecionou {{numberOfFieldsInDescription}}/{{MAXIMUM_FIELDS_IN_DESCRIPTION}} dos fields.",
  "messages.error_single_option_no_options":
    "Você não pode salvar um campo de opção única sem opções.",
  "messages.max_filled_as_form_description":
    "Para selecionar este campo, desmarque algum outro campo",

  "messages.tooltip_decimal_minimum_alert":
    "Devido às 2 casas decimais, o limite mínimo de caracteres para campos decimais é 3",
  "message.form_last_update": "Última atualização",
  "message.form_last_update_by": "Por"
};
