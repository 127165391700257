import React from "react";
import "./TitlePreference.scss";
import { ItemPreference } from "../../../../repositories/interfaces";
import TitleColumn from "../../atoms/TitleColumn/TitleColumn";
import { useTranslation } from "react-i18next";

interface TitlePreferenceProps {
  onChangeFunction: (preference: ItemPreference) => void;
  preference: ItemPreference;
  clear: (key: string, value: string) => {};
}

const TitlePreference = (props: TitlePreferenceProps) => {
  const { t } = useTranslation();
  const { onChangeFunction, preference, clear } = props;

  return (
    <section id="titlePreference">
      <div className="titlePreferenceTitle" data-testid="title-preference">
        <TitleColumn text={t("common.title")} />
      </div>
      <input
        maxLength={200}
        type="text"
        id="inputTitlePreference"
        value={t(preference.value).toString()}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          preference.value = event.target.value;
          onChangeFunction(preference);
          clear(preference.key, event.target.value);
        }}
      />
    </section>
  );
};

export default TitlePreference;
