import React from "react";
import "./SectionList.scss";
import { SectionListItem } from "../../molecules";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { Section } from "../../../../models/Section/Section";

const SectionList = () => {
  const items = useStoreState((state) => state.sections.items);
  const removeSection = useStoreActions((actions) => actions.sections.remove);

  return (
    <div className="SectionList-organism">
      {items.map((section: Section, i: number) => {
        section.index = i;
        return <SectionListItem key={i} section={section} removeSection={removeSection} />;
      })}
    </div>
  );
};

export default SectionList;
