import React from "react";
import "../styles/GlobalizationSection.scss";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { getPlaceholderLanguage } from "../../../helpers/util";
import GlobalizationSectionList from "./GlobalizationSectionList";
import { useTranslation } from "react-i18next";
import FormLocales from "../../../models/Form/FormLocales";

const GlobalizationSectionContainer = () => {
  const { t } = useTranslation();
  const form = useStoreState((state) => state.form.form);
  const sections = useStoreState((state) => state.sections.items);
  const fields = useStoreState((state) => state.fields.translatedItems);
  const selectedLanguage = useStoreState((state) => state.languages.selectedLanguage);
  const updateTranslation = useStoreActions((actions) => actions.form.updateTranslation);

  const languages = useStoreState((state) => state.languages.items);

  const languageName = languages?.find(
    (language: any) => language.isoCode === selectedLanguage?.isoCode
  );
  const isDefaultLanguage = selectedLanguage?.isoCode === form?.defaultLanguage;

  return (
    <>
      <article className="globalization-form-container">
        <span
          className={`globalization-form-title ${
            !isDefaultLanguage ? "globalization-form-title-edit" : ""
          }`}>
          {form?.title}
        </span>
        {!isDefaultLanguage && selectedLanguage && (
          <input
            className="globalization-form-title-input"
            value={(form
              ? form.formLocales.find(
                  (locale: FormLocales) => locale.cultureId === selectedLanguage?.id
                )?.title
              : ""
            )?.toString()}
            onChange={(e) => {
              updateTranslation({
                language: selectedLanguage,
                text: e.target.value,
                type: "UPDATE"
              });
            }}
            readOnly={isDefaultLanguage}
            placeholder={`${t("globalization.title")} ${t(
              getPlaceholderLanguage(selectedLanguage.isoCode),
              selectedLanguage
            )}`}
          />
        )}
      </article>
      {sections?.map((section, i) => {
        const fieldItems = fields?.flat()?.filter((field) => field.fieldListUuid === section.uuid);
        return (
          <GlobalizationSectionList
            key={i}
            isDefaultLanguage={isDefaultLanguage}
            section={section}
            fieldItems={fieldItems}
            selectedLanguage={selectedLanguage ?? undefined}
            languageName={languageName?.description || selectedLanguage?.description || ""}
            defaultLanguageIsoCode={form?.defaultLanguage ?? ""}
          />
        );
      })}
    </>
  );
};

export default GlobalizationSectionContainer;
