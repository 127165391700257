import React, { useState } from "react";
import "./DropdownLanguage.scss";
import Dropdown, { Option } from "react-dropdown";
import { useTranslation } from "react-i18next";
import { Icon } from "@material-ui/core";

interface DropdownComponentProps {
  options: { key: string; value: string }[];
  handleChange: any;
  form: any;
}

const DropdownLanguage = (props: DropdownComponentProps) => {
  const { options, handleChange, form } = props;
  const [currentValue, setCurrentValue] = useState("pt-BR");
  const { t } = useTranslation();

  let listKeys: string[] = [];
  options.forEach((opt) => {
    listKeys.push(t(opt.key));
  });

  const getKeyFromValue = (value: string) =>
    options.find((element: any) => element.value === value)?.key;

  const getValueFromKey = (key: string) =>
    options.find((element: any) => t(element.key) === key)?.value;

  return (
    <Dropdown
      className="dropdown-languages"
      options={listKeys}
      value={t(options.find((option) => option.value === form.defaultLanguage)?.key || "")}
      placeholder={getKeyFromValue(currentValue) + ""}
      arrowOpen={<Icon className="open-close-arrow">expand_less</Icon>}
      arrowClosed={<Icon className="open-close-arrow">expand_more</Icon>}
      onChange={(arg: Option) => {
        handleChange("defaultLanguage", getValueFromKey(arg.value) + "");
        setCurrentValue(getValueFromKey(arg.value) + "");
      }}
    />
  );
};

export default DropdownLanguage;
