import "../styles/Rules.scss";

import { Icon, SvgIcon } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  getActorOptions,
  getActorValue,
  getQuestionOptions,
  getQuestionValue,
  getRuleFieldOptions,
  getStatusOptions,
  ruleActionOptions
} from "../rulesUtils";
import { set, update } from "lodash";
import { useStoreActions, useStoreState } from "../../../store/hooks";

import DropdownItem from "./DropdownItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RuleItemProps } from "../interface/rulesInterface";
import { Section } from "../../../models/Section/Section";
import Select from "react-select";
import { getFontAwesomeIcon } from "../../../helpers/iconFabric";
import { getTypeByFieldId } from "../../../helpers/util";
import { useTranslation } from "react-i18next";

const RuleItem = (props: RuleItemProps) => {
  const { rule, index } = props;
  const [openStatus, setOpenStatus] = useState(true);
  const [agentType, setAgentType] = useState(rule.agentType || "");
  const [allStatusOptions, setAllStatusOptions] = useState(getStatusOptions(agentType || ""));

  const updateRule = useStoreActions((action) => action.rules.updateRule);
  const removeRule = useStoreActions((action) => action.rules.removeRule);
  const addRule = useStoreActions((action) => action.rules.addRule);
  const moveRuleUp = useStoreActions((action) => action.rules.moveRuleUp);
  const moveRuleDown = useStoreActions((action) => action.rules.moveRuleDown);
  const rules = useStoreState((state) => state.rules.rules);
  const setRulesOrderIndexes = useStoreActions((action) => action.rules.setRulesOrderIndexes);

  const fields = useStoreState((state) => state.fields.items);
  const sections = useStoreState((state) => state.sections.items);

  const joinedFields = Object.keys(fields)
    .map((key) => fields[key])
    .flat();

  const { t } = useTranslation();

  useEffect(() => {
    const agentId = rule.agent || "";
    const agentTypeById = getTypeByFieldId(joinedFields, agentId);
    setAgentType(agentTypeById);
  }, []);

  useEffect(() => {
    setAllStatusOptions(getStatusOptions(agentType || ""));
  }, [agentType]);

  return (
    <article className="rule-section-list-item">
      <section className="rule-section-header">
        <Icon
          className={`rule-section-icon-button rule-open-close-button`}
          onClick={() => {
            setOpenStatus(!openStatus);
          }}
        >
          {openStatus ? "expand_less" : "expand_more"}
        </Icon>
        <input
          type="text"
          value={rule.name}
          className="input-title"
          onChange={(event) => {
            updateRule({ ...rule, name: event.target.value, index });
          }}
        />
        <section className="rule-header-actions">
          <SvgIcon
            className="rule-section-icon-button move-button"
            onClick={() => {
              moveRuleUp(index);
              setRulesOrderIndexes(rules);
            }}>
            <FontAwesomeIcon icon={getFontAwesomeIcon("fa-sortUp")} />
          </SvgIcon>
          <SvgIcon
            className="rule-section-icon-button move-button"
            onClick={() => {
              moveRuleDown(index);
              setRulesOrderIndexes(rules);
            }}>
            <FontAwesomeIcon icon={getFontAwesomeIcon("fa-sortDown")} />
          </SvgIcon>
          <Icon
            className={`rule-section-icon-button`}
            onClick={() => {
              addRule({
                ...rule,
                index: rules.length,
                name: `Rule ${rules.length + 1}`
              });
            }}
          >
            content_copy
          </Icon>
          <Icon
            className={`rule-section-icon-button rule-trash-icon`}
            onClick={() => {
              removeRule(index);
            }}
          >
            delete
          </Icon>
        </section>
      </section>
      {openStatus && (
        <article className="rule-content-container">
          <div className="rule-grid-container">
            <div className="if">
              <span className="rule-field-label">{t("rules.if")}</span>
              <DropdownItem
                options={getActorOptions(joinedFields, t)}
                value={getActorValue(joinedFields, rule, t)}
                onChange={(arg: any) => {
                  const field = joinedFields.find((field) => {
                    const fieldTitlePreference = field.preferences.find((p) => p.key === "Title");
                    const fieldTitle = fieldTitlePreference ? t(fieldTitlePreference.value) : "";
                    return field.fieldId === arg.value && fieldTitle === arg.label;
                  });
                  setAgentType(field?.type || "");
                  updateRule({
                    ...rule,
                    agent: field?.fieldId,
                    agentType: field?.type,
                    target: "",
                    targetType: "",
                    response: "",
                    logicalTest: "",
                    index
                  });
                }}
              />
            </div>
            <div className="status">
              <span className="rule-field-label">{t("rules.status")}</span>
              <DropdownItem
                options={allStatusOptions.map((op) => ({
                  value: op,
                  label: t(op)
                }))}
                value={t(rule.logicalTest)}
                onChange={(arg: any) => {
                  updateRule({
                    ...rule,
                    logicalTest: arg.value,
                    index
                  });
                }}
              />
            </div>
            <div className="response">
              <span className="rule-field-label">{t("rules.response")}</span>
              {agentType === "number" ? (
                <input
                  className="rule-header-input"
                  value={rule.response}
                  type="number"
                  onChange={(e) => {
                    updateRule({ ...rule, response: e.target.value, index });
                  }}
                />
              ) : (
                <DropdownItem
                  options={getRuleFieldOptions(joinedFields, rule.agent || "")}
                  value={
                    getRuleFieldOptions(joinedFields, rule.agent || "")[rule.response]?.label || ""
                  }
                  onChange={(arg: any) => {
                    updateRule({
                      ...rule,
                      response: arg.value.toString(),
                      index
                    });
                  }}
                />
              )}
            </div>
            <div className="action">
              <span className="rule-field-label">{t("rules.action")}</span>
              <DropdownItem
                options={ruleActionOptions.map((op) => ({
                  value: op,
                  label: t(op)
                }))}
                value={t(rule.action)}
                onChange={(arg: any) => {
                  updateRule({
                    ...rule,
                    action: arg.value,
                    targetOptions: [],
                    target: "",
                    targetType: "",
                    index
                  });
                }}
              />
            </div>
            <div className="question">
              <span className="rule-field-label">{t("rules.question")}</span>
              <DropdownItem
                options={getQuestionOptions(joinedFields, sections, rule, t)}
                value={getQuestionValue(joinedFields, sections, rule, t)}
                onChange={(arg: any) => {
                  const field = joinedFields.find((field) => t(field.fieldId) === arg.value);
                  const section = sections.find(
                    (section: Section) => t(section.sectionId) === arg.value
                  );
                  updateRule({
                    ...rule,
                    target: field?.fieldId !== undefined ? field?.fieldId : section?.sectionId,
                    targetType: field?.type || "section",
                    targetOptions: [],
                    index
                  });
                }}
              />
            </div>
            {rule.action.includes("filter") && (
              <div className="options">
                <span className="rule-field-label">{t("rules.options")}</span>
                <Select
                  isMulti
                  name="colors"
                  value={rule?.targetOptions}
                  options={getRuleFieldOptions(joinedFields, rule.target || "")}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder=""
                  onChange={(val: any) => {
                    updateRule({
                      ...rule,
                      targetOptions: val,
                      index
                    });
                  }}
                />
              </div>
            )}
          </div>
        </article>
      )}
    </article>
  );
};

export default RuleItem;
