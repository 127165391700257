import React from 'react'
import './alerts.scss'

interface ErrorWarningProps{
    message: string
}
const ErrorWarning = ({message}: ErrorWarningProps) => {
  return (
    <div className='error'>{message}</div>
  )
}

export default ErrorWarning