import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon, SvgIcon } from "@material-ui/core";
import { ActionCreator } from "easy-peasy";
import React, { useEffect } from "react";
import { CatalogItem } from "../../../../feature/catalog/interface/CatalogItem";
import { LanguageItem } from "../../../../feature/languages/interface/LanguageItem";
import { getFontAwesomeIcon } from "../../../../helpers/iconFabric";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { useScroll } from "../../../hooks/useScroll";
import { GetFieldComponent } from "../../atoms";
import "./FieldsListItem.scss";

const FieldListItem = React.forwardRef<
  HTMLDivElement,
  { onSelectField: ActionCreator<CatalogItem>; item: CatalogItem }
>(({ onSelectField, item }) => {
  const { uuid, type, preferences } = item;
  const hasSelectedFieldOnStore = useStoreState((state) => state.fields.hasSelectedField);
  const defaultIsoCode = useStoreState((state) => state.form.form)?.defaultLanguage!;
  const defaultFormLanguage = useStoreState((state) => state.form.formLanguageList).find(
    (language: LanguageItem) => language.isoCode === defaultIsoCode
  );

  const selectedField = useStoreState((state) => state.fields.selectedField);
  const removeField = useStoreActions((actions) => actions.fields.removeField);
  const removeRuleByFieldId = useStoreActions((actions) => actions.rules.removeRuleByFieldId);
  const moveFieldUp = useStoreActions((actions) => actions.fields.moveFieldUp);
  const moveFieldDown = useStoreActions((actions) => actions.fields.moveFieldDown);
  const { ref, scrollToItem } = useScroll<HTMLDivElement>();
  const isSelected = selectedField?.uuid === uuid && hasSelectedFieldOnStore;

  const updateIsSaved = useStoreActions((actions) => actions.form.updateIsSaved);

  const setFieldsInDescription = useStoreActions((actions) => actions.form.setFieldsInDescription);

  const fieldsInDescription: CatalogItem[] = useStoreState(
    (state) => state.form.fieldsInDescription
  );
  const removeFieldFromFormDescriptionList = (item: CatalogItem) => {
    fieldsInDescription.forEach((field) => {
      if (field.uuid === item.uuid) {
        setFieldsInDescription({ field, action: "pop" });
      }
    });
  };
  useEffect(() => {
    if (isSelected) scrollToItem(ref);
  }, [item, selectedField]);
  return (
    <div className="field-item-container">
      <div
        ref={ref}
        id={"toolbox-field-" + uuid}
        className={
          selectedField?.uuid === uuid && hasSelectedFieldOnStore
            ? "toolbox-field"
            : "toolbox-field invisible"
        }>
        <SvgIcon
          className="move-fields-icon"
          onClick={() => {
            moveFieldUp(item);
            onSelectField(item);
            updateIsSaved(false);
          }}>
          <FontAwesomeIcon icon={getFontAwesomeIcon("fa-sortUp")} />
        </SvgIcon>
        <SvgIcon
          className="move-fields-icon"
          onClick={() => {
            moveFieldDown(item);
            onSelectField(item);
            updateIsSaved(false);
          }}>
          <FontAwesomeIcon icon={getFontAwesomeIcon("fa-sortDown")} />
        </SvgIcon>

        <span className="separator-fields"></span>
        <Icon
          className="trash-field-icon"
          onClick={() => {
            removeField(item);
            removeFieldFromFormDescriptionList(item);
            updateIsSaved(false);
            removeRuleByFieldId(item.fieldId);
          }}>
          delete
        </Icon>
      </div>
      <article
        className={`main-fields-articles ${
          selectedField?.uuid === uuid && hasSelectedFieldOnStore ? "selected-border" : ""
        }`}
        id={"selected-border-" + uuid}
        onClick={() => {
          onSelectField(item);
        }}>
        <span className="field-component">
          <GetFieldComponent
            fieldType={type}
            fieldPreferences={preferences}
            formLanguage={defaultFormLanguage}
          />
        </span>
      </article>
    </div>
  );
});
FieldListItem.displayName = "FieldListItem";
export default FieldListItem;
