import React from "react";
import "./../UsersAssociationPage.scss";
import { useStoreState } from "../../../../../store/hooks";
import { UserList, NewEmailInput, MultipleUsersAssociationInput } from "../../../molecules";
import { useTranslation } from "react-i18next";

interface EmailAssociationInputProps {
  formEmailsType: string;
}

const EmailAssociationInput = (props: EmailAssociationInputProps) => {
  const { t } = useTranslation();
  const form = useStoreState((state: { form: { form: any } }) => state.form.form);

  return (
    <div className="container">
      {inputTitle(props.formEmailsType)}
      <NewEmailInput formEmailsType={props.formEmailsType} />
      {multipleAssociation(props.formEmailsType)}
      <UserList items={form[props.formEmailsType]} formEmailsType={props.formEmailsType} />
    </div>
  );

  function inputTitle(formEmailsType: string) {
    const inputTitle = formEmailsType === "formUsers" ? "users.email" : "coOwners.email";
    return <span className="label-title">{t(inputTitle)}</span>;
  }

  function multipleAssociation(formEmailsType: string) {
    if (formEmailsType === "formUsers") {
      return (
        <div style={{ paddingTop: "16px" }}>
          <span className="label-title">{t("users.multiple_association")}</span>
          <MultipleUsersAssociationInput />
        </div>
      );
    }
  }
};

export default EmailAssociationInput;
