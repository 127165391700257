import React, { ChangeEvent, useEffect } from "react";
import "./ListItemsRadioPreference.scss";
import { ItemPreference } from "../../../../repositories/interfaces";
import { TitleColumn } from "../../atoms";
import { useModalVisibility } from "../../../../../src/feature/modal";
import ResponseOptions from "../ResponseOptions";
import { useTranslation } from "react-i18next";
import Icon from "@material-ui/core/Icon";
import { RhimButton, RhimModal } from "rhim-ui";
import Grid from "@material-ui/core/Grid";

interface ListItemsRadioPreferenceProps {
  onChangeFunction: (preference: ItemPreference) => void;
  preference: ItemPreference;
  score: boolean;
  cultureId: number;
}

const ListItemsRadioPreference = (props: ListItemsRadioPreferenceProps) => {
  const { t } = useTranslation();
  const { preference, onChangeFunction, score, cultureId } = props;
  const listOptionsFromPreference = preference.value ? JSON.parse(preference.value) : [];
  const options = listOptionsFromPreference.length
    ? listOptionsFromPreference
    : [
        {
          option: "",
          index: 0,
          formOptionLocales: [{ option: "", cultureId: cultureId }]
        },
        {
          option: "",
          index: 1,
          formOptionLocales: [{ option: "", cultureId: cultureId }]
        }
      ];

  const { modalProperties, setModalProperties } = useModalVisibility({
    visible: false
  });
  const stringifyOptions = (optionsArray: any[]): string => {
    return JSON.stringify(optionsArray);
  };

  const verifyInvalidOptions = (scoreFlag: boolean, optionsArray: any) => {
    let invalid = false;
    if (scoreFlag) {
      optionsArray.forEach((a: any) => {
        if (!a.weight && a.weight !== 0) invalid = true;
      });
    }
    return invalid;
  };

  const renderModalContent = () => {
    const hasInvalidOption = verifyInvalidOptions(score, listOptionsFromPreference);
    return (
      <div>
        <div className="messageModal">
          {hasInvalidOption && <span className="warning">{t("messages.required_fields")}</span>}
          <ResponseOptions onChangeFunction={onChangeFunction} preference={preference} />
        </div>
        <div id="modal-footer">
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item>
              <RhimButton
                title={t("common.save").toUpperCase()}
                variant="contained"
                onClick={() => {
                  setModalProperties({ visible: false });
                }}
                disabled={hasInvalidOption}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!listOptionsFromPreference.length) {
      preference.value = stringifyOptions(options);
      onChangeFunction(preference);
    }
  });

  const updateOptionPreference = (newOption: string, optionIndex: number) => {
    options[optionIndex].option = newOption;
    let localeToUpdate = options[optionIndex].formOptionLocales.find(
      (locale: any) => locale.cultureId === cultureId
    );
    localeToUpdate.option = newOption;
    preference.value = stringifyOptions(options);
    onChangeFunction(preference);
  };

  return (
    <section className="list-items-preference-radio">
      <TitleColumn text={t("preferences.options")} />
      {!score && (
        <div className="options">
          <input
            type="text"
            className="content"
            value={
              options[0].formOptionLocales.find((locale: any) => locale.cultureId === cultureId)
                ?.option ?? ""
            }
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateOptionPreference(event.target.value, 0)
            }
            placeholder={`${t("add_options.option")} A`}
          />
          <input
            type="text"
            className="content"
            value={
              options[1].formOptionLocales.find((locale: any) => locale.cultureId === cultureId)
                ?.option ?? ""
            }
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateOptionPreference(event.target.value, 1)
            }
            placeholder={`${t("add_options.option")} B`}
          />
        </div>
      )}

      {score && (
        <span className="addOptions-button button">
          <RhimButton
            variant="outlined"
            title={t("common.add").toUpperCase()}
            iconSide="right"
            icon={<Icon>arrow_forward</Icon>}
            onClick={async () => {
              setModalProperties({ visible: true, title: "form.title" });
            }}
          />
        </span>
      )}

      <RhimModal
        modalProps={{ id: "radio-options-modal" }}
        title={t("common.add")}
        content={renderModalContent()}
        showModal={modalProperties.visible}
        onClose={() => {
          setModalProperties({ visible: false });
        }}
        isMobile={false}
        modalSizeHeight="auto"
        modalSizeWidth="50%"
      />
    </section>
  );
};

export default ListItemsRadioPreference;
