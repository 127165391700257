import React from "react";
import "./UserListItem.scss";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { Form } from "../../../../feature/form/interface/Form";
import Icon from "@material-ui/core/Icon";

interface UserListItemProps {
  user: any;
  formEmailsType: string;
}

const UserListItem = (props: UserListItemProps) => {
  const { user } = props;
  const form = useStoreState((state) => state.form.form!);
  const updateForm = useStoreActions((action) => action.form.updateForm);
  const email = props.formEmailsType === "formUsers" ? user.userEmail : user.coOwnerEmail;

  function removeFormUser(userToRemove: any) {
    const index = form[props.formEmailsType].indexOf(userToRemove);
    const formUp: Form = form;
    if (index > -1) {
      formUp[props.formEmailsType].splice(index, 1);
      updateForm(formUp);
    }
  }

  return (
    <span className="form-user-association-item">
      <span className="form-user-association-item-title">{email.toLowerCase()}</span>
      <Icon className="font-awesome-icon-button" onClick={() => removeFormUser(user)}>
        clear
      </Icon>
    </span>
  );
};

export default UserListItem;
