import React from "react";
import "./styles/CatalogListItem.scss";
import { CatalogItem, CatalogTranslation } from "../interface/CatalogItem";
import { useStoreActions } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import { Icon } from "@material-ui/core";
import "@fortawesome/fontawesome-free/css/all.css";

export interface CatalogListItemProps {
  item: CatalogItem;
  onClick: (payload: CatalogTranslation) => void;
}

const CatalogListItem = (props: CatalogListItemProps) => {
  const { t } = useTranslation();
  const { item, onClick } = props;

  const updateIsSaved = useStoreActions((actions) => actions.form.updateIsSaved);

  const clickHandler = () => {
    onClick({ t, item });
    updateIsSaved(false);
  };

  return (
    <button className="catalog-list-item" type="button" onClick={clickHandler}>
      <span className="item">
        {item.icon.startsWith("fa-") ? (
          <Icon className={`item-icon fa ${item.icon}`}></Icon>
        ) : (
          <Icon className="item-icon">{item.icon}</Icon>
        )}
        <p className="name">{t(item.name)}</p>
      </span>
      <Icon className="add-icon">add</Icon>
    </button>
  );
};

export default CatalogListItem;
