import React from "react";

import { useStoreActions, useStoreState } from "../../../../../store/hooks";
import { FormOption } from "../../../../../repositories/interfaces";
import { FormField } from "../../../../../models/Form/FormField";
import { useTranslation } from "react-i18next";
import TitleColumn from "../../../atoms/TitleColumn/TitleColumn";
import Toggle from "../../../atoms/Toggle/Toggle";
import InputOnlyNumber from "../../../atoms/InputOnlyNumber/InputOnlyNumber";

const ScoreSection = () => {
  const { t } = useTranslation();
  const selectedField = useStoreState((state) => state.fields.selectedField)!;
  const setField = useStoreActions((actions) => actions.fields.setField);

  return (
    <>
      <section className="sectionPreferenceTitle">
        <div className="scorePreferenceTitle">
          <TitleColumn text={t("preferences.score")} />
          <Toggle
            isSelected={selectedField.score}
            changeToggleValue={() => {
              selectedField.score = !selectedField.score;
              if (!selectedField.score) {
                selectedField.weight = undefined;

                const options = selectedField.preferences.find(
                  (preference) =>
                    preference.key === "ListItemsRadio" || preference.key === "ListItemsDropdown"
                );
                if (options) {
                  const optionObj = JSON.parse(options.value) as FormOption[];
                  const values = optionObj.map((option) => {
                    option.weight = undefined;
                    return { ...option };
                  });
                  options.value = JSON.stringify(values);
                }
              }
              setField(selectedField);
            }}
          />
        </div>
      </section>
      {selectedField.score && (
        <section className="sectionPreferenceTitle">
          <div className="sessionWeightPreferenceTitle">
            <TitleColumn text={t("preferences.field_weight")} />
            <InputOnlyNumber
              className="sessionWeight"
              value={selectedField.weight}
              maxLength={200}
              onChange={(value?: number) => {
                // @ts-ignore
                const field: FormField = {
                  ...selectedField,
                  weight: value
                };
                // @ts-ignore
                setField(field);
              }}
            />
          </div>
        </section>
      )}
    </>
  );
};

export default ScoreSection;
