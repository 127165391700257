import React from "react";
import "./Toggle.scss";

interface ToggleProps {
  isSelected: boolean;
  changeToggleValue?: () => void;
  disabled?: boolean;
}

const Toggle = (props: ToggleProps) => {
  const { isSelected, changeToggleValue, disabled } = props;

  const getSliderClass = () => {
    if (disabled) {
      return "slider round disabled";
    } else {
      return "slider round";
    }
  };
  return (
    <label className="switch">
      <input 
      type="checkbox" 
      disabled={disabled} 
      checked={isSelected} 
      onChange={changeToggleValue} />
      <span className={getSliderClass()}></span>
    </label>
  );
};

export default Toggle;
