import React from "react";
import "./SectionPreference.scss";
import { TitleColumn, InputOnlyNumber, Toggle } from "../../atoms";
import { Section } from "../../../../models/Section/Section";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { IdPreference } from "../../molecules";
import { useTranslation } from "react-i18next";
import { LanguageItem } from "../../../../feature/languages/interface/LanguageItem";
import { FormSectionLocaleModel } from "../../../../models/Section/FormSectionLocaleModel";

interface SectionPreferenceProps {
  section: Section;
}

const SectionPreference = (props: SectionPreferenceProps) => {
  const { t } = useTranslation();
  const { section } = props;
  const updateSection = useStoreActions((actions) => actions.sections.updateSection);
  const updateIsSaved = useStoreActions((actions) => actions.form.updateIsSaved);
  const languages = useStoreState((state) => state.languages.items);
  const defaultLanguage = useStoreState((state) => state.form.form?.defaultLanguage);

  function changeSectionId(newId: string) {
    const updatedSection: Section = {
      ...section,
      sectionId: newId
    };
    updateSection(updatedSection);
    updateIsSaved(false);
  }

  const updateSectionTitle = (newTitle: string) => {
    let cultureIdDefaultLanguage = languages.find(
      (language: LanguageItem) => language.isoCode === defaultLanguage
    )?.id;
    let localeToUpdate = section.formSectionLocales.find(
      (locale: FormSectionLocaleModel) => locale.cultureId === cultureIdDefaultLanguage
    );
    if (localeToUpdate) {
      localeToUpdate.title = newTitle;
    }
    const updatedSection: Section = {
      ...section,
      title: newTitle
    };
    updateSection(updatedSection);
    updateIsSaved(false);
  };

  return (
    <>
      <section className="sectionPreferenceTitle">
        <div className="titlePreferenceTitle">
          <TitleColumn text={t("common.title")} />
        </div>
        <input
          maxLength={200}
          type="text"
          id="inputTitlePreference"
          value={t(section.title).toString()}
          onChange={(event) => {
            updateSectionTitle(event.target.value);
            updateIsSaved(false);
          }}
        />
      </section>
      <IdPreference
        title={t("preferences.section_id")}
        onChangeFunction={changeSectionId}
        preference={section.sectionId}
      />
      <section className="sectionPreferenceTitle">
        <div className="scorePreferenceTitle">
          <TitleColumn text={t("preferences.score")} />
          <Toggle
            isSelected={section.score}
            changeToggleValue={() => {
              let isScored = !section.score;
              if (!isScored) {
                section.weight = 0;
              }
              const updatedScore: Section = {
                ...section,
                score: !section.score
              };
              updateSection(updatedScore);
              updateIsSaved(false);
            }}
          />
        </div>
      </section>
      {section.score && (
        <section className="sectionPreferenceTitle">
          <div className="sessionWeightPreferenceTitle">
            <TitleColumn text={t("preferences.section_weight")} />
            <InputOnlyNumber
              id="inputSessionWeightPreference"
              className="sessionWeight"
              maxLength={200}
              value={section.weight}
              onChange={(value?: number) => {
                const updatedWeight: Section = {
                  ...section,
                  weight: value
                };
                updateSection(updatedWeight);
                updateIsSaved(false);
              }}
            />
          </div>
        </section>
      )}
    </>
  );
};

export default SectionPreference;
