import React from "react";
import "../styles/Rules.scss";
import { useTranslation } from "react-i18next";
import RulesList from "./RulesList";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { INITIAL_VALUE } from "../rulesUtils";
import { RhimButton } from "rhim-ui";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";

const RulesContainer = () => {
  const { t } = useTranslation();

  const form = useStoreState((state) => state.form.form);
  const rules = useStoreState((state) => state.rules.rules);
  const addRule = useStoreActions((action) => action.rules.addRule);

  return (
    <article className="rule-container">
      {rules.length > 0 ? (
        <RulesList rules={rules} />
      ) : (
        <>
          <p className="no-rule-title">Rules</p>
          <Grid container className="no-rule-message" justifyContent="center" alignItems="center">
            <Grid item>
              <p>{t("messages.empty_rule")}</p>
            </Grid>
          </Grid>
        </>
      )}
      <RhimButton
        variant="outlined"
        icon={<Icon>add</Icon>}
        title={t("add").toUpperCase()}
        onClick={() => {
          addRule({
            ...INITIAL_VALUE,
            name: `${t("common.rule")} ${rules.length + 1}`,
            id: rules.length,
            formId: form!.formId
          });
        }}
      />
    </article>
  );
};

export default RulesContainer;
