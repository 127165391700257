import { Dictionary } from "lodash";
import { getFilterFieldPreferences } from "../feature/catalog/CatalogUtil";
import { CatalogItem } from "../feature/catalog/interface/CatalogItem";
import { ItemPreference } from "../repositories/interfaces";

export default function UpdatePreference(fieldItems: Dictionary<CatalogItem[]>) {
  //add fild Id preference
  Object.keys(fieldItems).forEach((key) => {
    fieldItems[key].forEach((field) => {
      let hasFieldId = field.preferences.some(
        (preference: ItemPreference) => preference.key === "Fieldid"
      );
      if (!hasFieldId) {
        const itemPreference: ItemPreference = { key: "Fieldid", value: "" };
        field.preferences.push(itemPreference);
      }
      if (field.type === "pictureFile") {
        let setDefaultPicturePreference = !field.preferences.some(
          (preference: ItemPreference) => preference.key === "Min"
        );
        if (setDefaultPicturePreference) {
          let min: ItemPreference = { key: "Min", value: "1" };
          let max: ItemPreference = { key: "Max", value: "1" };
          field.preferences.push(min, max);
        }
      }
      getFilterFieldPreferences(field);
    });
  });

  return fieldItems;
}
