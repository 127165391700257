import React, { useState, useEffect } from "react";
import "./DataSearch.scss";
import DataListItem from "./DataListItem";
import DataList from "./DataList";
import InputSearch from "./InputSearch";
import useDebounce from "./useDebounce";
import { GraphUser } from "../../../../models/Graph/GraphUser";

export interface DataSearchProps {
  filter: (value: string) => Promise<GraphUser[]>;
  onItemClick: (value: GraphUser) => void;
}

const DataSearch = (props: DataSearchProps) => {
  const { filter, onItemClick } = props;
  const [searchItem, setSearchItem] = useState("");
  const [listVisibility, setListVisibility] = useState(false);
  const [list, setList] = useState<GraphUser[]>([]);
  const debouncedSearchTerm = useDebounce(searchItem, 500);

  useEffect(() => {
    const search = async () => {
      setListVisibility(true);
      if (debouncedSearchTerm) {
        setList([]);
        const filteredList = await filter(searchItem);
        setList(filteredList);
      } else {
        setList([]);
      }
    };
    if (searchItem !== "") {
      search();
    } else {
      setListVisibility(false);
    }
  }, [debouncedSearchTerm, filter, searchItem]);

  const itemClickHandler = (itemValue: GraphUser) => {
    setList([]);
    setSearchItem("");
    onItemClick(itemValue);
  };

  return (
    <div className="data-list-container">
      <InputSearch value={searchItem} onChangeHandler={setSearchItem}></InputSearch>
      <DataList
        visibility={listVisibility}
        items={list}
        render={(list: GraphUser[]) =>
          list.map((item, i) => {
            return <DataListItem key={i} item={item} onClick={itemClickHandler}></DataListItem>;
          })
        }></DataList>
    </div>
  );
};

export default DataSearch;
