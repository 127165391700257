import React from "react";
import "./WarningPage.scss";
import { useTranslation } from "react-i18next";
import { WarningRounded } from "@material-ui/icons";
import { RhimButton } from "rhim-ui";
import { useNavigate } from "react-router";
interface WarningPageProps {
  title: string;
  text: string;
}

const WarningPage = (props: WarningPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <main className="warning-page">
      <WarningRounded className="tools-icon" />
      <h1 className="warning-title">{props.title}</h1>
      <span className="warning-text">{props.text}</span>
      <RhimButton
        onClick={() => {
          navigate("/MyForms");
        }}
        title={t("messages.go_back_my_forms")}
        variant="outlined"
      />
    </main>
  );
};

export default WarningPage;
