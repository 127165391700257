import React from "react";
import "./DateTimeBuilder.scss";

interface DateTimeBuilderProps {
  title: string;
  mandatory: boolean;
}

const DateTimeBuilder = (props: DateTimeBuilderProps) => {
  const { title, mandatory } = props;
  return (
    <div className="datetime-builder field-item">
      <h2 className="title">
        {title}
        {mandatory && <span className="mandatory-signal">* </span>}
      </h2>
      <input
        className="content"
        type="text"
        value={"1900/01/01 00:00"}
        color="$darkGrey"
        readOnly
      />
    </div>
  );
};

export default DateTimeBuilder;
