import api, { v1 } from "../../repositories/API/Axios.api";
import { Form } from "./interface/Form";

export const getFormsByOwner = async () => {
  const response: any = await api.get<Form[]>(v1("Form/ByOwner"));
  return response?.data;
};

export const putForm = async (form: Form) => {
  const response: any = await api.put(v1("Form"), JSON.stringify(form));
  if (response) {
    let data: Form = response.data?.data;
    data?.formSections?.forEach((section) =>
      section.formFields.forEach((field: any) => (field.options = field.fieldOptions))
    );
    response.data.data = data;
    return response.data;
  }
  return response?.data;
};

export const postForm = async (formModel: Form) => {
  const response: any = await api.post<[Form]>(v1("Form"), JSON.stringify(formModel));
  return response?.data;
};

export const getFormById = async (formId: number) => {
  const response: any = await api.get<Form>(v1("Form/" + formId));
  if (response) {
    let data: Form = response.data;
    data?.formSections?.forEach((section) =>
      section.formFields.forEach((field: any) => {
        field.options = field.fieldOptions;
      })
    );
    return data;
  }
  return response?.data;
};

export const toggleFormActive = async (formId: number) => {
  const response: any = await api.put(v1(`Form/${formId}/toggleEnable`));
  return response?.data;
};

export const duplicateForm = async (formId: number, formTitle: string) => {
  const response: any = await api.post<Form>(v1(`Form/${formId}/duplicate`), JSON.stringify(formTitle));
  return response?.data;
};

export const deleteForm = async (formId: number) => {
  const response: any = await api.delete<Form>(v1(`Form/${formId}/delete`));
  return response?.data;
};

export const formHasAnswers = async (formId: number) => {
  const response: any = await api.get(v1(`Form/${formId}/hasAnswers`));
  return response?.data;
};
