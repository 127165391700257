import React, { Component } from "react";
import "./styles/ErrorBoundary.scss";

export default class ErrorBoundary extends Component<any, any> {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.clear();
    console.log(`Ocorreu um erro na renderização`);
    console.log("Error :", error);
    console.log("ErrorInfo :", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <span className="error-boundary-text">An error occurred while rendenring</span>;
    }
    return this.props.children;
  }
}
