import React from "react";
import "./DescriptionPreference.scss";
import { ItemPreference } from "../../../../repositories/interfaces";
import TitleColumn from "../../atoms/TitleColumn/TitleColumn";
import { useTranslation } from "react-i18next";

interface DescriptionPreferenceProps {
  onChangeFunction: (preference: ItemPreference) => void;
  preference: ItemPreference;
  clear: (key: string, value: string) => {};
  title: string;
}

const DescriptionPreference = (props: DescriptionPreferenceProps) => {
  const { t } = useTranslation();
  const { onChangeFunction, preference, clear, title } = props;
  return (
    <section id="DescriptionPreference">
      <div className="titlePreferenceTitle">
        <TitleColumn text={title} />
      </div>
      <textarea
        className="inputDescriptionPreference"
        maxLength={280}
        id="inputDescriptionPreference"
        value={t(preference.value).toString()}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
          preference.value = event.target.value;
          onChangeFunction(preference);
          clear(preference.key, event.target.value);
        }}
      />
    </section>
  );
};

export default DescriptionPreference;
