import React, { useEffect } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

export interface LoadingProps {
  isLoading: boolean;
}

const Loading = (props: LoadingProps) => {
  const classes = useStyles();
  const { isLoading } = props;
  useEffect(() => {}, [isLoading]);
  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
