import React from "react";
import "./InputOnlyNumber.scss";

export interface InputOnlyNumberProps {
  id?: string;
  className?: string;
  value?: number | "";
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  onChange: Function;
  customStyle?: any;
}

const InputOnlyNumber = (props: InputOnlyNumberProps) => {
  const { id, className, placeholder, disabled, value, maxLength, onChange, customStyle } = props;

  return (
    <input
      id={id}
      className={className}
      style={{ ...customStyle }}
      placeholder={placeholder}
      type="number"
      maxLength={maxLength}
      min="0"
      inputMode="numeric"
      disabled={disabled}
      value={value || value === 0 ? value : ""}
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
        const regex = /\d/;
        const verify =
          event.key === "ArrowRight" ||
          event.key === "ArrowLeft" ||
          event.key === "ArrowUp" ||
          event.key === "ArrowDown" ||
          event.key === "Delete" ||
          event.key === "Backspace" ||
          regex.test(event.key);
        if (!verify) {
          event.preventDefault();
        }
      }}
      onPaste={(event: any) => {
        event.preventDefault();
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value !== "" || event.target.value !== null)
          onChange(Number(event.target.value));
        else onChange(undefined);
      }}
    />
  );
};

export default InputOnlyNumber;
