import React, { useState } from "react";
import "./styles/CatalogList.scss";
import { CatalogItem, CatalogTranslation } from "../interface/CatalogItem";
import Icon from "@material-ui/core/Icon";

export interface CatalogListProps {
  title: string;
  items: CatalogItem[];
  initialOpenState: boolean;
  render: (items: CatalogItem[]) => JSX.Element[];
  addField: (payload: CatalogTranslation) => void;
}

const CatalogList = (props: CatalogListProps) => {
  const { title, items, initialOpenState, render } = props;
  const [open, setOpen] = useState(initialOpenState);
  return (
    <article className="catalog">
      <section
        className={open ? "catalog-header open-catalog" : "catalog-header"}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <p>{title}</p>
        <Icon className="collapse-icon">{open ? "expand_less" : "expand_more"}</Icon>
      </section>
      <section className={open ? "catalog-items" : "catalog-items invisible"}>
        {render(items)}
      </section>
    </article>
  );
};

export default CatalogList;
