import { sectionModel, SectionModelInterface } from "./section";
import { catalogModel, CatalogModelInterface } from "../../feature/catalog";
import { languagesModel, LanguageModelInterface } from "../../feature/languages";
import { authModel, AuthModelInterface } from "./auth";
import { formModel, FormModelInterface } from "../../feature/form";
import { fieldModel, FieldModelInterface } from "./field";
import { loadingModel, LoadingModelInterface } from "../../feature/loading";
import { notificationModel, NotificationModelInterface } from "../../feature/notification";
import { rulesModel, RulesModelInteface } from "../../feature/rules";

export interface StoreModel {
  sections: SectionModelInterface;
  catalogs: CatalogModelInterface;
  languages: LanguageModelInterface;
  fields: FieldModelInterface;
  form: FormModelInterface;
  auth: AuthModelInterface;
  loading: LoadingModelInterface;
  notification: NotificationModelInterface;
  rules: RulesModelInteface;
}

const model: StoreModel = {
  sections: sectionModel,
  fields: fieldModel,
  catalogs: catalogModel,
  languages: languagesModel,
  form: formModel,
  auth: authModel,
  loading: loadingModel,
  notification: notificationModel,
  rules: rulesModel
};

export default model;
