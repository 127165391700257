import React from "react";

interface OptionsListDropdownBuilderProps {
  title: string;
  optionsList: any[];
  getLengthFunction: (arrayAux: any[]) => number;
}

const OptionsListDropdownBuilder = (props: OptionsListDropdownBuilderProps) => {
  const { title, optionsList, getLengthFunction } = props;

  const getOptions = (type: string, arrayOpts: any[]) => {
    let optsEl: JSX.Element[] = [];
    if (getLengthFunction(arrayOpts) > 0) {
      for (let i = 0; i < getLengthFunction(arrayOpts); i++) {
        optsEl.push(
          <span className="option" key={i}>
            <p>{arrayOpts[i].option}</p>
          </span>
        );
      }
    }
    return optsEl;
  };

  const isFirstOptionValid =
    optionsList[0] !== undefined &&
    (title === "Dropdown" ? optionsList[0]?.option !== undefined : true);

  const dropdownStyle = isFirstOptionValid ? "options" : "options empty";

  return <div className={dropdownStyle}>{getOptions(title, optionsList)}</div>;
};

export default OptionsListDropdownBuilder;
