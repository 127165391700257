export default {
  "common.cancel": "İptal",
  "common.delete": "Sil",
  "common.duplicate": "Kopyala",
  "common.title": "Başlık",
  "common.upload": "Upload",
  "common.create": "Oluştur",
  "common.file": "Dosya",
  "common.section": "Bölüm",
  "common.add": "Ekle",
  "common.continue": "Devam",
  "common.confirm": "Onayla",
  "common.save": "Kaydet",
  "common.saved": "Kaydedildi",
  "common.yes": "Evet",
  "common.no": "Hayır",
  "common.skip": "Atla",
  "common.ok": "Tamam",
  "common.success": "Başarılı",
  "common.sign_out": "Oturumu Kapat",
  "common.answer": "Cevapla",
  "common.rule": "Kural",

  "message.createAnswer.success": "Cevabınız başarılı şekilde kaydedildi",
  "message.createAnswer.error.answer": "Cevap oluşturulamadı",
  "message.createAnswer.error.image": "Görüntü oluşturma başarısız",
  "message.createAnswer.error.image.type": "Geçersiz resim türü",
  "message.getAnswers.error.invalidInput":
    "Yanıtları filtrelemek için lütfen maksimum günlük bir süre girin.{{maxDays}}",
  "message.getAnswers.error.answer": "Cevaplar ayrıştırılamadı",
  "message.getAnswers.error.image": "Resimler ayrıştırılamadı",
  "message.formValidation.missingTitle": "Lütfen Başlık alanını doldurun",
  "message.formValidation.missingEmail": "Lütfen e-posta alanını doldurun",
  "message.formValidation.missingSectionTitle": "Please fill the title of all sections",
  "message.formValidation.missingFieldTitle": "Lütfen tüm bölümlerin başlığını doldurun",
  "message.formValidation.missingFieldType": "Lütfen tüm alanların türünü seçin",
  "message.formValidation.missingFieldId": "Lütfen tüm alanlar için bir kullanıcı girin.",
  "message.formValidation.missingEquipmentMrk": "Please select a equipment MRK on equipment field",
  "message.formValidation.missingSection":
    "Formun düzgün çalışması için en az bir bölüm gereklidir",
  "message.formValidation.multipleCustomerField":
    "Bir form müşteri alanında yalnızca bir SAR'a sahip olabilir",
  "message.formValidation.missingCustomerField":
    "SAR ekipmanı içeren bir form oluşturmak için,  alan kısmına SAR müşteri girin",
  "message.formValidation.missingEquipmentField":
    "To create a form with SAR Campaign/Ending Reason, please put a field of type SAR Equipment before",
  "message.formValidation.wrongCustomerFieldPositionAfterEquipment":
    "SAR Müşterisi, SAR Ekipmanından önce girilmelidir",
  "message.formValidation.wrongCustomerFieldPositionAfterCampaign":
    "SAR Müşterisi SAR Kampanyasından önce girilmelidir",
  "message.formValidation.wrongCustomerFieldPositionAfterEndingReason":
    "SAR Müşterisinin bir SAR Bitiş Nedeninden önce girilmesi gerekiyor",
  "message.formValidation.wrongEquipmentFieldPosition":
    "SAR kampanyadan önce SAR ekipman girilmelidir",
  "message.formValidation.wrongEquipmentFieldPositionAfterCampaign":
    "SAR Ekipmanının SAR Kampanyasından önce girilmesi gerekiyor",
  "message.formValidation.wrongEquipmentFieldPositionAfterEndingReason":
    "SAR Ekipmanının SAR Kullanımdan Kaldırma Nedeninden önce girilmesi gerekiyor",
  "message.formValidation.failureValidatingRules": "Form kuralları onaylanırken hata",
  "message.formValidation.missingTargetOptionsInRules": "Soru seçenekleri form kurallarında eksik",
  "message.createForm.success": "Form başarılı bir şekilde kaydedildi",
  "message.createForm.error": "Form kaydedilirken hata. Tekrar deneyin",
  "message.updateForm.success": "Form başarılı bir şekilde kaydedildi",
  "message.updateForm.error": "Form kaydedilirken hata",
  "message.toggleFormEnable.success.enable": "Form başarılı bir şekilde etkinleştirildi",
  "message.toggleFormEnable.success.disable": "Form etkinliği başarılı bir şekilde kaldırıldı",
  "message.toggleFormEnable.error": "Form etkinleştirme/ etkinliği kaldırma hatası",
  "message.hasAnswers": "Formda cevaplar bulunuyor",
  "message.hasNoAnswers": "Formda hiç cevap yok",
  "message.hasAnswers.error": "Form cevapları kontrol edilirken hata",
  "message.deleteForm.success": "Form başarılı bir şekilde silindi",
  "message.deleteForm.error": "Form silerken hata",
  "message.duplicate.modal.title": "Kopyanızın hangi isimle kaydedilmesini istersiniz?",
  "message.duplicateForm.success": "Form başarılı bir şekilde kopyalandı",
  "message.duplicateForm.error": "Form kopyalarken hata",
  "message.getMrk.error": "MRK alınırken hata",
  "message.getCulture.error": "Desteklenmeyen dil",

  "messages.oops": "Hata",
  "messages.link_copied": "Bağlantı kopyalandı",
  "messages.confirm_delete":
    "Are you sure you want to delete {{formTitle}}? This action can not be undone.",
  "messages.confirm_delete_answers":
    "Form başlığını silmek istediğinize emin misiniz {{formTitle}}? Bu aksiyon geri alınamaz.",
  "messages.confirm_title_duplicate":
    "Aşağıdaki isimle listenin en üstüne gönderilecek:",
  "messages.required_fields": "Lütfen gerekli tüm alanları doldurun",
  "messages.error_support":
    "Bu aksiyonu gerçekleştirirken bir hata oluştu. Hata tekrar ederse, desteğe başvurun.",
  "messages.error_field_id": "Lütfen alan ID'sini kontrol edin",
  "messages.error_field_dropdown": "Lütfen alan seçeneklerini kontrol edin",
  "messages.error_field_dropdown.title":
    "Açılır menüden her hangi bir seçimde bulunmadan kayıt yapamazsınız",
  "messages.error_field_weight": "Lütfen her alanın ağırlığını kontrol edin",
  "messages.error_all_field_weight": "Lütfen tüm alan ağırlıklarını tamamlayın",
  "messages.error_fields_repeated_id":
    "The field {{fieldIdA}} ID of section {{sectionIdA}} is the same as the field {{fieldIdB}} of section {{sectionIdB}}.",
  "messages.error_section_repeated_id": "Aynı ID ye sahip bölümler mevcut",
  "messages.error_section_name": "Lütfen bölüm adını kontrol edin",
  "messages.error_section_id": "Lütfen bölüm ID'sini kontrol edin",
  "messages.error_section_repeated_name": "Aynı isme sahip bölümler mevcut",
  "messages.error_mrk": "MRK bulunamadı",
  "messages.error_multiphotos_preferences": "Çoklu fotoğraf seçeneklerini kontrol edin",
  "messages.error_invalid_preference": "Geçersiz tercihler bulunuyor",
  "messages.construction": "Bu bölüm deneyiminizi iyileştirmek için çalışma altındadır",
  "messages.languages_note":
    "Eğer bu alanı boş bırakırsanız, kullanıcı için varsayılan dil olarak gösterilecektir.",
  "messages.users_not_found": "Kullanıcı bulunamadı",
  "messages.confirm_rules": "Bu form için kurallar tanımlamak ister misiniz ?",
  "messages.empty_rule": "Eğer şart içeren bir kural gerekiyorsa bu alanda oluşturabilirsiniz",
  "messages.empty_section": "Bölüm içerisine eklemek istediğiniz alanı seçin",
  "messages.saved":
    "formunuz başarılı bir şekilde kaydedildi! Şimdi formlarım bölümünde görüntüleyebilirsiniz",
  "messages.sign_out": "Sistemden ayrılmak istiyor musunuz ? ",
  "messages.cannot_change_form_status":
    "Ortak sahip olduğunuz için bu formu etkinleştiremez veya devre dışı bırakamazsınız.",
  "pages.my_forms": "Formlarım",
  "pages.new_form": "Yeni form",
  "pages.feedback": "Geribildirim",
  "pages.builder": "Oluşturucu",
  "pages.rules": "Kurallar",
  "pages.languages": "Diller",
  "pages.users": "Kullanıcılar",
  "pages.usersAndCoOwners": "Kullanıcılar ve ortak sahipler",
  "my_forms.id": "ID",
  "my_forms.form_code": "Form Kodu",
  "my_forms.creation_date": "Oluşturulma tarihi",
  "my_forms.updated_date": "Son değişiklikler",
  "my_forms.enable": "Etkinleştir",
  "my_forms.owner": "Sahip",
  "my_forms.actions": "Aksiyonlar",
  "actions.form": "Formu düzenle",
  "actions.rules": "Kuralları düzenle",
  "actions.users": "Kullanıcıları düzenle",
  "actions.languages": "Dilleri düzenle",
  "actions.power_bi": "Power BI bağlantısı",
  "actions.export": "Formu dışa aktar",
  "actions.delete": "Formu sil",
  "actions.duplicate": "Formu kopyala",
  "new_form.import": "Yeni formu içe aktar",
  "new_form.creation_language": "Dil oluşturma",
  "languages.portuguese": "Dil oluşturma",
  "languages.english": "İngilizce",
  "languages.spanish": "İspanyolca",
  "languages.german": "Almanca",
  "languages.mandarin": "Çince",
  "languages.french": "Fransızca",
  "languages.turkish": "Türkçe",
  "languages.czech": "Çekçe",
  "languages.polish": "Lehçe",
  "languages.slovenian": "Slovenian",
  "languages.swedish": "İsveççe",
  "languages.kosovo_albanian": "Arnavutçası",
  "fields.text": "Kısa metin",
  "fields.textarea": "uzun metin",
  "fields.number": "sayısal",
  "fields.datetime": "tarih ve saat",
  "fields.photo": "fotoğraf",
  "fields.dropdown": "Açılır menü",
  "fields.single_option": "Tek seçenek",
  "fields.customer": "Müşteri",
  "fields.equipment": "Ekipman",
  "fields.campaign": "Kampanya",
  "fields.end_reason": "Sonuç sonlandırılıyor",
  "fields.qr_code": "Kare Kod",
  "fields.signature_qr": "Kare Kod İmza",
  "fields.boolean": "Tek seçenek",
  "fields.dropdown-campaign": "Kampanya",
  "fields.dropdown-campaign-end-reason": "Sonuç sonlandırılıyor",
  "fields.dropdown-customer": "Müşteri",
  "fields.dropdown-equipment": "Ekipman",
  "fields.pictureFile": "fotoğraf",
  "fields.qrbarcode": "Kare Kod",
  "fields.signature-qrcode": "Signature QR",
  "constructor.fields": "Alanlar",
  "constructor.filter": "Filtre",
  "constructor.by.equipment": "Ekipmana göre filtre",
  "constructor.by.customer": "müşteriye göre filtre",
  "constructor.basic": "Temel",
  "constructor.rhim": "RHIM Entegrasyon",
  "constructor.section_title": "Bölüm başlığı",
  "constructor.unsaves_changes": "Kaydedilmemiş değişiklikler",
  "constructor.mrk": "MRK ekipman",
  "constructor.preferences": "Tercihler",
  "preferences.placeholder": "Tercihlerini değiştirmek istediğiniz alanı seçin",
  "preferences.checklistValue": "İşaretlenen maksimum seçenekler",
  "preferences.required": "Gerekli",
  "preferences.score": "Skor",
  "preferences.options": "Seçenekler",
  "preferences.automatic": "Otomatik",
  "preferences.automatic_id": "Otomatik ID",
  "preferences.field_id": "Alan ID",
  "preferences.field_weight": "Alan Ağırlığı",
  "preferences.section_weight": "Bölüm Ağırlığı",
  "preferences.section_id": "Bölüm ID",
  "preferences.options_placeholder": "nesneleri buraya girin ve enter ile birbirinden ayırın",
  "preferences.mrk_placeholder": "MRK seçin",
  "preferences.max": "Maksimum",
  "preferences.min": "Minimum",
  "preferences.formDescription": "Use as form description",
  "preferences.typeQrOrBarCode": "Tür",
  "preferences.IntegerOrFloat": "Tür",
  "preferences.decimalPlacesMin" : "Maksimum ondalık basamak",
  "preferences.DecimalPlaces": "DecimalPlaces",
  "preferences.option_integer": "Tamsayı",
  "preferences.option_decimal": "Ondalık",
  "preferences.error_empty_qrcode": "Choose at least one option",
  "add_options.option": "Seçenek",
  "add_options.weight": "Ağırlık",
  "add_options.response": "Cevap",
  "add_options.add_na": "İlgili Değil girin",
  "construction.go_back": "Geri",
  "languages.notes": "Notlar",
  "users.email": "Kullanıcı e-postaları",
  "coOwners.email": "Ortak sahipler e-postası",
  "users.multiple_association":
    "çoklu ilişkilendirme (noktalı virgül ayırıcılı bir e-posta listesiyle doldurun) ",
  "users.finish": "Formu tamamla",

  "globalization.title": "Write your form title in",
  "globalization.section": "Write your section title in",
  "globalization.field": "Write your field name in",
  "globalization.field.description": "Write your field's description in",
  "globalization.option": "Write your option value in",

  "rules.if": "Eğer",
  "rules.status": "Durum",
  "rules.response": "Cevap",
  "rules.action": "Aksiyon",
  "rules.question": "Soru",
  "rules.options": "Seçenekler",

  "option.is": "Is",
  "option.isn't": "Isn't",
  "option.hide.section": "Bölümü gizle",
  "option.hide.field": "Alanı gizle",
  "option.show.section": "Bölümü göster",
  "option.show.field": "Alanı göster",
  "option.filter": "Filtre",
  "option.required": "Gerekli",

  "pagination.view": "iki değer arasını gösteriyor {{from}}-{{to}}  {{count}}",
  "pagination.rows_per_page": "Sayfa başına satır:",
  "messages.error_form_access_denied":
    "Bu forma erişiminiz yok. Lütfen yazarı veya ortak yazarı olduğunuz bir form arayın",
  "messages.page_not_found": "sayfa bulunamadı",
  "messages.text_page_not_found": "Üzgünüz, erişmeye çalıştığınız sayfa mevcut değil.",
  "messages.go_back_my_forms": "Formlarıma Dön",
  "messages.access_denied": "Erişim engellendi",
  "messages.text_access_denied":
    "Bu forma erişiminiz yok. Lütfen yazar veya ortak yazar olduğunuz bir form arayın.",
  "messages.form_not_found": "form bulunamadı",
  "messages.text_form_not_found":
    "Üzgünüz, erişmeye çalıştığınız form mevcut değil. Lütfen başka bir form arayın veya yeni bir form oluşturun.",
  "message.formValidation.multipleEquipmentInSection":
    "Form, bölüm başına yalnızca bir SAR Ekipmanı alanına sahip olabilir",
  "preferences.description": "Tanım",
  "messages.tooltip_decimal_minimum_alert":
    "2 ondalık basamağa bağlı olarak, ondalık tipi alanlar için minimum karakter sınırı 3'tür.",
  "message.form_last_update": "Son güncelleme",
  "message.form_last_update_by": "Yazan"
};
