import { ItemPreference } from "../../repositories/interfaces";
import { LanguageItem } from "../languages/interface/LanguageItem";
import { CatalogItem } from "./interface/CatalogItem";

export const getItemId = (t: Function, preferences: ItemPreference[]): string => {
  const titlePreference = preferences.find(
    (preference) => preference.key === "Title" || preference.key === "FixedTitle"
  );

  return t(titlePreference?.value) ?? "";
};

export const getBasicItems = (items: CatalogItem[]) => {
  let basicItems: CatalogItem[] = [];
  items.forEach((item) => {
    if (!item.type.includes("dropdown-")) {
      basicItems.push(item);
    }
  });
  return basicItems;
};

export const getSarIntegrationItems = (items: CatalogItem[]) => {
  let sarIntegrationItems: CatalogItem[] = [];
  items.forEach((item) => {
    if (item.type.includes("dropdown-")) {
      sarIntegrationItems.push(item);
    }
  });
  return sarIntegrationItems;
};

const SAR_FIELDS = ["dropdown-campaign", "dropdown-equipment"];

export const getFilterFieldPreferences = (item: CatalogItem) => {
  if (SAR_FIELDS.includes(item.type)) {
    const filterPreference = item.preferences.find((preference) => preference.key === "Filter");
    if (!filterPreference) {
      item.preferences.splice(item.preferences.length - 1, 0, {
        key: "Filter",
        value: "true"
      });
    }
  }
  return item;
};

export const addFieldLocalesToNewField = (
  field: CatalogItem,
  languageList: LanguageItem[],
  defaultLanguage: LanguageItem | undefined,
  t: Function
) => {
  field.formFieldLocales = [];
  languageList.forEach((language: LanguageItem) => {
    if (defaultLanguage?.id === language.id) {
      field.formFieldLocales.push({
        cultureId: language.id,
        formFieldId: 0,
        title: t(field.name),
        description: "",
      });
    } else {
      field.formFieldLocales.push({
        cultureId: language.id,
        formFieldId: 0,
        title: "",
        description: ""
      });
    }
  });
  return field;
};

export const createPreferenceOptions = (field: any, languageList: LanguageItem[]) => {
  let fieldPreferenceOptions = field.preferences.find((preference: ItemPreference) =>
    preference.key.includes("ListItemsRadio")
  );

  field["options"] = [];
  field["fieldOptions"] = [];

  field["options"].push({
    index: 0,
    weight: null,
    isNA: null,
    option: "",
    formOptionLocales: []
  });
  field["fieldOptions"].push({
    index: 0,
    weight: null,
    isNA: null,
    option: "",
    formOptionLocales: []
  });

  field["options"].push({
    index: 1,
    weight: null,
    isNA: null,
    option: "",
    formOptionLocales: []
  });
  field["fieldOptions"].push({
    index: 1,
    weight: null,
    isNA: null,
    option: "",
    formOptionLocales: []
  });

  field["options"].forEach((option: any) => {
    languageList.forEach((language: LanguageItem) => {
      option.formOptionLocales.push({
        cultureId: language.id,
        option: ""
      });
    });
  });

  field["fieldOptions"].forEach((option: any) => {
    languageList.forEach((language: LanguageItem) => {
      option.formOptionLocales.push({
        cultureId: language.id,
        option: ""
      });
    });
  });

  if (fieldPreferenceOptions) {
    let options: any[] = JSON.parse(fieldPreferenceOptions.value);
    options.push({
      index: 0,
      weight: null,
      isNA: null,
      option: "",
      formOptionLocales: []
    });
    options.push({
      index: 1,
      weight: null,
      isNA: null,
      option: "",
      formOptionLocales: []
    });

    options.forEach((option: any) => {
      languageList.forEach((language: LanguageItem) => {
        option.formOptionLocales.push({
          cultureId: language.id,
          option: ""
        });
      });
    });
    fieldPreferenceOptions.value = JSON.stringify(options);
  }
  return field;
};
