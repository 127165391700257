import React from "react";
import "./CreateFormButton.scss";
import { useTranslation } from "react-i18next";
import { RhimButton } from "rhim-ui";

interface ButtonProps {
  isActive: boolean;
  onClickFunction: () => void;
}

const CreateFormButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const { isActive } = props;
  const { onClickFunction } = props;

  return (
    <span id="create-form-button-container">
      <RhimButton
        id="create-form-button"
        variant="contained"
        title={t("common.create").toUpperCase()}
        onClick={isActive ? onClickFunction : () => {}}
      />
    </span>
  );
};

export default CreateFormButton;
