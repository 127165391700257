import { TextField, InputAdornment} from "@material-ui/core";
import "./MaxLengthTextField.scss";
import React, { useState } from "react";

interface MaxLengthTextFieldProps {
    maxLength: number;
    value: string;
    onValueChange: (newValue: string) => void;
    inputRedAlert?: boolean;
  }
  
  const MaxLengthTextField = (props : MaxLengthTextFieldProps) => {
    const { maxLength, value, onValueChange, inputRedAlert = false } = props;
    

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      onValueChange(newValue); 
  };

    return (
      <TextField
        value={value}
        onChange={handleChange} 
        className="input-text-field"
        error={inputRedAlert}
        required
        fullWidth
        inputProps={{
          maxLength,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {`${value.length}/${maxLength}`}
            </InputAdornment>
          ),
        }}
        variant="outlined"
        margin="dense"
      />
    );
  };

  export default MaxLengthTextField;