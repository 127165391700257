import React from "react";

interface LoginPageProps {}

const LoginPage = (props: LoginPageProps) => {
  return (
    <>
      <button> Login </button>
    </>
  );
};

export default LoginPage;
