import "../styles/Rules.scss";

import React from "react";
import RuleItem from "./RuleItem";
import { RulesListProps } from "../interface/rulesInterface";

const RulesList = (props: RulesListProps) => {
  const { rules } = props;
  
  return (
    <>
      {rules
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .map((rule, index) => {
          return <RuleItem rule={rule} index={index} key={`rule-${index}`} />;
        })}
    </>
  );
};

export default RulesList;
