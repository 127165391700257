import React from "react";
import { LanguageItem } from "../interface/LanguageItem";
import "./styles/LanguageListItem.scss";
import { Icon } from "@material-ui/core";

export interface LanguageListItemProps {
  item: LanguageItem;
  isItemActive: boolean;
  isLanguageSelected: boolean;
  selectLanguage: (item: LanguageItem) => void;
  deleteLanguage: (item: LanguageItem) => void;
}

const LanguageListItem = (props: LanguageListItemProps) => {
  const { item, isItemActive, isLanguageSelected, selectLanguage, deleteLanguage } = props;
  const selectLanguageHandler = () => {
    selectLanguage(item);
  };
  const deleteLanguageHandler = () => {
    deleteLanguage(item);
  };

  const isItemActiveStyle = isItemActive && isLanguageSelected ? " selected" : "";

  const classNameItem = isItemActive
    ? `languages-list-item-active${isItemActiveStyle}`
    : "languages-list-item-unactive";

  const iconProps = {
    children: isItemActive ? "delete" : "add",
    className: isItemActive ? "remove icon" : "add icon",
    onClickHandle: isItemActive ? deleteLanguageHandler : () => {}
  };

  return (
    <div className={classNameItem} key={`language-item-${item.isoCode}`}>
      <button className="item-button" type="button" onClick={selectLanguageHandler}>
        {item.description}
        <Icon onClick={iconProps.onClickHandle} className={iconProps.className}>
          {iconProps.children}
        </Icon>
      </button>
    </div>
  );
};

export default LanguageListItem;
