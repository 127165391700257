import "./ConfirmationModal.scss";

import { Icon } from "@material-ui/core";
import { ModalType } from "../enum/ModalType";
import React from "react";
import { RhimDialog } from "rhim-ui";

export interface ConfirmationModalProps {
  title: string;
  message: string;
  type: ModalType;
  visibility: boolean;
  setVisibility: (visibility: boolean) => void;
  confirmationText: string;
  onConfirmationClick?: any;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { title, message, confirmationText, onConfirmationClick, type, visibility, setVisibility } =
    props;

  const renderIcon = (type: ModalType) => {
    switch (type) {
      case ModalType.Success:
      case ModalType.Error:
        const icon = getIconByType(type);
        return <Icon className={`confirmation-modal-icon ${type}`}>{icon}</Icon>;
      default:
        return <React.Fragment></React.Fragment>;
    }
  };

  const getIconByType = (type: ModalType) => {
    const icon = type === ModalType.Success ? "check" : "clear";
    return icon;
  };

  const renderTitle = (icon: JSX.Element, title: string) => {
    return (
      <p className={"modal-header"}>
        {icon}
        <h1 className="modal-title">{title}</h1>
      </p>
    );
  };

  const renderMessage = (message: string) => {
    return <p className="modal-text">{message}</p>;
  };

  return (
    <RhimDialog
      isMobile={false}
      title={renderTitle(renderIcon(type), title)}
      isOpen={visibility}
      cancel={{
        title: confirmationText,
        handleFunction: () => {
          if (onConfirmationClick) onConfirmationClick();
          setVisibility(!visibility);
        }
      }}
      content={<p className="modal-text">{renderMessage(message)}</p>}
    />
  );
};

export default ConfirmationModal;
