import React from "react";
import "./FilterPreference.scss";

import { useTranslation } from "react-i18next";
import { CatalogItem } from "../../../../feature/catalog/interface/CatalogItem";
import TitleColumn from "../../atoms/TitleColumn/TitleColumn";
import Toggle from "../../atoms/Toggle/Toggle";

interface FilterPreferenceProps {
  selectedField: CatalogItem;
  setField: Function;
}

const FilterPreference = (props: FilterPreferenceProps) => {
  const { t } = useTranslation();
  const { selectedField, setField } = props;

  const isSelected =
    selectedField.preferences.find((preference: any) => preference.key === "Filter")?.value ===
    "true";

  const filterTitle = selectedField.type.includes("equipment")
    ? "constructor.by.customer"
    : "constructor.by.equipment";

  return (
    <section className="filter-preference">
      <TitleColumn text={t(filterTitle)} />
      <Toggle
        isSelected={isSelected}
        changeToggleValue={() => {
          const newSelectValue = isSelected ? "false" : "true";
          const newPreferences = selectedField.preferences.map((preference: any) => {
            if (preference.key === "Filter") return { ...preference, value: newSelectValue };
            return preference;
          });
          selectedField.preferences = newPreferences;
          setField(selectedField);
        }}
      />
    </section>
  );
};

export default FilterPreference;
