import { Theme } from "@material-ui/core";
import { rhimTheme } from "rhim-ui";
import { colors } from "./theme";

const { greyThree } = colors;

const customTheme = {
  ...rhimTheme, overrides: {
    ...rhimTheme.overrides,
    MuiTooltip: {
      arrow: {
        color: greyThree
      },
      tooltip: {
        fontSize: 14,
        backgroundColor: greyThree,
        textAlign: "center",
        padding: 12
      }
    }
  }
} as Theme;

export default customTheme;