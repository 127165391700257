import React from "react";
import "./QRBarCodeBuilder.scss";
import { Icon } from "@material-ui/core";

interface DropdownBuilderProps {
  title: string;
  mandatory: boolean;
  isSignature: boolean;
}

const QRBarCodeBuilder = (props: DropdownBuilderProps) => {
  const { title, mandatory, isSignature } = props;

  return (
    <div className="qr-bar-code-builder field-item">
      <h2 className="title">
        {title}
        {mandatory && <span className="mandatory-signal">* </span>}
      </h2>
      <div className="content">
        <div className="input-qr-bar-code">
          <p />
          <Icon className="qr-bar-code-icon plus-icon">add</Icon>
        </div>
        <Icon className="qr-bar-code-icon qrcode-icon">
          {isSignature ? "recent_actors" : "qr_code"}
        </Icon>
      </div>
    </div>
  );
};

export default QRBarCodeBuilder;
