import React from "react";
import { ItemPreference } from "../../../../repositories/interfaces";
import TitleColumn from "../../atoms/TitleColumn/TitleColumn";
import "./CheckListValuePreference.scss";

interface CheckListValuePreferenceProps {
  onChangeFunction: (preference: ItemPreference) => void;
  preference: ItemPreference;
  title: string;
  optionsLength: number;
}

const CheckListValuePreference = (props: CheckListValuePreferenceProps) => {
  const { onChangeFunction, preference, title, optionsLength } = props;

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = parseInt(value);

    newValue = newValue <= 1 ? 1 : newValue;
    newValue = newValue > optionsLength ? optionsLength : newValue;

    onChangeFunction({ ...preference, value: newValue });
  };

  return (
    <section className="containerChecklistValuePreference">
      <TitleColumn text={title} />
      <input type="number" value={preference.value} onChange={handleChange} />
    </section>
  );
};

export default CheckListValuePreference;
