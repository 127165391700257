import { GraphUser } from "../models/Graph/GraphUser";
import api, { v2 } from "./API/Axios.api";

export const getEmailList = async (name: string) => {
  let response: any;

  response = await api
    .get<GraphUser[]>(v2("Graph/User/ByPartialEmail?partialEmail=" + name))
    .catch((error) => console.log(error));

  return response.data;
};

export const getUser = async (email: string) => {
  let response: any;

  response = await api
    .get<GraphUser>(v2("Graph/User/ByFullEmail?fullEmail=" + email))
    .catch((error) => console.log(error));

  return response?.data;
};
