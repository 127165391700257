// @ts-nocheck

import { getHeaderContainerStyle, useStyles } from "./styles";

import HeaderContent from "./HeaderContent";
import LogoSVG from "../../../../assets/img/logoSVG";
import React from "react";
import { RhimHeader } from "rhim-ui";
import { Typography } from "@material-ui/core";

const Header = () => {
  const { containerLogo, title } = useStyles()();

  const renderHeaderTitle = () => {
    return (
      <Typography color="primary" className={title}>
        {window.env.name}
      </Typography>
    );
  };

  const renderContent = () => {
    return (
      <HeaderContent />
    );
  };

  return (
    <RhimHeader
      isMobile={false}
      headerTitle={renderHeaderTitle()}
      logo={
        <div className={containerLogo}>
          <LogoSVG />
        </div>
      }
      content={<HeaderContent /> as any}
      customStyle={getHeaderContainerStyle()}
    />
  );
};

export default Header;
