import React, { useEffect } from "react";
import YesNoPreference from "../YesNoPreference";
import { CatalogItem } from "../../../../feature/catalog/interface/CatalogItem";
import { useStoreState } from "easy-peasy";
import AlertWarning from "../../atoms/Alerts/alertFullFormDescription";
import { t } from "i18next";
import "./FieldsInFormDescription.scss";

interface IProps {
  preference: any;
  changePreference: any;
  title: string;
  selectedSectionUuid: string;
}
const FieldsInFormDescription = ({
  changePreference,
  preference,
  selectedSectionUuid,
  title
}: IProps) => {
  const fieldsInDescription = useStoreState((state: any) => state.form.fieldsInDescription);
  const MAXIMUM_FIELDS_IN_DESCRIPTION = 5;

  const numberOfFieldsInDescription = fieldsInDescription?.length;
  const isFilledForFormDescription = fieldsInDescription?.find(
    (field: CatalogItem) => field.uuid === selectedSectionUuid
  );
  const disabled =
    numberOfFieldsInDescription >= MAXIMUM_FIELDS_IN_DESCRIPTION &&
    preference.key === "Fieldsinformdescription" &&
    !isFilledForFormDescription;
  if (disabled) {
    preference.value = false;
  }
  const showFullNumberOfFieldsDescription =
    numberOfFieldsInDescription >= MAXIMUM_FIELDS_IN_DESCRIPTION &&
    preference.key === "Fieldsinformdescription" &&
    !isFilledForFormDescription;
  const showSubTitle = preference.key === "Fieldsinformdescription";
  useEffect(() => {
    if (
      numberOfFieldsInDescription < MAXIMUM_FIELDS_IN_DESCRIPTION &&
      preference.key === "Fieldsinformdescription"
    ) {
      changePreference(preference);
    }
  }, [preference.value, preference]);

  return (
    <div>
      <YesNoPreference
        preference={preference}
        changePreference={changePreference}
        subtitle={t("messages.fieldsInformDescriptionPreference", {
          numberOfFieldsInDescription,
          MAXIMUM_FIELDS_IN_DESCRIPTION
        })}
        title={title}
        showSubTitle={showSubTitle}
        selectedSectionUuid={selectedSectionUuid}
        MAXIMUM_FIELDS_IN_DESCRIPTION={MAXIMUM_FIELDS_IN_DESCRIPTION}
        numberOfFieldsInDescription={numberOfFieldsInDescription}
        disabled={disabled}
      />
      <div>{showFullNumberOfFieldsDescription ? <AlertWarning /> : ""}</div>
    </div>
  );
};

export default FieldsInFormDescription;
