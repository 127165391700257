import { CatalogItem } from "../catalog/interface/CatalogItem";
import { FormRule } from "./interface/rulesInterface";
import { Section } from "../../models/Section/Section";

export const INITIAL_VALUE: FormRule = {
  name: "",
  id: undefined,
  formId: undefined,
  orderIndex: 0,
  agent: undefined,
  agentType: undefined,
  logicalTest: "",
  response: "",
  action: "",
  target: undefined,
  targetType: undefined
};

export const ruleStatusOptions = ["option.is", "option.isn't", ">", "<"];

export const ruleActionOptions = [
  "option.hide.section",
  "option.hide.field",
  "option.filter",
  "option.required"
];

const acceptedActors = ["dropdown", "boolean", "number"];

export const getActorOptions = (items: CatalogItem[], t: Function) =>
  items
    .filter((item: CatalogItem) => acceptedActors.includes(item.type))
    .map((field: CatalogItem) => {
      const preferenceTitle = field.preferences.filter((p) => p.key == "Title");
      if (preferenceTitle.length > 0) {
        return {
          label: t(preferenceTitle[0].value),
          value: field.fieldId
        };
      } else {
        return {
          label: "",
          value: field.fieldId
        };
      }
    });

export const getStatusOptions = (targetType: string) =>
  targetType === "number" ? ruleStatusOptions : ruleStatusOptions.slice(0, 2);

export const getRuleFieldOptions = (items: CatalogItem[], fieldId: string) => {
  const options = items
    .find((field: CatalogItem) => field.fieldId === fieldId)
    ?.preferences.find((preference: any) => preference.key.includes("List"))?.value;
  if (options) {
    const parsedOptions = JSON.parse(options);
    if (!parsedOptions.some((opt: any) => opt.option)) return [];
    return parsedOptions?.map((option: any, index: number) => {
      return {
        label: option.option,
        value: index
      };
    });
  }
  return [];
};

export const getSectionIdOfField = (sections: Section[], fieldId: string) => {
  let result = "";
  sections?.forEach((section) => {
    section.formFields?.forEach((field: CatalogItem) => {
      if (field.fieldId === fieldId) {
        result = section.sectionId;
      }
    });
  });
  return result;
};

export const getQuestionOptions = (
  fields: CatalogItem[],
  sections: Section[],
  rule: FormRule,
  t: Function
) => {
  if (rule.action.includes("section")) {
    if (sections.length <= 1) {
      return [];
    }
    const agentSectionId = getSectionIdOfField(sections, rule.agent || "");
    return sections
      .filter((section: Section) => section.sectionId !== agentSectionId)
      .map((section: Section) => {
        return {
          label: t(section.title),
          value: section.sectionId
        };
      });
  } else if (rule.action.includes("filter")) {
    if (fields.length <= 1) {
      return [];
    }
    return getMapFieldsReturn(
      fields.filter(
        (field: CatalogItem) => field.type === "dropdown" && field.fieldId !== rule.agent
      ),
      t
    );
  } else {
    if (fields.length <= 1) {
      return [];
    }
    return getMapFieldsReturn(
      fields.filter((field: CatalogItem) => field.fieldId !== rule.agent),
      t
    );
  }
};

export const getMapFieldsReturn = (fields: CatalogItem[], t: Function) => {
  return fields.map((field: CatalogItem) => {
    const preferenceTitle = field.preferences.filter((p) => p.key == "Title");
    if (preferenceTitle.length > 0) {
      return {
        label: t(preferenceTitle[0].value),
        value: field.fieldId
      };
    } else {
      return {
        label: "",
        value: field.fieldId
      };
    }
  });
};

export const getQuestionValue = (
  joinedFields: CatalogItem[],
  sections: Section[],
  rule: FormRule,
  t: Function
) => {
  return t(
    !rule.target
      ? ""
      : joinedFields
          .find((field) => field.fieldId === rule.target)
          ?.preferences.filter((p) => p.key == "Title")[0].value ||
          sections.find((section: Section) => section.sectionId === rule.target)?.title ||
          ""
  );
};

export const getActorValue = (joinedFields: CatalogItem[], rule: FormRule, t: Function) => {
  return t(
    joinedFields
      .find((field) => field.fieldId === rule.agent)
      ?.preferences.filter((p) => p.key == "Title")[0].value
  );
};
