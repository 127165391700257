import { action, thunk } from "easy-peasy";
import * as CatalogManager from "../CatalogManager";
import {
  getFilterFieldPreferences,
  addFieldLocalesToNewField,
  createPreferenceOptions
} from "../CatalogUtil";
import { CatalogModelInterface } from "./catalogModelInterface";
import { getAllFieldIds, getUniqueId } from "../../../helpers/util";
import { FormField } from "../../form/interface/FormField";
import { fieldListToFormField } from "../../form/FormUtil";

const catalogModel: CatalogModelInterface = {
  items: [],
  setItems: action((state, payload) => {
    state.items = payload.map((item) => item);
  }),
  getItems: thunk(async (action, payload, { getStoreActions }) => {
    const { loading } = getStoreActions();
    try {
      loading.setLoading(true);
      const catalog = await CatalogManager.getCatalog(payload);
      action.setItems(catalog);
    } catch (error) {
      action.setItems([]);
    } finally {
      loading.setLoading(false);
    }
  }),
  addField: thunk((actions, payload, { getStoreActions, getStoreState }) => {
    const { fields } = getStoreActions();
    const { fields: fieldsState, sections, form, languages } = getStoreState();
    const { selectedSection } = sections;
    const { item, t } = payload;
    const formLanguageList = form.formLanguageList;
    const allFormFields = fieldListToFormField(
      Object.values(fieldsState.items).flat() || []
    ) as FormField[];

    const allFieldIds = getAllFieldIds(allFormFields);
    const fieldTitle = item.name.includes("fields.") ? t(item.name) : item.name;
    const fieldId = getUniqueId(item.type, fieldTitle, allFieldIds);
    const itemToAdd = CatalogManager.parseCatalogItem(payload);
    itemToAdd.fieldId = fieldId;
    itemToAdd.fieldListUuid = selectedSection!.uuid;
    createPreferenceOptions(itemToAdd, formLanguageList);
    getFilterFieldPreferences(itemToAdd);

    addFieldLocalesToNewField(itemToAdd, formLanguageList, languages.formDefaultLanguage, t);
    fields.addField({ field: itemToAdd, section: selectedSection, t });
    fields.selectField(itemToAdd);
  })
};

export default catalogModel;
