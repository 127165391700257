import { action } from "easy-peasy";
import { AuthModelInterface } from "./authModelInterface";
import { getJwtPayload } from "../../../helpers/LoginHelper";

const authModel: AuthModelInterface = {
  userData: undefined,
  getUserData: action((state) => {
    state.userData = getJwtPayload();
  })
};

export default authModel;
