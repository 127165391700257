import React, { useEffect, useState } from "react";
import "./SingleOptionDropdownPreference.scss";
import { ItemPreference } from "../../../../repositories/interfaces";

import Select, { SingleValue, PropsValue } from "react-select";
import TitleColumn from "../../atoms/TitleColumn/TitleColumn";

interface SingleOptionDropdownPreferenceProps {
  key: number;
  title: string;
  preference: any;
  onChangeFunction: (preference: ItemPreference) => void;
  options: { value: string; label: string }[];
}
const SingleOptionDropdownPreference = ({
  preference,
  title,
  onChangeFunction,
  options
}: SingleOptionDropdownPreferenceProps) => {
  let option: { value: string; label: string }[] = [];
  const [selectedOption, setSelectedOption] = useState<PropsValue<any>>(option);

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderWidth: "1px !important",
      borderColor: "#d8dde1"
    })
  };
  useEffect(() => {
    let option: { value: string; label: string };

    if (preference.value === "true") option = options[0];
    else if (preference.value === "false") option = options[1];
    else {
      option = options[0];
    }
    setSelectedOption(option);
  }, [preference]);

  return (
    <div>
      <div id="typePreferenceTitle">
        <TitleColumn text={title} />
      </div>

      <Select
        name="isinteger"
        value={selectedOption}
        options={options}
        className={`basic-select`}
        styles={customStyles}
        classNamePrefix="select"
        placeholder=""
        onChange={(event: SingleValue<any>) => {
          setSelectedOption([event]);
          preference.value = event.value;
          onChangeFunction(preference);
        }}
      />
    </div>
  );
};

export default SingleOptionDropdownPreference;
