import React from "react";
import "./NewEmailInput.scss";
import { getEmailList } from "../../../../repositories/Graph.repository";
import { useStoreState, useStoreActions } from "../../../../store/hooks";
import { Form } from "../../../../feature/form/interface/Form";
import DataSearch from "../DataSearch/DataSearch";
import { GraphUser } from "../../../../models/Graph/GraphUser";

interface NewEmailInputProps {
  formEmailsType: string;
}

const NewEmailInput = (props: NewEmailInputProps) => {
  const form = useStoreState((state) => state.form.form!);
  const updateForm = useStoreActions((action) => action.form.updateForm);

  async function createNewAssociation(graphUser: GraphUser) {
    const formUp: Form = form;

    const emailToAssociate = graphUser.userPrincipalName;
    const isUserNotAssociated =
      formUp[props.formEmailsType].find(
        (user: { userEmail: any }) => user.userEmail === emailToAssociate
      ) === undefined;

    const addingOwnerAsCoowner =
      props.formEmailsType === "formCoOwners" && emailToAssociate === form.ownerEmail;
    if (isUserNotAssociated && !addingOwnerAsCoowner) {
      const newAssociation =
        props.formEmailsType === "formUsers"
          ? getFormUser(form, graphUser)
          : getFormCoOwner(form, graphUser);
      formUp[props.formEmailsType].push(newAssociation);
    }

    // @ts-ignore
    updateForm(formUp);
  }

  const validateEmail = async (email: string) => {
    const usersData: GraphUser[] = await getEmailList(email);
    return usersData;
  };

  return (
    <React.Fragment>
      <DataSearch
        filter={validateEmail}
        onItemClick={(value: GraphUser) => {
          createNewAssociation(value);
        }}
      />
    </React.Fragment>
  );
};

function getFormUser(form: any, userData: GraphUser) {
  return {
    formId: form.formId,
    userEmail: userData.userPrincipalName,
    userGuid: userData.id
  };
}

function getFormCoOwner(form: any, userData: any) {
  return {
    formId: form.formId,
    coOwnerEmail: userData.userPrincipalName
  };
}

export default NewEmailInput;
