import { action, thunk } from "easy-peasy";
import * as LanguageManager from "../LanguageManager";
import { LanguageModelInterface } from "./languageModelInterface";

const languagesModel: LanguageModelInterface = {
  items: [],
  activeItems: [],
  selectedLanguage: undefined,
  formDefaultLanguage: undefined,
  selectedAppLanguage: "",
  setFormDefaultLanguage: action((state, payload) => {
    state.formDefaultLanguage = payload;
  }),
  setActiveItems: action((state, payload) => {
    state.activeItems = payload;
  }),
  setItems: action((state, payload) => {
    state.items = payload.map((item) => item);
  }),
  getItems: thunk(async (action, payload, { getStoreActions }) => {
    const { loading } = getStoreActions();
    try {
      loading.setLoading(true);
      const languages = await LanguageManager.getLanguages(payload);
      action.setItems(languages);
    } catch (error) {
      action.setItems([]);
    } finally {
      loading.setLoading(false);
    }
  }),
  selectLanguage: action((state, payload) => {
    if (payload) state.selectedLanguage = payload;
  }),
  updateLanguage: thunk((actions, payload, { getStoreActions }) => {
    const { form, sections, fields } = getStoreActions();
    form.updateTranslation(payload);
    sections.updateSectionTranslation(payload);
    fields.updateFieldTitleTranslation(payload);
    fields.updateOptionTranslation(payload);
  }),
  setSelectedAppLanguage: action((state, payload) => {
    state.selectedAppLanguage = payload?.isoCode ?? "";
  })
};

export default languagesModel;
