import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { RhimButton } from "rhim-ui";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

const SubHeader = (props: any) => {
  const { title, showButton, handleSubHeaderAction, buttonLabel, renderCustomContent, id } = props;

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className="containerTitle"
    >
      <Typography id={`sub-header-${id}`} variant="h5" component="h5" className="text">
        <Box fontFamily="Neutrif Pro Bold">{title}</Box>
      </Typography>
      <Grid container item md={12} xs={12} justifyContent={"flex-end"}>
        {renderCustomContent && <Grid item>{renderCustomContent()}</Grid>}
        {showButton && (
          <Grid item>
            <RhimButton
              onClick={handleSubHeaderAction}
              title={buttonLabel}
              id="sub-header-btn-action"
              icon={<Icon>add</Icon>}
              iconSide="left"
              variant="contained"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SubHeader;
