import React, { useState, useEffect } from "react";
import { ItemPreference } from "../../../../repositories/interfaces";
import "./DropdownComponent.scss";
import Dropdown, { Option } from "react-dropdown";
import { getMRK } from "../../../../repositories/MRK.repository";
import { useTranslation } from "react-i18next";
import { useStoreState } from "../../../../store/hooks";
import { Icon } from "@material-ui/core";

interface DropdownComponentProps {
  onChangeFunction: (preference: ItemPreference) => void;
  preference: ItemPreference;
}

async function getMRKListFromConnector(language: string) {
  const mrkData = await getMRK(language);
  if (mrkData.success === true) {
    return mrkData.data;
  } else {
    return [];
  }
}

const DropdownComponent = (props: DropdownComponentProps) => {
  const { t } = useTranslation();
  const { onChangeFunction, preference } = props;
  const initialValue = { mrkId: -1, mrkDescription: "No MRK found" };
  const [list, setList] = useState([initialValue]);
  const selectedLanguage = useStoreState((state) => state.languages.selectedAppLanguage);

  useEffect(() => {
    async function getMrkAsync() {
      const listValues = await getMRKListFromConnector(selectedLanguage);
      setList(listValues);
    }
    getMrkAsync();
  }, [selectedLanguage]);

  let listString: Option[] = [];
  list.forEach((element: any) => {
    listString.push({
      label: element.mrkId + " " + element.mrkDescription,
      value: element.mrkId
    });
  });

  function getMRKKeyFromMRKValue(mrkId: number) {
    return list.find((element: any) => element.mrkId === mrkId)?.mrkDescription;
  }

  function returnPlaceholder(mrkId: number) {
    if (getMRKKeyFromMRKValue(mrkId) !== undefined) {
      return mrkId + " " + getMRKKeyFromMRKValue(mrkId);
    } else {
      return t("preferences.mrk_placeholder");
    }
  }

  return (
    <Dropdown
      className="mrk-dropdown"
      options={listString}
      placeholder={returnPlaceholder(preference.value)}
      value={list.length === 1 ? `${preference.value}` : returnPlaceholder(preference.value)}
      arrowOpen={<Icon className="open-close-arrow">expand_less</Icon>}
      arrowClosed={<Icon className="open-close-arrow">expand_more</Icon>}
      onChange={(arg: Option) => {
        preference.value = arg.value;
        onChangeFunction(preference);
      }}
    />
  );
};

export default DropdownComponent;
