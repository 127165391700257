export default {
  "common.cancel": "取消",
  "common.delete": "删除",
  "common.duplicate": "重复值",
  "common.title": "标题",
  "common.upload": "上传",
  "common.create": "生成",
  "common.file": "文件夹",
  "common.section": "章节",
  "common.add": "增加",
  "common.continue": "继续",
  "common.confirm": "确认",
  "common.save": "保存",
  "common.saved": "已保存",
  "common.yes": "是的",
  "common.no": "不",
  "common.skip": "跳过",
  "common.ok": "好的",
  "common.success": "成果",
  "common.sign_out": "登出",
  "common.answer": "回复",
  "common.rule": "标准",
  "message.createAnswer.success": "保存成功！",
  "message.createAnswer.error.answer": "生成回复出错",
  "message.createAnswer.error.image": "生成图片出错",
  "message.getAnswers.error.invalidInput": "请输入｛｛maxDays｝｝天的最长期限以筛选响应。",
  "message.getAnswers.error.answer": "分析回复数据失败",
  "message.getAnswers.error.image": "分析图片失败",
  "message.formValidation.missingTitle": "请输入标题",
  "message.formValidation.missingEmail": "请输入邮箱",
  "message.formValidation.missingSectionTitle": "请输入所有章节标题",
  "message.formValidation.missingFieldTitle": "请输入所有标题",
  "message.formValidation.missingFieldType": "请选择类型",
  "message.formValidation.missingFieldId": "请填入ID",
  "message.formValidation.missingEquipmentMrk": "请在设备区域填写MRK",
  "message.formValidation.missingSection": "表单至少需要填写部分内容",
  "message.formValidation.multipleCustomerField": "表单仅支持一个SAR客户",
  "message.formValidation.multipleEquipmentInSection": "表单的每个章节仅支持一个SAR设备",
  "message.formValidation.missingCustomerField": "为了生成一份包含SAR设备的表单，请先添加SAR客户信息",
  "message.formValidation.missingEquipmentField": "为了生成一份包含SAR炉役/结束原因的表单，请先填入SAR设备信息",
  "message.formValidation.wrongCustomerFieldPositionAfterEquipment": "SAR客户信息应在SAR设备前填入",
  "message.formValidation.wrongCustomerFieldPositionAfterCampaign": "SAR客户信息应在SAR炉役前填入",
  "message.formValidation.wrongCustomerFieldPositionAfterEndingReason": "SAR客户信息应在SAR结束原因前填入",
  "message.formValidation.wrongEquipmentFieldPositionAfterCampaign": "SAR设备信息应在SAR炉役信息前填入",
  "message.formValidation.wrongEquipmentFieldPositionAfterEndingReason": "填写炉役结束信息前需要先输入设备相关信息",
  "message.formValidation.wrongEquipmentFieldPosition": "填写炉役结束信息前需要先输入设备相关信息",
  "message.formValidation.failureValidatingRules": "验证表单的规则失败",
  "message.formValidation.missingTargetOptionsInRules": "表单规则中缺少问题选项。",
  "message.createForm.success": "表单保存成功",
  "message.createForm.error": "存储表单失败，请再次尝试",
  "message.updateForm.success": "表单保存成功",
  "message.updateForm.error": "存储表单时失败",
  "message.toggleFormEnable.success.enable": "表单成功启用",
  "message.toggleFormEnable.success.disable": "表单成功禁用",
  "message.toggleFormEnable.error": "启用/禁用表单时出错",
  "message.hasAnswers": "表单中已有回复",
  "message.hasNoAnswers": "表单中没有回复",
  "message.hasAnswers.error": "检查表单中回复时出错",
  "message.deleteForm.success": "表单成功删除",
  "message.deleteForm.error": "表单删除失败",
  "message.duplicate.modal.title": "您希望以什么名字保存您的副本 ？",
  "message.duplicateForm.success": "表单复制成功",
  "message.duplicateForm.error": "表单复制失败",
  "message.getMrk.error": "获取MRK时出错",
  "message.getCulture.error": "不支持的语言类型",
  "messages.oops": "出错",
  "messages.link_copied": "链接已复制",
  "messages.confirm_delete": "是否确实要删除｛｛表单标题｝｝？此操作无法撤消。",
  "messages.confirm_delete_answers": "这张表格已经有回复了。继续操作，将删除所有回复，并且此操作无法撤消。是否要继续？",
  "messages.confirm_title_duplicate": "它将以以下名称发送到列表的顶部:",
  "messages.required_fields": "请填入所有要求的区域",
  "messages.error_support": "执行此操作时出现问题。如果错误仍然存在，请联系我们的支持人员。",
  "messages.error_field_id": "请检查字段ID",
  "messages.error_field_no_options": "请检查字段选项！",
  "messages.error_field_preference_no_options": "请检查字段的首选项",
  "messages.error_qrbarcode_field_preference_no_options": "如果不选择至少一种类型，则无法保存QR/条形码字段。",
  "messages.error_field_dropdown.title": "如果无所选项，则无法保存下拉列表。",
  "messages.error_field_weight": "请检查权重字段！",
  "messages.error_all_field_weight": "请填写所有权重字段！",
  "messages.error_fields_repeated_id": "节｛｛sectionIdA｝｝的字段｛｛fieldIdA｝｝ID与节｛{sectionIdB｝}的字段{｛field",
  "messages.error_section_repeated_id": "存在具有相同Id的节！",
  "messages.error_section_name": "请检查章节名称",
  "messages.error_section_id": "请检查章节ID",
  "messages.error_section_repeated_name": "存在具有相同名称的章节",
  "messages.error_mrk": "无法找的MRK",
  "messages.error_multiphotos_preferences": "请检查照片选项",
  "messages.error_invalid_preference": "无效的首选项值",
  "messages.construction": "此部分尚在准备中，未来将进一步提高您的体验",
  "messages.languages_note": "如果保留一个空字段，它将显示为用户的默认语言。",
  "messages.users_not_found": "未找到用户",
  "messages.confirm_rules": "是否愿意为该表单创建规则",
  "messages.empty_rule": "如果有一个条件规则，可以在这里创建",
  "messages.empty_section": "选择一个字段以将其添加到节中",
  "messages.saved": "您的表单已成功保存！您现在可以在“我的表单”上进行检查。",
  "messages.sign_out": "是否要断开与系统的连接？",
  "messages.cannot_change_form_status": "您不能启用或禁用此表单，因为您只是共同所有者。",
  "pages.my_forms": "我的表单",
  "pages.new_form": "新建表单",
  "pages.feedback": "反馈",
  "pages.builder": "创建者",
  "pages.rules": "规则",
  "pages.languages": "语言",
  "pages.users": "用户",
  "pages.usersAndCoOwners": "用户和共同所有者",
  "my_forms.id": "ID",
  "my_forms.form_code": "表单编码",
  "my_forms.creation_date": "生成日期",
  "my_forms.updated_date": "上次更改",
  "my_forms.enable": "启用",
  "my_forms.owner": "所有者",
  "my_forms.actions": "操作",
  "actions.form": "编辑表格",
  "actions.rules": "编辑规则",
  "actions.users": "编辑用户",
  "actions.languages": "编辑语言",
  "actions.power_bi": "POWER BI链接",
  "actions.export": "导出表单",
  "actions.delete": "删除表单",
  "actions.duplicate": "复制表单",
  "new_form.import": "导入新表单",
  "new_form.creation_language": "创建语言",
  "languages.portuguese": "葡萄牙语",
  "languages.english": "英语",
  "languages.spanish": "西班牙语",
  "languages.german": "德语",
  "languages.mandarin": "中文",
  "languages.french": "法语",
  "languages.turkish": "土耳其语",
  "languages.czech": "捷克语",
  "languages.polish": "波兰语",
  "languages.slovenian": "斯洛文尼亚语",
  "languages.swedish": "瑞典语",
  "languages.kosovo_albanian": "科索沃阿尔巴尼亚语",
  "fields.text": "短字段",
  "fields.textarea": "长字段",
  "fields.number": "数字",
  "fields.datetime": "日期和时间",
  "fields.photo": "照片",
  "fields.dropdown": "下拉",
  "fields.single_option": "单选",
  "fields.customer": "客户",
  "fields.equipment": "设备",
  "fields.campaign": "炉役",
  "fields.end_reason": "关闭原因",
  "fields.qr_code": "二维码/条形码",
  "fields.signature_qr": "二维码签名",
  "fields.boolean": "单选",
  "fields.dropdown-campaign": "炉役",
  "fields.dropdown-campaign-end-reason": "关闭原因",
  "fields.dropdown-customer": "客户",
  "fields.dropdown-equipment": "设备",
  "fields.pictureFile": "照片",
  "fields.qrbarcode": "二维码/条形码",
  "fields.signature-qrcode": "二维码签名",
  "fields.check_list": "核检表",
  "constructor.fields": "区域",
  "constructor.filter": "过滤器",
  "constructor.by.equipment": "设备过滤器",
  "constructor.by.customer": "客户过滤器",
  "constructor.basic": "基础",
  "preferences.checklistValue": "最大的可选选项",
  "constructor.rhim": "RHIM 集成",
  "constructor.section_title": "章节标题",
  "constructor.unsaves_changes": "未保存更改",
  "constructor.mrk": "设备MRK",
  "constructor.preferences": "偏好",
  "preferences.placeholder": "选择以编辑偏好",
  "preferences.required": "要求",
  "preferences.score": "分数",
  "preferences.options": "选项",
  "preferences.automatic": "自动",
  "preferences.automatic_id": "自动ID",
  "preferences.field_id": "区域ID",
  "preferences.field_weight": "区域权重",
  "preferences.section_weight": "章节权重",
  "preferences.section_id": "章节ID",
  "preferences.options_placeholder": "通过使用enter分隔项目来键入项目",
  "preferences.mrk_placeholder": "选择MRK",
  "preferences.max": "最大值",
  "preferences.min": "最小值",
  "preferences.formDescription": "用作表单描述",
  "preferences.error_empty_qrcode": "选择至少一个选项",
  "preferences.typeQrOrBarCode": "点击",
  "preferences.IntegerOrFloat": "点击",
  "add_options.option": "选项",
  "add_options.weight": "权重",
  "add_options.response": "反馈",
  "add_options.add_na": "不适用的添加",
  "construction.go_back": "反馈",
  "languages.notes": "标注",
  "users.email": "用户邮箱",
  "coOwners.email": "共同所有者邮箱",
  "users.multiple_association": "多重关联（用带有分号分隔符的邮件列表填充）",
  "users.finish": "完成表单",
  "globalization.title": "输入表单标题",
  "globalization.section": "输入章节标题",
  "globalization.field": "输入区域名称",
  "globalization.field.description": "输入区域描述",
  "globalization.option": "输入你的选项值",
  "rules.if": "如果",
  "rules.status": "状态",
  "rules.response": "反馈",
  "rules.action": "行动",
  "rules.question": "问题",
  "rules.options": "选项",
  "option.is": "是",
  "option.isn't": "不是",
  "option.hide.section": "隐藏章节",
  "option.hide.field": "隐藏区域",
  "option.show.section": "展示章节",
  "option.show.field": "展示区域",
  "option.filter": "筛选",
  "option.required": "要求的",
  "pagination.view": "查看｛｛from｝｝-｛to｝",
  "pagination.rows_per_page": "每页行数",
  "messages.error_form_access_denied": "您无权访问此表单。请查找您是所有者或共同所有者的表单",
  "messages.page_not_found": "无法找到该页",
  "messages.text_page_not_found": "很抱歉，您试图访问的页面不存在。",
  "messages.go_back_my_forms": "回到我的表单",
  "messages.access_denied": "访问被拒绝",
  "messages.text_access_denied": "您无权访问此表单。请搜索您是作者或共有者的其他表单。",
  "messages.form_not_found": "表单不存在",
  "messages.text_form_not_found": "很抱歉，您试图访问的表单不存在。请搜索另一个表单或创建一个新表单。",
  "preferences.description": "描述",
  "messages.fieldsInformDescriptionPreference": "您已经选择了｛｛numberOfFieldsInDescription｝｝/｛{MAXIMUM_FIELDS_IN_",
  "messages.error_single_option_no_options": "没有选项就无法保存单个选项字段。",
  "messages.max_filled_as_form_description": "要选择此字段，请取消选择其他字段",
  "preferences.option_integer": "整数",
  "preferences.option_decimal": "小数",
  "messages.tooltip_decimal_minimum_alert": "由于有两位小数，小数类型字段的最小字符限制为3。",
  "message.form_last_update": "上次更新",
  "message.form_last_update_by": "通过"
 }
