import { Icon } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import "./FormSavingStatus.scss";

export interface SavedInfo {
  isSaved: boolean;
}

const FormSaveStatus = ({ isSaved }: SavedInfo) => {
  const { t } = useTranslation();
  return (
    <span>
      {isSaved ? (
        <span className="saved-container">
          <Icon>check</Icon>
          <span className="saved">{t("common.saved")}</span>
        </span>
      ) : (
        <span className="unsaved-container">
          <span className="unsaved">{t("constructor.unsaves_changes")}</span>
        </span>
      )}
    </span>
  );
};

export default FormSaveStatus;
