import { v2 } from "../../../repositories/API/Axios.api";
import { Form } from "../interface/Form";

export const generateLinkToDB = (form: Form): string => {
  let formCode = form.formCode;
  let today = new Date(Date.now());
  let DaysBefore = new Date();

  DaysBefore.setDate(today.getDate() - 58);
  let finalDate = today.getMonth() + 1 + "-" + today.getDate() + "-" + today.getFullYear();

  let initialDate =
    DaysBefore.getMonth() + 1 + "-" + DaysBefore.getDate() + "-" + DaysBefore.getFullYear();

  return `${window.env.apiURL}${v2(
    `Answer/${formCode}?createdAt=${initialDate} 00:00:00&finalDate=${finalDate} 23:59:59`
  )}`;
};
