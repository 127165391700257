import store from "../../../../store/store";
import { MenuItem } from "../../molecules/SecondaryMenu/SecondaryMenu";

export const getMenuItems = (t: any) => {
  const state = store.getState();
  const ownerUser = state.form.form?.ownerEmail;
  const userEmail = state.auth.userData?.unique_name;
  const title = ownerUser === userEmail ? "pages.usersAndCoOwners" : "pages.users";
  const formId = state.form.form?.formId;
  return [
    {
      id: "constructor-list-item",
      path: `/Builder/Constructor/${formId}`,
      icon: "description",
      title: t("pages.builder"),
      isSelected: false
    },
    {
      id: "rules-list-item",
      path: `/Builder/Rules/${formId}`,
      icon: "settings",
      title: t("pages.rules"),
      isSelected: false
    },
    {
      id: "globalization-list-item",
      path: `/Builder/Globalization/${formId}`,
      icon: "public",
      title: t("pages.languages"),
      isSelected: false
    },
    {
      id: "association-list-item",
      path: `/Builder/UsersAssociation/${formId}`,
      icon: "person_add",
      title: t(title),
      isSelected: false
    }
  ];
};

export const getNextMenu = (menuItems: MenuItem[]) => {
  const selectedIndex = menuItems.findIndex((item) => item.isSelected);
  const nextIndex = (selectedIndex + 1) % menuItems.length;
  const next = menuItems[nextIndex];
  return { nextIndex, next };
};

export const conditional = (
  menu: MenuItem,
  condition: (menu: MenuItem) => boolean,
  ifTrue: (menu: MenuItem) => void,
  ifFalse: (menu: MenuItem) => void
) => {
  if (condition(menu)) {
    ifTrue(menu);
  } else {
    ifFalse(menu);
  }
};
