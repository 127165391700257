import { CatalogItem } from "../../../feature/catalog/interface/CatalogItem";

export const moveFields = (
  translatedItems: any[],
  translatedIndex: number,
  fieldsList: CatalogItem[],
  index: number,
  newIndex: number
) => {
  translatedItems?.forEach((item: any, index: number) => {
    if (item[index]?.fieldListUuid === fieldsList[index]?.fieldListUuid) {
      translatedIndex = index;
    }
  });
  const fieldsTranslatedList = translatedItems[translatedIndex]?.slice();
  const fieldTranslated = fieldsTranslatedList[index];
  fieldsTranslatedList[index] = fieldsTranslatedList[newIndex];
  fieldsTranslatedList[newIndex] = fieldTranslated;
  const field = fieldsList[index];
  fieldsList[index] = fieldsList[newIndex];
  fieldsList[newIndex] = field;
  return fieldsTranslatedList;
};
