import React from "react";
import "./GlobalizationPage.scss";
import { TitleSection } from "../../atoms";
import LanguagesContainer from "../../../../feature/languages/views/LanguageContainer";
import { GlobalizationSectionContainer } from "../../../../feature/globalizationSection";
import { ErrorBoundary } from "../../../../feature/errorBoundary";
import { useTranslation } from "react-i18next";
import { Icon } from "@material-ui/core";

const GlobalizationPage = () => {
  const { t } = useTranslation();
  return (
    <main className="globalization-screen">
      <section className="languages-column">
        <article className="title">
          <TitleSection text={t("pages.languages")} />
        </article>
        <ErrorBoundary>
          <LanguagesContainer />
        </ErrorBoundary>
      </section>
      <section className="main">
        <ErrorBoundary>
          <GlobalizationSectionContainer />
        </ErrorBoundary>
      </section>
      <section className="notes">
        <article className="title">
          <TitleSection text={t("languages.notes")} />
        </article>
        <article className="note-container">
          <Icon className="note-icon">info</Icon>
          <span className="note-message">{t("messages.languages_note")}</span>
        </article>
      </section>
    </main>
  );
};

export default GlobalizationPage;
