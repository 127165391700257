import React from "react";
import { useTranslation } from "react-i18next";
import "./UpdateStatus.scss";

export interface UpdateInfo {
  date: string;
  email: string;
}

const UpdateStatus = ({ date, email }: UpdateInfo) => {
  const { t } = useTranslation();

  const updatedDate = new Date(date);

  function formatUpadatedDateTime() {
    const dateFormatedTo8digits = updatedDate.toLocaleDateString(undefined, {
      month: "2-digit",
      day: "2-digit",
      year: "numeric"
    });
    const hourFormatedToHHMM = updatedDate.toLocaleString(undefined, {
      hourCycle: "h24",
      timeStyle: "short"
    });
    return `${dateFormatedTo8digits} ${hourFormatedToHHMM}`;
  }

  function formatUpadatedAuthorEmail() {
    if (!email) return "";
    const authorString = `${t("message.form_last_update_by")} ${email}`;
    return authorString.toLocaleLowerCase();
  }
  function formatedUpdatedInfo() {
    return `${t(
      "message.form_last_update"
    )}: ${formatUpadatedDateTime()}  ${formatUpadatedAuthorEmail()} `;
  }
  return <span className="updated-info">{formatedUpdatedInfo()}</span>;
};
export default UpdateStatus;
