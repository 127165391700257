import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useWidthResize } from "rhim-ui";
import MenuNavigation from "../../../../feature/navigation/views/menuNavigation";

import { useStyles } from "./styles";
import { StoreProvider } from "easy-peasy";
import { useStoreActions, useStoreState } from "../../../../../src/store/hooks";
import { useTranslation } from "react-i18next";
import Header from "../../organisms/Header/Header";

const MainPage = (props: any) => {
  const { i18n } = useTranslation();
  const getUserData = useStoreActions((actions) => actions.auth.getUserData);
  const getItems = useStoreActions((actions) => actions.catalogs.getItems);
  const getLanguages = useStoreActions((actions) => actions.languages.getItems);
  const selectedLanguage = useStoreState((state) => state.languages.selectedAppLanguage);
  useEffect(() => {
    getUserData();
    getItems("ComponentCatalog");
    getLanguages("Cultures");
  }, [getUserData, getItems, getLanguages]);

  useEffect(() => {
    if (selectedLanguage !== undefined && selectedLanguage !== i18n.language) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [i18n, selectedLanguage]);

  const [menuOpened, toggleMenu] = useState(false);
  const { isMobile, width } = useWidthResize();
  const { menuOptions } = props;

  const { containerMainPage, container, contentPage, page } = useStyles(
    width,
    isMobile,
    menuOpened
  )();

  return (
    <div className={containerMainPage}>
      <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
        <MenuNavigation
          menuOptions={menuOptions}
          menuOpened={menuOpened}
          toggleMenu={(open = false) => {
            toggleMenu(open);
          }}
        />
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          className={container}
          wrap="nowrap">
          <Header />
          <Grid container direction="column" className={contentPage} wrap="nowrap">
            <Grid className={page}>{props.children}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

type Props = StoreProvider["props"] & { children: React.ReactNode };

export default MainPage;
