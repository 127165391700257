import React, { useState } from "react";
import { RhimSideMenu, useWidthResize } from "rhim-ui";
import { MenuOpen } from "@material-ui/icons";
import MenuItem from "./menuItem";
import SubMenuItems from "./subMenuItems";
import { useNavigate } from "react-router-dom";
import { MenuItem as MenuItemType } from "../navigationUtils";
import { gathererTheme } from "../../../helpers/theme";
import { getCustomStyleSideMenu } from "../styles";

const MenuNavigation = (props: any) => {
  const navigate = useNavigate();
  const { isMobile } = useWidthResize();
  const [selectedMenuItem, setSelectedMenuItem] = useState("1");
  const [menuItemExpand, setMenuItemExpand] = useState("");
  const { menuOpened, toggleMenu, menuOptions } = props;

  const onSelectMenuItem = (selectedOption: MenuItemType) => {
    if (!selectedOption.isSingleMenu) {
      const menuItemId = selectedOption.id !== menuItemExpand ? selectedOption.id : "";
      setMenuItemExpand(menuItemId);
      toggleMenu(true);
    } else if (selectedOption.label.includes("feedback")) {
      let win = window.open(selectedOption.uri, "_blank");
      win?.focus();
    } else {
      navigate(selectedOption.uri);
      toggleMenu(false);
    }
    setSelectedMenuItem(selectedOption.id);
  };

  const renderIconSideMenu = () => {
    const iconCustomStyle = { style: { color: "#fff" } };
    return <MenuOpen {...iconCustomStyle} onClick={() => toggleMenu(!menuOpened)} />;
  };

  const renderMenuItem = (menuItem: any) => {
    const showItems = menuItem?.items && !!menuItem.items.length;
    const openSubmenu = menuItemExpand === String(menuItem.id) && menuOpened;

    return (
      <MenuItem
        key={menuItem.id}
        menuOpened={menuOpened}
        menuItem={menuItem}
        selectMenuItem={onSelectMenuItem}
        selectedMenuItem={selectedMenuItem}
        showItems={showItems}
        openSubmenu={openSubmenu}
        subMenu={
          <SubMenuItems
            menuOpened={menuOpened}
            items={menuItem?.items}
            selectedMenuItem={selectedMenuItem}
            showItems={showItems}
            openSubmenu={openSubmenu}
            selectMenuItem={onSelectMenuItem}
          />
        }
      />
    );
  };

  return (
    <RhimSideMenu
      theme={gathererTheme}
      iconToggleMenu={renderIconSideMenu()}
      menuItems={menuOptions}
      isMobile={isMobile}
      menuOpened={menuOpened}
      renderItem={(menuOption: any) => renderMenuItem(menuOption)}
      customStyle={getCustomStyleSideMenu()}
    />
  );
};

export default MenuNavigation;
