import { rhimTheme } from "rhim-ui";
import { gathererTheme } from "../../helpers/theme";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export const getCustomStyleSideMenu = () => {
  const customStyleSideMenu = {
    sidebar: {
      backgroundColor: rhimTheme.palette.primary.main,
      boxShadow: "inset -8px 0px 8px 0px rgba(0,0,0,0.3)"
    },
    menuList: {
      maxHeight: window.innerHeight - gathererTheme.layout.headerHeight,
      overflowX: "hidden",
      overflowY: "auto"
    },
    menuHeader: { padding: "25px 0 0 17px", maxHeight: 30 }
  };
  return customStyleSideMenu;
};

export const getMenuItemStyles = (active = false, mouseIsOver = false) => {
  const activeColor = rhimTheme.palette.secondary.main;
  const borderPX = 3;
  return {
    containerFloatSubmenu: {
      borderRadius: `0 ${borderPX}px ${borderPX}px ${borderPX}px`,
      backgroundColor: rhimTheme.palette.primary.main,
      width: 200,
      boxShadow: "0 0 8px 0 rgba(0,0,0,0.2), 0 2px 4px 0 rgba(0,0,0,0.2)"
    },
    item: {
      height: 60,
      backgroundColor: mouseIsOver ? "rgba(0, 0, 0, 0.25)" : ""
    },
    activeItem: {
      borderLeft: `3px solid ${active ? activeColor : "transparent"}`,
      borderRadius: 1
    },
    activeText: {
      color: "#fff",
      fontFamily: "Neutrif Pro",
      marginBottom: "-25px"
    },
    activeIcon: {
      color: active ? activeColor : "#fff"
    }
  };
};

export const getSubMenuItemStyles = makeStyles(() =>
  createStyles({
    root: {
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.25)"
      }
    },
    focused: {}
  })
);
