const storageKeys = {
  apiToken: "ui.store.apiToken",
  languageIsoCode: "ui.store.languageIsoCode",
  i18n: "ui.store.translations",
  permissions: "ui.store.permissions",
  loggedUser: "ui.store.loggedUser"
};

export const getItem = (key: string) => localStorage.getItem(key);
export const setItem = (key: string, value: string) => localStorage.setItem(key, value);
export const removeItem = (key: string) => localStorage.removeItem(key);

export default {
  getApiToken: () => getItem(storageKeys.apiToken),
  getLanguageIsoCode: () => getItem(storageKeys.languageIsoCode),
  getI18n: () => getItem(storageKeys.i18n),
  getPermissions: (): string[] => JSON.parse(getItem(storageKeys.permissions) || "[]"),
  getLoggedUser: () => getItem(storageKeys.loggedUser),
  setApiToken: (value: string) => setItem(storageKeys.apiToken, value),
  setLanguageIsoCode: (value: string) => setItem(storageKeys.languageIsoCode, value),
  setI18n: (value: string) => setItem(storageKeys.i18n, value),
  setPermissions: (value: string[]) => setItem(storageKeys.permissions, JSON.stringify(value)),
  setLoggedUser: (value: string) => setItem(storageKeys.loggedUser, value),
  removeApiToken: () => removeItem(storageKeys.apiToken),
  removeLoggedUser: () => removeItem(storageKeys.loggedUser)
};
