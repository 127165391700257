import React from "react";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import CatalogList from "./CatalogList";
import { CatalogItem } from "../interface/CatalogItem";
import CatalogListItem from "./CatalogListItem";
import { getSarIntegrationItems, getBasicItems } from "../CatalogUtil";
import { useTranslation } from "react-i18next";

const CatalogContainer = () => {
  const { t } = useTranslation();
  const items = useStoreState((state) => state.catalogs.items).sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    } else if (b.name > a.name) {
      return -1;
    } else return 0;
  });
  const addFieldToSection = useStoreActions((actions) => actions.catalogs.addField);
  const render = (items: CatalogItem[]) => {
    return items.map((item, index) => (
      <CatalogListItem key={index} item={item} onClick={addFieldToSection} />
    ));
  };

  return (
    <>
      <CatalogList
        title={t("constructor.basic")}
        items={getBasicItems(items)}
        initialOpenState={true}
        render={render}
        addField={addFieldToSection}
      />
      <CatalogList
        title={t("constructor.rhim")}
        items={getSarIntegrationItems(items)}
        initialOpenState={false}
        render={render}
        addField={addFieldToSection}
      />
    </>
  );
};

export default CatalogContainer;
