import React from "react";
import "./UniquePhotoBuilder.scss";
import { Icon } from "@material-ui/core";

interface UniquePhotoBuilderProps {
  title: string;
  mandatory: boolean;
}

const UniquePhotoBuilder = (props: UniquePhotoBuilderProps) => {
  const { title } = props;
  const { mandatory } = props;
  return (
    <div className="unique-photo-builder field-item">
      <h2 className="title">
        {title}
        {mandatory && <span className="mandatory-signal"> *</span>}
      </h2>
      <div className="content">
        <Icon className="font-awesome-icon-button">panorama</Icon>
      </div>
    </div>
  );
};

export default UniquePhotoBuilder;
