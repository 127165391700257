export default {
  "common.cancel": "Cancelar",
  "common.delete": "Eliminar",
  "common.duplicate": "Duplicar",
  "common.title": "Título",
  "common.upload": "Cargar",
  "common.create": "Crear",
  "common.file": "Archivo",
  "common.section": "Sección",
  "common.add": "Añadir",
  "common.continue": "Continuar",
  "common.confirm": "Confirmar",
  "common.save": "Guardar",
  "common.saved": "Salvado",
  "common.yes": "Sí",
  "common.no": "No",
  "common.skip": "Saltar",
  "common.ok": "Ok",
  "common.success": "Éxito",
  "common.sign_out": "Desconectar",
  "common.answer": "Respuesta",
  "common.rule": "Regla",

  "message.createAnswer.success": "¡Respuesta guardada con éxito!",
  "message.createAnswer.error.answer": "Error al crear la respuesta",
  "message.createAnswer.error.image": "Error al crear imágenes",
  "message.createAnswer.error.image.type": "Tipo de imagen no válido",
  "message.getAnswers.error.invalidInput":
    "Por favor, ingrese un período máximo de {{maxDays}} días para filtrar las respuestas.",
  "message.getAnswers.error.answer": "Error de análisis de AnswerData",
  "message.getAnswers.error.image": "Error de análisis de imágenes",
  "message.formValidation.missingTitle": "Por favor complete el campo Título.",
  "message.formValidation.missingEmail": "Por favor complete el campo de correo electrónico",
  "message.formValidation.missingSectionTitle":
    "Por favor, complete el título de todas las secciones.",
  "message.formValidation.missingFieldTitle": "Por favor complete todos los campos de títulos.",
  "message.formValidation.missingFieldType": "Por favor, seleccione el tipo en todos los campos.",
  "message.formValidation.missingFieldId":
    "Por favor, ingrese una identificación para todos los campos.",
  "message.formValidation.missingEquipmentMrk":
    "Por favor, seleccione un MRK en el campo de equipo.",
  "message.formValidation.missingSection":
    "Un formulario necesita al menos una sección para funcionar correctamente.",
  "message.formValidation.multipleCustomerField":
    "El formulario solo puede tener un campo de Cliente SAR.",
  "message.formValidation.missingCustomerField":
    "Para crear un formulario con Equipo SAR, coloque un campo de tipo Cliente SAR antes.",
  "message.formValidation.missingEquipmentField":
    "Para crear un formulario con Campaña/Motivo de fin de campaña SAR, coloque un campo de tipo Equipo SAR antes.",
  "message.formValidation.wrongCustomerFieldPositionAfterEquipment":
    "Se debe ingresar el cliente SAR antes que el equipo SAR",
  "message.formValidation.wrongCustomerFieldPositionAfterCampaign":
    "Se debe ingresar el cliente SAR antes de una campaña SAR",
  "message.formValidation.wrongCustomerFieldPositionAfterEndingReason":
    "El cliente de SAR debe ingresarse antes de un motivo de finalización de SAR",
  "message.formValidation.wrongEquipmentFieldPositionAfterCampaign":
    "Se debe ingresar el equipo SAR antes de la campaña SAR",
  "message.formValidation.wrongEquipmentFieldPositionAfterEndingReason":
    "El equipo de SAR debe ingresarse antes de la razón de finalización de SAR",
  "message.formValidation.wrongEquipmentFieldPosition":
    "El equipo SAR debe estar antes de la campaña SAR.",
  "message.formValidation.failureValidatingRules": "Failure validating form rules.",
  "message.formValidation.missingTargetOptionsInRules":
    "Question options are missing in form rules.",
  "message.createForm.success": "¡Formulario guardado con éxito!",
  "message.createForm.error": "Error al guardar el formulario. Inténtelo otra vez",
  "message.updateForm.success": "Formulario guardado con éxito.",
  "message.updateForm.error": "Error al guardar el formulario",
  "message.toggleFormEnable.success.enable": "Formulario habilitado con éxito!",
  "message.toggleFormEnable.success.disable": "Formulario deshabilitado con éxito!",
  "message.toggleFormEnable.error": "Error al habilitar / deshabilitar formulario.",
  "message.hasAnswers": "Formulario tiene respuestas",
  "message.hasNoAnswers": "Formulario no tiene respuestas",
  "message.hasAnswers.error": "Error al verificar si el formulario tiene respuestas",
  "message.deleteForm.success": "Formulario borrado con éxito.",
  "message.deleteForm.error": "Error al borrar el formulario.",
  "message.duplicate.modal.title": "¿Con qué nombre te gustaría guardar tu copia?",
  "message.duplicateForm.success": "Formulario duplicado correctamente.",
  "message.duplicateForm.error": "Error al duplicar el formulario.",
  "message.getMrk.error": "Error al obtener MRK",
  "message.getCulture.error": "Idioma no admitido",

  "messages.oops": "Uy",
  "messages.link_copied": "Enlace copiado",
  "messages.confirm_delete":
    "Borrar: ¿Estás seguro de que quieres borrar {{formTitle}}? Esta acción no puede deshacerse",
  "messages.confirm_delete_answers":
    "Este formulario ya tiene respuestas. Al proceder, todas las respuestas serán borradas y esta acción no puede deshacerse. ¿Quiere proceder?",
  "messages.confirm_title_duplicate": "Se enviará a la parte superior de la lista con el nombre de abajo:",
  "messages.required_fields": "Por favor, rellene todos los campos requeridos",
  "messages.error_support":
    "Se produjo un problema al realizar esta acción. Si el error persiste, por favor contacte con nuestro soporte.",
  "messages.error_field_id": "Por favor, compruebe la identificación del campo!",
  "messages.error_field_weight": "Por favor, compruebe los campos de peso!",
  "messages.error_all_field_weight": "Por favor, complete todos los campos de peso",
  "messages.error_fields_repeated_id":
    "El campo de ID {{fieldIdA}} de la sección {{sectionIdA}} es el mismo que el campo de ID {{fieldIdB}} de la sección {{sectionIdB}}.",
  "messages.error_section_repeated_id": "¡Hay una sección con el mismo nombre!",
  "messages.error_section_name": "Por favor, compruebe el nombre de la sección!",
  "messages.error_section_id": "Por favor, compruebe el ID de la sección!",
  "messages.error_section_repeated_name": "¡Hay una sección con el mismo nombre!",
  "messages.error_mrk": "No se encontró MRK",
  "messages.construction": "Esta sección está en construcción para mejorar aún más su experiencia",
  "messages.languages_note":
    "Si deja un campo vacío, se mostrará como el idioma predeterminado para el usuario.",
  "messages.users_not_found": "Usuarios no encontrados",
  "messages.confirm_rules": "¿Quieres establecer reglas para este formulario?",
  "messages.empty_rule": "Si tiene una regla condicional, puede crearla aquí",
  "messages.empty_section": "Seleccione un campo para agregarlo dentro de la sección",
  "messages.saved":
    "¡Tu formulario se ha guardado correctamente! Puede comprobarlo ahora en Mis formularios.",
  "messages.sign_out": "¿Quieres desconectarte del sistema?",
  "messages.cannot_change_form_status":
    "No puede habilitar o deshabilitar este formulario, ya que es copropietario.",
  "pages.my_forms": "Mis Formularios",
  "pages.new_form": "Nuevo Formulario",
  "pages.feedback": "Comentarios",
  "pages.builder": "Constructor",
  "pages.rules": "Reglas",
  "pages.languages": "Idiomas",
  "my_forms.id": "ID",
  "my_forms.form_code": "Código de formulario",
  "my_forms.creation_date": "Fecha de creación",
  "my_forms.updated_date": "Últimas modificaciones",
  "my_forms.enable": "Habilitar",
  "my_forms.owner": "Proprietario",
  "my_forms.actions": "Acciones",
  "actions.form": "Editar el formulario",
  "actions.rules": "Editar las reglas",
  "actions.users": "Editar usuarios",
  "actions.languages": "Editar el lenguaje",
  "actions.power_bi": "Enlace de PowerBI",
  "actions.export": "Exportar Formulario",
  "actions.delete": "Borrar el Formulario",
  "actions.duplicate": "Duplicar Formulario",
  "new_form.import": "Importar nuevo formulario",
  "new_form.creation_language": "Lenguaje de creación",
  "languages.portuguese": "Portugués",
  "languages.english": "Inglés",
  "languages.spanish": "Español",
  "languages.german": "Alemán",
  "languages.mandarin": "Mandarín",
  "languages.french": "Francés",
  "languages.turkish": "Turco",
  "languages.czech": "Checo",
  "languages.polish": "Polaco",
  "languages.slovenian": "Esloveno",
  "languages.swedish": "Sueco",
  "languages.kosovo_albanian": "Albanés",
  "fields.text": "Texto corto",
  "fields.textarea": "Texto largo",
  "fields.number": "Numérico",
  "fields.datetime": "Fecha y hora",
  "fields.photo": "Foto",
  "fields.dropdown": "Lista de escogencia",
  "fields.check_list": "Checklist",
  "fields.single_option": "Opción única",
  "fields.customer": "Cliente",
  "fields.equipment": "Equipo",
  "fields.campaign": "Campaña",
  "fields.end_reason": "Motivo de fín de campaña",
  "fields.qr_code": "Código QR/Código de barras",
  "fields.signature_qr": "Código QR de la firma",
  "fields.boolean": "Opción única",
  "fields.dropdown-campaign": "Campaña",
  "fields.dropdown-campaign-end-reason": "Motivo de fín de campaña",
  "fields.dropdown-customer": "Cliente",
  "fields.dropdown-equipment": "Equipo",
  "fields.pictureFile": "Foto",
  "fields.qrbarcode": "Código QR/Código de barras",
  "fields.signature-qrcode": "Código QR de la firma",
  "constructor.fields": "Campos",
  "constructor.filter": "Filtrar",
  "constructor.by.equipment": "Filtrar por equipo",
  "constructor.by.customer": "Filtrar por cliente",
  "pages.users": "Usuarios",
  "pages.usersAndCoOwners": "Usuarios y copropietarios",
  "constructor.basic": "Básico",
  "constructor.rhim": "Integración de RHIM",
  "constructor.section_title": "Título de la sección",
  "constructor.unsaves_changes": "Cambios no guardados",
  "constructor.mrk": "MRK del equipo",
  "constructor.preferences": "Preferencias",
  "preferences.placeholder": "Seleccione un campo para editar sus preferencias",
  "preferences.checklistValue": "Máximo de opciones marcadas",
  "preferences.required": "Requerido",
  "preferences.score": "Pontuación",
  "preferences.options": "Opciones",
  "preferences.automatic": "Automático",
  "preferences.automatic_id": "Id Automático",
  "preferences.field_id": "Identificador de campo",
  "preferences.field_weight": "Peso del campo",
  "preferences.section_weight": "Peso de la sección",
  "preferences.section_id": "Identificador de la sección",
  "preferences.options_placeholder": "Escriba los elementos separándolos con enter",
  "preferences.mrk_placeholder": "Seleccione un MRK",
  "preferences.max": "Maximum",
  "preferences.min": "Minimum",
  "preferences.formDescription": "Usar como descrição de formulário",
  "preferences.error_empty_qrcode": "Choose at least one option",
  "preferencias.typeQrOrBarCode": "Tipo",
  "preferencias.IntegerOrFloat": "Tipo",
  "preferences.option_integer": "Entero",
  "preferencias.option_decimal": "Decimal",
  "add_options.option": "Opciones",
  "add_options.weight": "Peso",
  "add_options.response": "Respuesta",
  "add_options.add_na": "Añadir N/A",
  "construction.go_back": "Regrese",
  "languages.notes": "Notas",
  "users.email": "Correo electrónico de los usuarios",
  "coOwners.email": "Correo electrónico de los copropietarios",
  "users.multiple_association":
    "Asociación múltiple (llénelo con una lista de correo con separador de punto y coma)",
  "users.finish": "Encerrar formulário",

  "globalization.title": "Escribe tu título del formulário en",
  "globalization.section": "Escribe tu título de sección en",
  "globalization.field": "Escribe tu nombre de campo en",
  "globalization.field.description": "Escribe la descripción de su campo en",
  "globalization.option": "Escribe el valor de la opción en",

  "rules.if": "Si",
  "rules.status": "Estado",
  "rules.response": "Respuesta",
  "rules.action": "Acción",
  "rules.question": "Pregunta",
  "rules.options": "Opciones",

  "option.is": "Es",
  "option.isn't": "No es",
  "option.hide.section": "Ocultar Sección",
  "option.hide.field": "Ocultar campo",
  "option.show.section": "Mostrar sección",
  "option.show.field": "Mostrar campo",
  "option.filter": "Filtrar",
  "option.required": "Obligatorio",

  "pagination.view": "Viendo {{from}}-{{to}} de {{count}}",
  "pagination.rows_per_page": "Filas por página:",
  "messages.error_form_access_denied":
    "No tiene acceso a este formulario. Busque un formulario en el que sea autor o coautor",
  "messages.page_not_found": "Página no encontrada",
  "messages.text_page_not_found": "Lo sentimos, la página a la que intenta acceder no existe.",
  "messages.go_back_my_forms": "Volver a Mis Formularios",
  "messages.access_denied": "Acceso denegado",
  "messages.text_access_denied":
    "No tienes acceso a este formulario. Busque un formulario en el que sea autor o coautor.",
  "messages.form_not_found": "Formulario no encontrado",
  "messages.text_form_not_found":
    "Lo sentimos, el formulario al que intenta acceder no existe. Por favor, busque otro formulario o cree uno nuevo.",
  "message.formValidation.multipleEquipmentInSection":
    "El formulario solo puede tener un campo Equipo SAR por sección",
  "preferences.description": "Descripción",
  "messages.fieldsInformDescriptionPreference":
    "You have already selected {{numberOfFieldsInDescription}}/{{MAXIMUM_FIELDS_IN_DESCRIPTION}} fields",
  "messages.error_field_no_options": "¡Por favor revise las opciones de campo!",
  "messages.error_single_option_no_options":
    "No puede guardar un solo campo de opción sin opciones.",
  "messages.max_filled_as_form_description": "To select this field, please unselect other field",
  "messages.tooltip_decimal_minimum_alert":
    "Debido a las 2 posiciones decimales, el límite mínimo de caracteres para campos de tipo decimal es 3.",
  "message.form_last_update": "Última actualización",
  "message.form_last_update_by": "Por"
};
