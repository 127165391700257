export class UpdateFieldTranslation {
  filterItemsByFieldListUuid = (translatedItems: any[], fieldListUuid: any) => {
    return (translatedItems || []).filter((item) => item.fieldListUuid === fieldListUuid).flat();
  };

  addMissingLocalesToFields = (fieldGroups: any, languageId: any) => {
    for (const fields in fieldGroups) {
      for (const { formFieldLocales, id } of fields as any) {
        const hasLocale = formFieldLocales?.some(
          (locale: { cultureId: any }) => locale.cultureId === languageId
        );
        if (!hasLocale) {
          formFieldLocales?.push({
            cultureId: languageId,
            formFieldId: id,
            title: ""
          });
        }
      }
    }
  };

  setEmptyTitleForMatchingItems = (matchingItems: any[], isoCode: any) => {
    matchingItems.forEach((item: { [x: string]: string }) => {
      if (!item[`title-${isoCode}`]) {
        item[`title-${isoCode}`] = "";
      }
    });
  };

  updateLocaleForField = (
    items: { [s: string]: unknown } | ArrayLike<unknown>,
    fieldUuid: any,
    language: { id: any },
    text: any
  ) => {
    Object.values(items).forEach((fields: any) => {
      const fieldToUpdate = fields.find((field: { uuid: any }) => field.uuid === fieldUuid);
      if (!fieldToUpdate) {
        return;
      }
      const localeToUpdate = fieldToUpdate.formFieldLocales.find(
        (locale: { cultureId: any }) => locale.cultureId === language.id
      );
      if (localeToUpdate) {
        localeToUpdate.title = text;
      } else {
        fieldToUpdate.formFieldLocales.push({
          cultureId: language.id,
          formFieldId: fieldToUpdate.id,
          title: text
        });
      }
    });
  };

  updateTitleForMatchingItems = (matchingItems: any[], isoCode: any, text: any) => {
    matchingItems.forEach((item: { [x: string]: any }) => {
      if (item[`title-${isoCode}`]) {
        item[`title-${isoCode}`] = text;
      }
    });
  };

  removeLocalesFromFields = (
    items: { [s: string]: unknown } | ArrayLike<unknown>,
    cultureId: any
  ) => {
    Object.values(items).forEach((fields: any) => {
      fields.forEach((field: { formFieldLocales: any[] }) => {
        field.formFieldLocales = field.formFieldLocales.filter(
          (locale: { cultureId: any }) => locale.cultureId !== cultureId
        );
      });
    });
  };

  removeTitleForMatchingItems = (matchingItems: any[], isoCode: any) => {
    matchingItems.forEach((item: { [x: string]: any }) => {
      delete item[`title-${isoCode}`];
    });
  };
}
