import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { RhimButton, RhimDialog } from "rhim-ui";
import { useModalVisibility } from "../../../../feature/modal";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { FormName } from "../../atoms";
import FormSavingStatus from "../../atoms/FormSavingStatus";
import UpdateStatus from "../../atoms/UpdateStaus";
import { SecondaryMenu } from "../../molecules";
import { MenuItem } from "../../molecules/SecondaryMenu/SecondaryMenu";
import "./HeaderBuilderScreen.scss";
import { conditional, getMenuItems, getNextMenu } from "./HeaderBuilderUtil";

const HeaderBuilderScreen = () => {
  const { t } = useTranslation();
  const { modalProperties, setModalProperties } = useModalVisibility({
    visible: false
  });
  const navigate = useNavigate();
  const menuItems = getMenuItems(t);
  const [isInAssociationPage, setIsInUsersAssociationPage] = useState(false);
  const location = useLocation();

  const form = useStoreState((state) => state.form.form!);
  const save = useStoreActions((actions) => actions.form.save);
  const saveForm = async () => {
    await save({ form, t });
  };

  const isFormSaved = useStoreState((state) => state.form.isSaved);

  useEffect(() => {
    setIsInUsersAssociationPage(location.pathname === `/Builder/UsersAssociation/${form.formId}`);
  }, [location]);

  const goTo = (menu: MenuItem) => {
    navigate(menu.path);
  };

  const renderModalMessage = () => {
    return <p className="modal-text">{t("messages.confirm_rules")}</p>;
  };

  const handleCancel = () => {
    const { nextIndex } = getNextMenu(menuItems);
    const index = nextIndex + (1 % menuItems.length);
    goTo(menuItems[index]);
  };

  const handleConfirm = () => {
    const { next } = getNextMenu(menuItems);
    goTo(next);
  };

  const openModal = () => setModalProperties({ visible: true });

  const navigateWithCondition = (menu: MenuItem, condition: (menu: MenuItem) => boolean) =>
    conditional(menu, condition, goTo, openModal);

  const handleContinueClick = () => {
    const { next } = getNextMenu(menuItems);
    const rulesIsSelected = (menu: MenuItem) => menu.path !== "/Builder/Rules";
    navigateWithCondition(next, rulesIsSelected);
  };

  return (
    <header className="header-builder-screen">
      <div className="sub-menu">
        <div className="form-info-and-header-menu">
          <div className="form-name-and-header-menu">
            <FormName />
            <div className="menu">
              <SecondaryMenu menuItems={menuItems} navigate={goTo} />
            </div>
          </div>
          <UpdateStatus date={form.updatedAt!} email={form.updatedBy!} />
          <div className="form-satuts">
            <FormSavingStatus isSaved={isFormSaved} />
          </div>
        </div>
      </div>

      <div className="action-buttons">
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={2}>
          {!isInAssociationPage && (
            <Grid item>
              <RhimButton
                variant="outlined"
                icon={<Icon>arrow_forward</Icon>}
                iconSide="right"
                title={t("common.continue").toUpperCase()}
                onClick={
                  isInAssociationPage
                    ? async () => {
                        await saveForm();
                      }
                    : handleContinueClick
                }
              />
            </Grid>
          )}
          <Grid item>
            <RhimButton
              variant="contained"
              title={
                isInAssociationPage
                  ? t("users.finish").toUpperCase()
                  : t("common.save").toUpperCase()
              }
              onClick={async () => {
                await saveForm();
                if (isInAssociationPage) {
                  navigate("/MyForms");
                }
              }}
            />
          </Grid>
        </Grid>
      </div>

      <RhimDialog
        isMobile={false}
        title={<p className="modal-title">{t("pages.rules")}</p>}
        isOpen={modalProperties.visible}
        confirm={{
          title: t("pages.rules").toUpperCase(),
          handleFunction: () => {
            handleConfirm();
            setModalProperties({ visible: !modalProperties.visible });
          }
        }}
        cancel={{
          title: t("common.skip").toUpperCase(),
          handleFunction: () => {
            handleCancel();
            setModalProperties({ visible: !modalProperties.visible });
          }
        }}
        content={<p className="modal-text">{renderModalMessage()}</p>}
      />
    </header>
  );
};

export default HeaderBuilderScreen;
