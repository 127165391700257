import React, { ChangeEvent, useState } from "react";
import "./styles/NewFormPage.scss";
import { Form } from "../interface/Form";
import { CreateFormButton, DropdownLanguage } from "../../../shared/components/atoms";
import { useNavigate } from "react-router-dom";
import { getNewForm } from "../FormManager/FormManager";
import { useTranslation } from "react-i18next";
import { useStoreActions } from "../../../store/hooks";

export interface NewFormPageProps {
  createForm: (form: Form) => Promise<void>;
  saveForm: (form: Form) => Promise<Form>;
}

const NewFormPage = (props: NewFormPageProps) => {
  const { t } = useTranslation();
  const { saveForm } = props;
  const newForm = getNewForm();
  const [form, setForm] = useState<Form>(newForm);
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  const setFormStore = useStoreActions((action) => action.form.setForm);

  const handleChange = (key: string, value: string) => {
    const formUpdated = { ...form, [key]: value };
    validateForm(formUpdated);
    setForm(formUpdated);
  };

  const handleSave = async () => {
    const newSavedForm = await saveForm(form);
    setFormStore(newSavedForm);
    navigate(`/Builder/Constructor/${newSavedForm.formId}`);
  };

  const validateForm = (form: Form) => {
    let isValid = false;
    if (form.title) {
      isValid = true;
    }
    setIsFormValid(isValid);
  };

  return (
    <div className="new-form-page">
      <div className="container">
        <h1 className="title">{t("pages.new_form")}</h1>
        <div className="data-content">
          <span className="label-title">{t("common.title")}</span>
          <input
            type="text"
            className="form-element"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleChange("title", event.target.value);
            }}
            value={form.title}
          />
          <span className="label-title">{t("new_form.creation_language")}</span>
          <DropdownLanguage
            options={[
              { key: "languages.portuguese", value: "pt-BR" },
              { key: "languages.english", value: "en-US" },
              { key: "languages.spanish", value: "es-ES" },
              { key: "languages.german", value: "de-AT" },
              { key: "languages.mandarin", value: "zh-CHS" },
              { key: "languages.french", value: "fr-FR" },
              { key: "languages.turkish", value: "tr-TR" },
              { key: "languages.czech", value: "cs-CZ" },
              { key: "languages.slovenian", value: "sl-SI" },
              { key: "languages.polish", value: "pl-PL" },
              { key: "languages.swedish", value: "sv-SE" },
              { key: "languages.kosovo_albanian", value: "sq-KV" },

            ]}
            handleChange={handleChange}
            form={form}
          />
          <CreateFormButton isActive={isFormValid} onClickFunction={handleSave} />
        </div>
      </div>
    </div>
  );
};

export default NewFormPage;
