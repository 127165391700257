export const colors = {
  transparent: "transparent",
  warning: "#F6BC26" ,
  primary: "#003262",
  success: "#00CC66",
  error: "#ED2C2A",
  greyZero: "#ECEEF0",
  greyOne: "#D8DDE1",
  greyTwo: "#B1BBC2",
  greyThree: "#294152",
  surface: "#ffffff",
  textGrey: "#8D9499",
  textPrimary: "#000",
  border: "#D7DDE1",
}

export const gathererTheme = {
  overrides: {
    MuiInputBase: {
      root: {
        borderColor: "red"
      }
    },
    palette: {
      inherit: { main: "#8D9499" }
    }
  },
  typography: {
    fontFamily: ['"Roboto"', "Arial"].join(","),
    body1: {
      fontSize: 16
    },
    body2: {
      fontSize: 14
    },
    subtitle1: {
      fontWeight: "bold",
      fontSize: 16
    },
    subtitle2: {
      fontWeight: "bold",
      fontSize: 14
    },
    sizes: {
      subtextSize: 10,
      bodySize: 14,
      leadSize: 16,
      titleSize: 20
    }
  },
  palette: {
    transparent: { main: "transparent" },
    warning: { main: colors.warning },
    primary: { main: colors.primary },
    success: { main: colors.success },
    error: { main: colors.error },
    greyZero: { main: colors.greyZero },
    greyOne: { main: colors.greyOne },
    greyTwo: { main: colors.greyTwo },
    surface: { main: colors.surface },
    textGrey: { main: colors.textGrey },
    textPrimary: { main: colors.textPrimary },
    border: { main: colors.border }
  },
  shape: {
    smallRadius: 4,
    mediumRadius: 4,
    largeRadius: 4
  },
  layout: {
    menuOpenedDesk: 250,
    menuClosedDesk: 64,
    headerHeight: 64
  }
};
