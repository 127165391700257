import React, { useEffect } from "react";
import MyFormsPage from "./MyFormsPage";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { Form } from "../interface/Form";
import { getForm } from "../FormManager/FormManager";
import { useTranslation } from "react-i18next";

const MyFormContainer = () => {
  const { t } = useTranslation();
  const selectForm = useStoreActions((actions) => actions.form.selectForm);
  const getForms = useStoreActions((actions) => actions.form.getForms);
  const forms = useStoreState((state) => state.form.items);
  const toggleActive = useStoreActions((state) => state.form.toggleActive);
  const duplicateForm = useStoreActions((state) => state.form.duplicateForm);
  const deleteForm = useStoreActions((state) => state.form.deleteForm);
  const selectLanguage = useStoreActions((state) => state.languages.selectLanguage);

  useEffect(() => {
    return () => selectLanguage(undefined);
  }, []);

  return (
    <MyFormsPage
      forms={forms}
      selectForm={selectForm}
      toggleActive={(form: Form) => toggleActive({ form, t })}
      getForms={() => {
        getForms({ t });
      }}
      duplicateForm={(formId: number, formTitle: string) => duplicateForm({ num: formId, t, formTitle: formTitle })}
      deleteForm={(formId: number) => deleteForm({ num: formId, t })}
      getForm={getForm}
    />
  );
};

export default MyFormContainer;
