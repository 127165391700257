import React from "react";
import "./TitleSection.scss";
import { TextProps } from "../../../../repositories/interfaces";

const TitleSection = (props: TextProps) => {
  const { text } = props;
  return <p className="TitleSection">{text}</p>;
};

export default TitleSection;
