import React, { useState, useEffect } from "react";
import { FieldsListItem } from "../../molecules";
import "./FieldsList.scss";
import { ActionCreator } from "easy-peasy";
import { CatalogItem } from "../../../../feature/catalog/interface/CatalogItem";

interface FieldsListInterface {
  items: CatalogItem[];
  onSelectField: ActionCreator<CatalogItem>;
}

const FieldsList = (props: FieldsListInterface) => {
  const { items, onSelectField } = props;
  const [fieldList, setFieldList] = useState(items);
  useEffect(() => {
    setFieldList(items);
  }, [items, fieldList]);
  return (
    <section className="fields-list-mainColumn">
      {items?.map((field, idx) => {
        field.index = idx;
        return <FieldsListItem key={field.index} item={field} onSelectField={onSelectField} />;
      })}
    </section>
  );
};

export default FieldsList;
