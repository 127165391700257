import React from "react";
import "./MainColumn.scss";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SectionList from "../SectionList/SectionList";

const MainColumn = () => {
  return (
    <main id="main-column-organism">
      <article id="sections-list-MainColumn">
        <DndProvider backend={HTML5Backend}>
          <SectionList />
        </DndProvider>
      </article>
    </main>
  );
};

export default MainColumn;
