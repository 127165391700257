import React, { useState, useEffect } from "react";
import "./DropdownBuilder.scss";
import { OptionsListDropdownBuilder } from "../../atoms";
import { FormOption } from "../../../../repositories/interfaces";
import { Icon } from "@material-ui/core";
import { useStoreState } from "../../../../store/hooks";

interface DropdownBuilderProps {
  title: string;
  mandatory: boolean;
  optionsArray: any[];
}

const DropdownBuilder = (props: DropdownBuilderProps) => {
  const { title, mandatory, optionsArray } = props;
  const selectedField = useStoreState((state) => state.fields.selectedField);
  const [options, setOptions] = useState<FormOption[]>([
    {
      option: "",
      index: 0,
      formOptionLocales: [],
      fieldId: selectedField?.id ?? 0,
      formFieldId: selectedField?.id ?? 0
    }
  ]);

  const getOpts = (arrayAux: FormOption[]) =>
    arrayAux.map((option) => option?.option ?? "").join(", ");

  const getLength = (arrayAux: FormOption[]) =>
    arrayAux.filter((option) => option !== undefined).length;

  useEffect(() => {
    setOptions(optionsArray || []);
  }, [optionsArray]);

  return (
    <div className="dropdown-builder field-item">
      <h2 className="title">
        {title}
        {mandatory && <span className="mandatory-signal">* </span>}
      </h2>

      <div className="content-field-item content">
        <div className="field">
          <p>{getOpts(options)}</p>
          <Icon className="icon">expand_more</Icon>
        </div>
        <OptionsListDropdownBuilder
          title={title}
          optionsList={options}
          getLengthFunction={getLength}
        />
      </div>
    </div>
  );
};

export default DropdownBuilder;
