import React from "react";
import "./FixedListItemsDropdownPreference.scss";
import { ItemPreference } from "../../../../repositories/interfaces";
import { TitleColumn } from "../../atoms";
import { useTranslation } from "react-i18next";

interface ListItemsDropdownPreferenceProps {
  preference: ItemPreference;
}

const FixedListItemsDropdownPreference = (props: ListItemsDropdownPreferenceProps) => {
  const { t } = useTranslation();
  const { preference } = props;

  const getContent = (preference: ItemPreference) => {
    const optionsArray = preference.value ? JSON.parse(preference.value) : [];
    const splitString = optionsArray ? optionsArray.join("\n") : [];
    return splitString;
  };

  const content = getContent(preference);

  return (
    <section className="fixed-list-items-preference">
      <TitleColumn text={t("preferences.options")} />
      <textarea
        readOnly
        value={content}
        className="fixed-content"
        placeholder={t("preferences.mrk_placeholder")}
      />
    </section>
  );
};

export default FixedListItemsDropdownPreference;
