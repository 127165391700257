import React from "react";
import "./BodyBuilderScreen.scss";
import { TitleSection, AddSectionButton } from "../../atoms";
import CatalogContainer from "../../../../feature/catalog/views/CatalogContainer";

import { ErrorBoundary } from "../../../../feature/errorBoundary";
import { useTranslation } from "react-i18next";
import MainColumn from "../MainColumn/MainColumn";
import PreferenceColumn from "../PreferenceColumn";

const BodyBuilderScreen = () => {
  const { t } = useTranslation();
  return (
    <main className="body-builder-screen">
      <section className="catalog-column">
        <article className="title">
          <TitleSection text={t("constructor.fields")} />
        </article>
        <ErrorBoundary>
          <CatalogContainer />
        </ErrorBoundary>
        <AddSectionButton />
      </section>
      <section className="main">
        <MainColumn />
      </section>
      <section className="preferences">
        <article className="title">
          <TitleSection text={t("constructor.preferences")} />
        </article>
        <PreferenceColumn />
      </section>
    </main>
  );
};

export default BodyBuilderScreen;
