import React, { useState } from "react";
import "../styles/GlobalizationSection.scss";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { getPlaceholderLanguage } from "../../../helpers/util";
import { useTranslation } from "react-i18next";
import GlobalizationSectionFieldList from "./GlobalizationSectionFieldList";
import { Icon } from "@material-ui/core";
import { LanguageItem } from "../../languages/interface/LanguageItem";

const GlobalizationSectionList = (props: GlobalizationSectionListProps) => {
  const { t } = useTranslation();
  const { section, selectedLanguage, languageName, isDefaultLanguage, defaultLanguageIsoCode } =
    props;
  const [openStatus, setOpenStatus] = useState(true);

  const fieldList = useStoreState((state) => state.fields.items);

  const updateTranslation = useStoreActions((action) => action.sections.updateSectionTranslation);

  const languageList = useStoreState((state) => state.languages.items);
  const cultureId = languageList.find(
    (language: LanguageItem) => language.isoCode === selectedLanguage?.isoCode
  )?.id;

  const defaultLanguageId = languageList.find(
    (language: LanguageItem) => language.isoCode === defaultLanguageIsoCode
  )?.id;
  return (
    <article className="globalization-section-list-item">
      <section className="globalization-section-header">
        <Icon
          className={`globalization-section-icon-button
          ${
            !isDefaultLanguage
              ? "globalization-edit-open-close-button"
              : "globalization-open-close-button"
          }`}
          onClick={() => {
            setOpenStatus(!openStatus);
          }}>
          {openStatus ? "expand_less" : "expand_more"}
        </Icon>
        <article className="globalization-header-container">
          <span
            className={`globalization-header-title${
              !isDefaultLanguage ? " globalization-header-title-edit" : ""
            }`}>
            {t(
              section.formSectionLocales.find(
                (locale: any) => locale.cultureId === defaultLanguageId
              )?.title ?? ""
            )}
          </span>
          {!isDefaultLanguage && selectedLanguage && (
            <input
              readOnly={isDefaultLanguage}
              className="globalization-header-input"
              placeholder={`${t("globalization.section")} ${t(
                getPlaceholderLanguage(selectedLanguage.isoCode),
                selectedLanguage
              )}`}
              value={
                section.formSectionLocales.find(
                  (locale: any) => locale.cultureId === selectedLanguage?.id
                )?.title ?? ""
              }
              onChange={(event) => {
                updateTranslation({
                  language: selectedLanguage,
                  text: event.target.value,
                  type: "UPDATE",
                  uuid: section.uuid,
                  cultureId: cultureId
                });
              }}
            />
          )}
        </article>
      </section>
      {openStatus && (
        <article className="globalization-field-list-container">
          {fieldList[section.uuid]?.map((item, index) => {
            return (
              <GlobalizationSectionFieldList
                key={index}
                isDefaultLanguage={isDefaultLanguage}
                fieldItem={item}
                index={index + 1}
                selectedLanguage={selectedLanguage}
                languageName={languageName}
              />
            );
          })}
        </article>
      )}
    </article>
  );
};

interface GlobalizationSectionListProps {
  section: any;
  fieldItems: any[];
  selectedLanguage: LanguageItem | undefined;
  languageName: string;
  isDefaultLanguage: boolean;
  defaultLanguageIsoCode: string;
}

export default GlobalizationSectionList;
