import React from "react";
import "./SecondaryMenu.scss";
import { useLocation } from "react-router";
import { SecondaryMenuItem } from "../../atoms";

export interface SecondaryMenuProps {
  menuItems: MenuItem[];
  navigate: (item: MenuItem) => void;
}

export interface MenuItem {
  id: string;
  path: string;
  icon: string;
  title: string;
  isSelected: boolean;
}

const SecondaryMenu = (props: SecondaryMenuProps) => {
  const location = useLocation();
  const { menuItems, navigate } = props;
  return (
    <menu className="secondary-menu">
      {menuItems.map((item) => {
        item.isSelected = location.pathname.includes(item.path);
        return (
          <SecondaryMenuItem
            key={item.id}
            id={item.id}
            path={item.path}
            icon={item.icon}
            title={item.title}
            isSelected={item.isSelected}
            onClick={() => {
              navigate(item);
            }}
          />
        );
      })}
    </menu>
  );
};

export default SecondaryMenu;
