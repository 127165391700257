import React from "react";
import { Navigate, Route } from "react-router-dom";
import { RulesContainer } from "../../../../feature/rules";

import GlobalizationPage from "../GlobalizationPage";
import UsersAssociationPage from "../UsersAssociationPage";
import BuilderPage from "./BuilderPage";
import BodyBuilderScreen from "../../organisms/BodyBuilderScreen/BodyBuilderScreen";

const getBuilderRoutes = () => {
  return (
    <Route path="/Builder/*" element={<BuilderPage />}>
      <Route path="" element={<Navigate to="Constructor/:formId" />} />
      <Route path="Constructor/:formId" element={<BodyBuilderScreen />} />
      <Route path="Rules/:formId" element={<RulesContainer />} />
      <Route path="UsersAssociation/:formId" element={<UsersAssociationPage />} />
      <Route path="Globalization/:formId" element={<GlobalizationPage />} />
    </Route>
  );
};

export default getBuilderRoutes;
