import { Dictionary } from "../../../../models/Dictionary";
import { Section } from "../../../../models/Section/Section";
import { ItemPreference } from "../../../../repositories/interfaces";
import { CatalogItem } from "../../../catalog/interface/CatalogItem";

export class VerifyScoreWeight {
  constructor(private readonly fields: Dictionary<CatalogItem[]>) {}
  private checkField = (field: CatalogItem) => {
    const optionsString = this.getOptionsString(field.preferences);
    if (field.score && !field.weight) {
      return false;
    }
    if (optionsString) {
      const options = this.parseOptions(optionsString);
      options.forEach((option: any) => {
        if (option.score && !option.weight) {
          return false;
        }
      });

      if (this.hasInvalidOptions(field.score, options)) {
        return false;
      }
    }

    return true;
  };

  private getOptionsString = (preferences: ItemPreference[]) => {
    const optionPreference = preferences.find(
      (p) => p.key === "ListItemsDropdown" || p.key === "ListItemsRadio"
    );
    return optionPreference?.value;
  };

  private parseOptions = (optionsString: any) => {
    return JSON.parse(optionsString);
  };

  private hasInvalidOptions = (score: boolean, options: any) => {
    for (const option of options) {
      const { isNA, weight: optionWeight } = option;
      if (score && !isNA && (optionWeight == null || optionWeight === "" || isNaN(optionWeight))) {
        return true;
      }
    }
    return false;
  };

  checkSection = (section: Section) => {
    const { score, weight, uuid } = section;

    if (score && !weight) {
      return false;
    }

    const fieldsInSection = this.fields[uuid];
    if (!fieldsInSection) {
      return true;
    }
    return fieldsInSection.every((field) => this.checkField(field) === true);
  };
}
