import { action, computed, thunkOn } from "easy-peasy";
import { getAllFieldIds, getUniqueId } from "../../../../helpers/util";

import { FieldModelInterface } from "../fieldModelInterface";
import { FormField } from "../../../../feature/form/interface/FormField";
import { UpdateFieldTranslation } from "./utils/updateFieldTranslation";
import { fieldListToFormField } from "../../../../feature/form/FormUtil";
import { moveFields } from "../fieldModelUtil";

const SAR_FIELDS = [
  "dropdown-customer",
  "dropdown-campaign",
  "dropdown-campaign-end-reason",
  "dropdown-equipment"
];

const fieldModel: FieldModelInterface = {
  items: {},
  translatedItems: [],
  selectedListUuid: "",
  hasSelectedField: false,
  selectedField: null,
  setItems: action((state, payload) => {
    const items = { ...payload } as any;
    state.items = { ...payload };
    state.translatedItems = Object.keys(items).map((key) =>
      items[key].map((item: any) => {
        const preferencesOptions = item.preferences.find((preference: any) =>
          preference.key.includes("List")
        )?.value;
        const hasOptions = !SAR_FIELDS.includes(item.type);
        const preferenceTitle = item.preferences.filter((p: any) => p.key == "Title")[0].value;
        const type = item.type;
        return {
          ...item,
          type,
          fieldListUuid: item.fieldListUuid,
          itemName: item.type,
          defaultLanguageName: preferenceTitle,
          options: preferencesOptions && hasOptions ? JSON.parse(preferencesOptions) : []
        };
      })
    );
  }),
  selectedList: computed((state) => {
    const { selectedListUuid, items } = state;
    return items[selectedListUuid] || [];
  }),
  setField: action((state, payload) => {
    const { items } = state;
    items[payload.fieldListUuid][payload.index] = payload;
    state.selectedField = { ...payload };
    state.items = { ...state.items, ...items };
    const sectionIndex = Object.keys(items)?.findIndex((key) => key === payload.fieldListUuid);
    const fieldIndex = state.translatedItems[sectionIndex]?.findIndex(
      (item: any) => item.uuid === payload.uuid
    );
    const preferencesOptions = payload.preferences.find((preference: any) =>
      preference.key.includes("List")
    )?.value;
    const preferenceTitle = payload.preferences.filter((p) => p.key == "Title")[0].value;
    const hasOptions = !SAR_FIELDS.includes(payload.type);
    const selectedField = state.translatedItems[sectionIndex][fieldIndex];
    selectedField.fieldId = payload.fieldId;
    selectedField.type = payload.type;
    selectedField.fieldListUuid = payload.fieldListUuid;
    selectedField.preferences = payload.preferences;
    Object.keys(selectedField).forEach((key) => {
      if (
        key.includes("title-") &&
        selectedField.score === payload.score &&
        selectedField.defaultLanguageName !== preferenceTitle
      ) {
        selectedField[key] = "";
      }
    });
    if (
      selectedField.score === payload.score &&
      selectedField.defaultLanguageName === preferenceTitle
    ) {
      selectedField.options =
        preferencesOptions && hasOptions ? JSON.parse(preferencesOptions) : [];
    }
    selectedField.defaultLanguageName = preferenceTitle;
    selectedField.score = payload.score;
    selectedField.weight = payload.weight;
  }),
  addField: action((state, payload) => {
    const { items } = state;
    const { field, t } = payload;
    const fieldList = items[field.fieldListUuid] || [];
    const allFieldList = Object.values(items).flat() || [];
    const formFields = fieldListToFormField(allFieldList || []) as FormField[];
    const allFieldIds = getAllFieldIds(formFields);
    const fieldTitle = field.name.includes("fields.") ? t(field.name) : field.name;
    const fieldId = getUniqueId(field.type, fieldTitle, allFieldIds);
    state.items[field.fieldListUuid] = [...fieldList, field];
    const sectionIndex = Object.keys(items).indexOf(field.fieldListUuid);
    if (state.translatedItems.length - 1 < sectionIndex) {
      state.translatedItems.push([]);
    }
    state.translatedItems[sectionIndex].push({
      fieldId: fieldId,
      type: field.type,
      uuid: field.uuid,
      fieldListUuid: field.fieldListUuid,
      preferences: field.preferences,
      itemName: field.type,
      defaultLanguageName: field.name,
      options: [],
      index: sectionIndex
    });
  }),

  removeField: action((state, payload) => {
    const { items, selectedList, translatedItems } = state;

    const sectionIndex = Object.keys(items).indexOf(payload.fieldListUuid);
    const fieldIndex = translatedItems[sectionIndex]?.findIndex(
      (item: any) => item.uuid === payload.uuid
    );

    selectedList.splice(payload.index, 1);

    if (payload.relation) {
      const relationIndex = selectedList.findIndex(
        (item) => item.relation && item.relation === payload.relation
      );
      selectedList.splice(relationIndex, 1);
    }

    translatedItems[sectionIndex].splice(fieldIndex, 1);
    items[payload.fieldListUuid] = selectedList;

    state.hasSelectedField = false;
    state.selectedField = null;
  }),

  selectField: action((state, payload) => {
    state.hasSelectedField = true;
    state.selectedField = payload;
    state.selectedListUuid = payload.fieldListUuid;
  }),
  deselectField: action((state) => {
    state.hasSelectedField = false;
    state.selectedField = null;
  }),
  moveFieldUp: action((state, payload) => {
    if (payload.index) {
      const indexAbove = payload.index - 1;
      const fieldsList = state.selectedList.slice();
      let translatedIndex = 0;
      const fieldsTranslatedList = moveFields(
        state.translatedItems,
        translatedIndex,
        fieldsList,
        payload.index,
        indexAbove
      );
      state.items[payload.fieldListUuid] = fieldsList;
      state.translatedItems[translatedIndex] = fieldsTranslatedList;
    }
  }),
  moveFieldDown: action((state, payload) => {
    const indexBelow = payload.index + 1;
    const fieldsList = state.selectedList.slice();
    if (indexBelow < fieldsList.length) {
      let translatedIndex = 0;
      const fieldsTranslatedList = moveFields(
        state.translatedItems,
        translatedIndex,
        fieldsList,
        payload.index,
        indexBelow
      );
      state.items[payload.fieldListUuid] = fieldsList;
      state.translatedItems[translatedIndex] = fieldsTranslatedList;
    }
  }),
  removeList: action((state, payload) => {
    const index = Object.keys(state.items).indexOf(payload);

    if (index >= 0) {
      state.translatedItems.splice(index, 1);
    }

    delete state.items[payload];
  }),
  onSelectSection: thunkOn(
    (actions, storeActions) => [storeActions.sections.selectSection],
    (actions, target, { getState, getStoreState }) => {
      const { fieldListUuid } = getState().selectedField || {};
      const { selectedSection } = getStoreState().sections;
      if (fieldListUuid !== selectedSection?.uuid) {
        actions.deselectField();
      }
    }
  ),
  onFormClear: thunkOn(
    (actions, storeActions) => [storeActions.form.clearFormData],
    (actions, target, { getState }) => {
      const state = getState();
      actions.deselectField();
      state.items = {};
    }
  ),

  updateOptionTranslation: action((state: any, payload) => {
    const { language, text, type, defaultOption } = payload;
    const section = state.translatedItems
      ?.flat()
      .filter((item: any) => item.fieldListUuid === payload.fieldListUuid);
    if (state.translatedItems) {
      if (type === "ADD") {
        handleAdd(state, language);
      }
      if (type === "UPDATE") {
        handleUpdate(state, payload, language, text, defaultOption, section);
      }
      if (type === "REMOVE") {
        handleRemove(state, language);
      }
    }
  }),
  updateFieldDescriptionTranslation: action((state, payload) => {
    const { language, description } = payload;
    const sections = state.translatedItems
      ?.flat()
      .filter((item: any) => item.fieldListUuid === payload.fieldListUuid);
    if (state.items) {
      for (let key in state.items) {
        let fieldToUpdate: any = state.items[key]?.find(
          (field: any) => field.uuid === payload.fieldUuid
        );
        if (fieldToUpdate) {
          let localeToUpdate = fieldToUpdate.formFieldLocales.find(
            (locale: any) => locale.cultureId === language.id
          );
          if (localeToUpdate) {
            localeToUpdate.description = description;
          } else {
            const newLocale = {
              cultureId: language.id,
              description: description,
              formFieldId: fieldToUpdate.id,
              title: ""
            };
            fieldToUpdate.formFieldLocales.push(newLocale);
          }
        }
      }
      sections?.forEach((item: any, index: number) => {
        if (index === payload.index) item[`description-${language.isoCode}`] = description;
      });
    }
  }),

  updateFieldTitleTranslation: action((state, payload) => {
    const { language, title, type } = payload;
    const updateFieldTranslation = new UpdateFieldTranslation();
    const itemsByFieldUuid = updateFieldTranslation.filterItemsByFieldListUuid(
      state.translatedItems,
      payload.fieldListUuid
    );
    switch (type) {
      case "ADD":
        updateFieldTranslation.addMissingLocalesToFields(state.items, language);
        updateFieldTranslation.setEmptyTitleForMatchingItems(itemsByFieldUuid, language.isoCode);
        break;
      case "UPDATE":
        updateFieldTranslation.updateLocaleForField(
          state.items,
          payload.fieldUuid,
          language,
          title
        );
        updateFieldTranslation.updateTitleForMatchingItems(
          itemsByFieldUuid,
          language.isoCode,
          title
        );
        break;
      case "REMOVE":
        updateFieldTranslation.removeLocalesFromFields(state.items, language.id);
        updateFieldTranslation.removeTitleForMatchingItems(itemsByFieldUuid, language.isoCode);
        break;
    }
  })
};

export default fieldModel;


// ------------------ UTILS ------------------

function handleRemove(state: any, language: any) {
  for (let key in state.items) {
    state.items[key]?.forEach((field: any) => {
      if (field.fieldOptions?.length !== 0) {
        field.fieldOptions.forEach((option: any) => {
          option.formOptionLocales = option.formOptionLocales.filter(
            (locale: any) => locale.cultureId !== language.id
          );
        });
      }
      let preferences = field.preferences.find(
        (preference: any) => preference.key.includes("ListItems") && preference.key !== "FixedListItemsDropdown"
      );
      let preferenceOptionOBJ = preferences ? JSON.parse(preferences.value) : [];
      preferenceOptionOBJ.forEach((option: any) => {
        option.formOptionLocales = option.formOptionLocales.filter(
          (locale: any) => locale.cultureId !== language.id
        );
      });
      if (preferences) {
        preferences.value = JSON.stringify(preferenceOptionOBJ);
      }
    });
  }
  state.translatedItems?.flat().forEach((item: any) => {
    if (item.options?.length !== 0) {
      item.options?.forEach((option: any) => {
        delete option[`title-${language.isoCode}`];
      });
    }
  });
}

function updateLocaleOption(locale: any, text: any) {
  if (locale) locale.option = text;
}

function updatePreferenceLocale(preferenceLocale: any, text: any, preferenceOBJ: any, payload: any, language: any) {
  if (typeof preferenceLocale !== "undefined" && preferenceLocale !== null) {
    preferenceLocale.option = text;
  } else {
    preferenceOBJ[payload.optionIndex]?.formOptionLocales?.push({
      option: text,
      cultureId: language.id
    });
  }
}

function handleUpdate(state: any, payload: any, language: any, text: any, defaultOption: any, section: any) {
  let fieldToUpdate: any = state.items[payload.fieldListUuid]?.find(
    (field: any) => field.uuid === payload.fieldUuid
  );
  
  if (fieldToUpdate?.fieldOptions) {
    let localeToUpdate = fieldToUpdate?.fieldOptions[payload.optionIndex]?.formOptionLocales.find((locale: any) => locale.cultureId === language.id);
    updateLocaleOption(localeToUpdate, text);
    
    let localeOptionToUpdate = fieldToUpdate?.options[payload.optionIndex]?.formOptionLocales.find((locale: any) => locale.cultureId === language.id);
    updateLocaleOption(localeOptionToUpdate, text);
  }

  
  let preferences = fieldToUpdate.preferences.find(
    (preference: any) => preference.key.includes("ListItems") && preference.key !== "FixedListItemsDropdown"
  );
  let preferenceOBJ = preferences ? JSON.parse(preferences.value) : [];
  let preferenceLocale = preferenceOBJ[payload.optionIndex]?.formOptionLocales?.find(
    (locale: any) => locale.cultureId === language.id
  );

  updatePreferenceLocale(preferenceLocale, text, preferenceOBJ, payload, language);
  
  preferenceOBJ[payload.optionIndex].option = defaultOption;
  if (preferences) {
    preferences.value = JSON.stringify(preferenceOBJ);
  }
  section?.forEach((item: any, index: number) => {
    if (index === payload.index) {
      if (item.options?.length !== 0) {
        item.options?.forEach((option: any, optionIndex: number) => {
          if (optionIndex === payload.optionIndex) option[`title-${language.isoCode}`] = text;
        });
      }
    }
  });
}

function handleAdd(state: any, language: any) {
  for (let key in state.items) {
    state.items[key]?.forEach((field: any, index: number) => {
      handleFieldOptions(field, language);
      handleOptions(field, language);
    });
  }
  state.translatedItems?.flat().forEach((item: any) => {
    if (item.options?.length !== 0) {
      item.options?.forEach((option: any) => {
        if (!option[`title-${language.isoCode}`]) option[`title-${language.isoCode}`] = "";
      });
    }
  });

}

function handleFieldOptions(field: any, language: any) {
  if (field.fieldOptions?.length !== 0) {
    field.fieldOptions?.forEach((option: any) => {
      let existingOptionLocale = option.formOptionLocales.find(
        (locale: any) => locale.cultureId === language.id
      );
      if (!existingOptionLocale) {
        option.formOptionLocales.push({
          cultureId: language.id,
          formOptionId: option.id,
          option: ""
        });
      }
    });
  }
}

function handleOptions(field: any, language: any) {
  if (field.options?.length !== 0) {
    field.options?.forEach((option: any) => {
      let existingOptionLocale = option.formOptionLocales.find(
        (locale: any) => locale.cultureId === language.id
      );
      if (!existingOptionLocale) {
        option.formOptionLocales.push({
          cultureId: language.id,
          formOptionId: option.id,
          option: ""
        });
      }
    });
  }
  let preferences = field.preferences.find(
    (preference: any) => preference.key.includes("ListItems") && preference.key !== "FixedListItemsDropdown"
  );
  let preferenceOptionOBJ = preferences ? JSON.parse(preferences.value) : [];
  preferenceOptionOBJ.forEach((option: any) => {
    option?.formOptionLocales?.push({
      cultureId: language.id,
      formOptionId: option.formOptionId,
      option: ""
    });
  });
  if (preferences) {
    preferences.value = JSON.stringify(preferenceOptionOBJ);
  }
}

