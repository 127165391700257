import React from "react";
import "./alertFullFormDescription.scss";
import { useTranslation } from "react-i18next";

const AlertWarning = () => {
  const { t } = useTranslation();
  return (
    <div className="alert">
      <span>{t("messages.max_filled_as_form_description")}</span>
    </div>
  );
};

export default AlertWarning;
