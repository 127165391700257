import { useRef, RefObject } from "react";

export const useScroll = <T>() => {
  const ref = useRef<T>(null);
  const scrollToItem = (ref: RefObject<HTMLElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  };
  return { ref, scrollToItem };
};
