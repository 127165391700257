export const msalConfig = {
  auth: {
    clientId: window.env.clientId,
    authority: "https://login.microsoftonline.com/651b43ce-ccb8-4301-b551-b04dd872d401",
    redirectUri: "/authRedirect.html",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
};

export const loginRequest = {
  scopes: ["api://" + window.env.clientId + "/access_as_user"]
};
