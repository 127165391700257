import React, { useEffect, useState } from "react";
import Select, { ActionMeta, MultiValue, PropsValue } from "react-select";
import TitleColumn from "../../atoms/TitleColumn/TitleColumn";
import { ItemPreference } from "../../../../repositories/interfaces";
import "./QrOrBarCode.scss";
import ErrorWarning from "../../atoms/AlertsPreference/alertFieldError";
import { useTranslation } from "react-i18next";

interface QrOrBarCodeProps {
  key: number;
  title: string;
  preference: any;
  onChangeFunction: (preference: ItemPreference) => void;
}
const QrOrBarCode = ({ preference, title, onChangeFunction }: QrOrBarCodeProps) => {
  const options = [
    { value: "qrcode", label: "Qr Code" },
    { value: "barcode", label: "Bar Code" }
  ];
  let option: { value: string; label: string }[] = [];
  const values = preference.value.split(",");
  values.forEach((item: string) => {
    if (item === "qrcode") option.push({ value: "qrcode", label: "Qr Code" });
    if (item === "barcode") option.push({ value: "barcode", label: "Bar Code" });
  });

  const [selectedOptions, setSelectedOptions] = useState<PropsValue<any>>(option);

  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderWidth: isError ? "2px !important" : "1px !important",
      borderColor: isError ? "red !important" : "#d8dde1"
    })
  };

  useEffect(() => {
    if (preference.value == "") {
      setIsError(true);
    } else {
      let abc: { value: string; label: string }[] = [];
      const values = preference.value.split(",");
      values.forEach((item: string) => {
        if (item === "qrcode") abc.push({ value: "qrcode", label: "Qr Code" });
        if (item === "barcode") abc.push({ value: "barcode", label: "Bar Code" });
        setSelectedOptions(abc);
      });
    }
  }, [preference.value, preference]);
  return (
    <div>
      <div id="typePreferenceTitle">
        <TitleColumn text={title} />
      </div>
      <div className="container">
        <Select
          isMulti
          name="qrorbarcode"
          value={selectedOptions}
          options={options}
          className={`basic-multi-select `}
          styles={customStyles}
          classNamePrefix="select"
          placeholder=""
          onChange={(event: MultiValue<any>, value: ActionMeta<any>) => {
            setSelectedOptions([...event]);
            const values = event.map((i: any) => i.value);
            preference.value = values.toString();
            onChangeFunction(preference);
            if (event.length === 0) {
              setIsError(true);
            } else {
              setIsError(false);
            }
          }}
        />
        {isError === true ? <ErrorWarning message={t("preferences.error_empty_qrcode")} /> : ""}
      </div>
    </div>
  );
};

export default QrOrBarCode;
