import * as CatalogRepository from "./CatalogRepository";
import { CatalogItem, CatalogTranslation } from "./interface/CatalogItem";
import { v4 as uuidv4 } from "uuid";

export const getCatalog = async (route: string) => {
  return CatalogRepository.getFieldsCatalog(route);
};

export const parseCatalogItem = (payload: CatalogTranslation) => {
  const item: CatalogItem = JSON.parse(JSON.stringify(payload.item));
  item.uuid = uuidv4();
  return item;
};
