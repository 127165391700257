import React from "react";

interface ISubtitle {
  message: string;
}
const Subtitle = ({ message }: ISubtitle) => {
  return <span className="subtitle">{message}</span>;
};

export default Subtitle;
