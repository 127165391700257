import React, { useState, useEffect } from "react";
import LanguageList from "./LanguageList";
import LanguageListItem from "./LanguageListItem";
import { LanguageItem } from "../interface/LanguageItem";
import {
  arrayContains,
  getSortedLanguageItems,
  existsLanguageInTranslations
} from "../LanguageUtil";
import { useStoreState, useStoreActions } from "../../../store/hooks";
import { useModalVisibility } from "../../modal";
import { ModalType } from "../../modal/enum/ModalType";
import { RhimDialog } from "rhim-ui";
import { useTranslation } from "react-i18next";

const LanguageContainer = () => {
  const { t } = useTranslation();
  const form = useStoreState((state) => state.form.form);
  const languages = useStoreState((state) => state.languages.items);
  const activeItems = useStoreState((state) => state.languages.activeItems);
  const selectedLanguage = useStoreState((state) => state.languages.selectedLanguage);
  const setActiveItems = useStoreActions((action) => action.languages.setActiveItems);
  const setSelectedLanguage = useStoreActions((action) => action.languages.selectLanguage);
  const updateLanguage = useStoreActions((action) => action.languages.updateLanguage);
  const allItems = languages.filter((item) => item.isoCode !== form?.defaultLanguage);

  allItems.sort((a, b) => {
    if (a.description > b.description) {
      return 1;
    } else if (b.description > a.description) {
      return -1;
    } else {
      return 0;
    }
  });

  const [sortedItems, setSortedItems] = useState<LanguageItem[]>(
    getSortedLanguageItems(activeItems, allItems)
  );
  const { modalProperties, setModalProperties } = useModalVisibility({
    visible: false
  });

  useEffect(() => {}, [selectedLanguage]);
  useEffect(() => {
    setSortedItems(getSortedLanguageItems(activeItems, allItems));
  }, [activeItems, allItems]);
  useEffect(() => {
    const languagesInTitle = form ? Object.keys(form).filter((key) => key.includes("title-")) : [];
    const formattedLanguages = languagesInTitle.map((language) => language.split("title-")[1]);
    const activeLanguages = languages.filter((language: any) =>
      formattedLanguages.includes(language.isoCode)
    );
    setActiveItems(activeLanguages);
  }, [form, languages, setActiveItems]);

  const makeItemActive = (item: LanguageItem) => {
    const sortActiveItems = (a: LanguageItem, b: LanguageItem) => {
      if (a.description > b.description) {
        return 1;
      } else if (b.description > a.description) {
        return -1;
      } else {
        return 0;
      }
    };

    const sortedActiveItems = [...activeItems, item].sort(sortActiveItems);
    setActiveItems(sortedActiveItems);
  };

  const selectLanguage = (item: LanguageItem) => {
    if (selectedLanguage?.isoCode !== item.isoCode) {
      if (!existsLanguageInTranslations(item, form?.formLocales)) {
        updateLanguage({
          language: item,
          type: "ADD"
        });
      }
      setSelectedLanguage(item);
    }
    if (!arrayContains(activeItems, item)) {
      makeItemActive(item);
    }
  };

  const deleteLanguage = (item: LanguageItem) => {
    updateLanguage({
      language: item,
      type: "REMOVE"
    });
    const newActiveItems = activeItems.filter((activeItem) => activeItem.id !== item.id);
    setActiveItems(newActiveItems);
    if (selectedLanguage?.isoCode === item.isoCode) {
      setSelectedLanguage(undefined);
    }
  };

  const handleDelete = (item: LanguageItem) => {
    setModalProperties({
      visible: true,
      message: "Are you sure you want to delete " + item.description + "?",
      type: ModalType.Error,
      confirmText: "DELETE",
      onConfirmFunction() {
        deleteLanguage(item);
        let defaultFormLanguage = languages.find(
          (languages: LanguageItem) => languages.isoCode === form?.defaultLanguage
        );
        setSelectedLanguage(defaultFormLanguage);
      }
    });
  };

  const render = (items: LanguageItem[]) => {
    return items.map((item, i) => (
      <LanguageListItem
        item={item}
        isLanguageSelected={selectedLanguage?.isoCode === item.isoCode}
        isItemActive={arrayContains(activeItems, item)}
        selectLanguage={selectLanguage}
        deleteLanguage={handleDelete}
        key={i}
      />
    ));
  };

  return (
    <>
      <LanguageList
        title={
          languages.find((language) => language.isoCode === form?.defaultLanguage)?.description ||
          ""
        }
        items={sortedItems}
        render={render}
      />
      <RhimDialog
        isMobile={false}
        title={<p className="modal-title">{t("common.delete")}</p>}
        isOpen={modalProperties.visible}
        confirm={{
          title: modalProperties.confirmText ?? t("common.confirm").toUpperCase(),
          handleFunction: () => {
            if (modalProperties.onConfirmFunction) modalProperties.onConfirmFunction();
            setModalProperties({ visible: !modalProperties.visible });
          }
        }}
        cancel={{
          title: t("common.cancel").toUpperCase(),
          handleFunction: () => {
            setModalProperties({ visible: !modalProperties.visible });
          }
        }}
        content={<p className="modal-text">{modalProperties.message}</p>}
      />
    </>
  );
};

export default LanguageContainer;
