import { rhimTheme } from "rhim-ui";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export const getHeaderStyles = makeStyles(() => {
  const primaryColor = rhimTheme.palette.primary.main;
  return createStyles({
    languages: {
      "&>.Dropdown-control": {
        border: "none",
        cursor: "pointer"
      },
      "& .Dropdown-placeholder": {
        fontWeight: "bold",
        color: primaryColor
      },
      "& .open-close-arrow": {
        color: primaryColor
      },
      "& .Dropdown-menu": {
        width: "80%",
        marginLeft: "6px"
      }
    },
    separationBar: {
      borderLeft: "1px solid #d8dde1",
      height: "30px",
      margin: "0 8px"
    },
    userName: {
      fontSize: "14px",
      padding: "0 10px",
      color: "#003262",
      fontFamily: "Neutrif Pro"
    },
    logoutButton: {
      paddingLeft: "7px",
      cursor: "pointer"
    }
  });
});

export const getHeaderContainerStyle = () => {
  return {
    rightSideContainer: {
      flexBasis: "66.5%"
    },
    header: { padding: "0 0 0 25px" }
  };
};

export const useStyles = () => {
  return makeStyles((theme) =>
    createStyles({
      containerLogo: {
        margin: 0,
        marginLeft: 5,
        padding: 0,
        width: 62,
        height: 37
      },
      title: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "Neutrif Pro"
      }
    })
  );
};
