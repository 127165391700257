import React, { useState, useEffect } from "react";
import "./SectionListItem.scss";
import { useStoreActions, useStoreState } from "../../../../store/hooks";

import { ThunkCreator } from "easy-peasy";
import { Section } from "../../../../models/Section/Section";
import { useScroll } from "../../../hooks/useScroll";
import { useTranslation } from "react-i18next";
import { Icon, SvgIcon } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFontAwesomeIcon } from "../../../../helpers/iconFabric";
import { FormSectionLocaleModel } from "../../../../models/Section/FormSectionLocaleModel";
import { LanguageItem } from "../../../../feature/languages/interface/LanguageItem";
import { CatalogItem } from "../../../../feature/catalog/interface/CatalogItem";
import { Dictionary } from "lodash";
import FieldsList from "../../organisms/FieldsList/FieldsList";

const SectionListItem = (props: { section: Section; removeSection: ThunkCreator<any, any> }) => {
  const { t } = useTranslation();
  const { section, removeSection } = props;
  const { uuid, title, index } = section;
  const selectSection = useStoreActions((actions) => actions.sections.selectSection);
  const isSelected = useStoreState((state) => state.sections.selectedSection?.uuid === uuid);
  const languages = useStoreState((state) => state.languages.items);
  const defaultLanguage = useStoreState((state) => state.form.form?.defaultLanguage);
  const updateSection = useStoreActions((actions) => actions.sections.updateSection);
  const moveSectionUp = useStoreActions((actions) => actions.sections.moveSectionUp);
  const moveSectionDown = useStoreActions((actions) => actions.sections.moveSectionDown);
  const items: Dictionary<CatalogItem[]> = useStoreState((state) => state.fields.items) || [];

  const onSelectField = useStoreActions((actions) => actions.fields.selectField);
  const [openStatus, setOpenStatus] = useState(true);
  const { ref, scrollToItem } = useScroll<HTMLDivElement>();
  const updateIsSaved = useStoreActions((actions) => actions.form.updateIsSaved);
  const deselectField = useStoreActions((actions) => actions.fields.deselectField);
  const setFieldsInDescription = useStoreActions((actions) => actions.form.setFieldsInDescription);
  const fieldsInDescription: CatalogItem[] = useStoreState(
    (state) => state.form.fieldsInDescription
  );

  const removeFieldsInFormDescription = () => {
    fieldsInDescription.forEach((f) => {
      items[uuid].forEach((i) => {
        if (i.uuid === f.uuid) {
          setFieldsInDescription({ field: f, action: "pop" });
        }
      });
    });
  };
  useEffect(() => {
    if (isSelected) scrollToItem(ref);
  }, [section, isSelected, ref, scrollToItem]);

  const updateSectionTitle = (newTitle: string) => {
    let cultureIdDefaultLanguage = languages.find(
      (language: LanguageItem) => language.isoCode === defaultLanguage
    )?.id;
    let localeToUpdate = section.formSectionLocales.find(
      (locale: FormSectionLocaleModel) => locale.cultureId === cultureIdDefaultLanguage
    );
    if (localeToUpdate) {
      localeToUpdate.title = newTitle;
    }
    const updatedSection: Section = {
      ...section,
      title: newTitle
    };
    updateSection(updatedSection);
    updateIsSaved(false);
  };

  return (
    <div className="section-list-item" ref={ref}>
      <div
        className={
          (isSelected ? "selected-style " : "unselected-style ") + (openStatus ? "open-status" : "")
        }
        onClick={() => {
          if (!isSelected) {
            selectSection(section);
          }
        }}>
        <article className={`body-content ${!openStatus ? "closed-section" : ""}`}>
          <section className="section-header">
            <Icon
              className="section-icon-button open-close-button"
              onClick={() => {
                setOpenStatus(!openStatus);
              }}>
              {openStatus ? "expand_less" : "expand_more"}
            </Icon>
            <input
              type="text"
              value={t(title).toString()}
              className="input-title"
              onClick={(event) => {
                deselectField();
                selectSection(section);
              }}
              onChange={(event) => updateSectionTitle(event.target.value)}
            />
            <div className={isSelected ? "move-buttons" : "move-buttons invisible"}>
              <SvgIcon
                className="section-icon-button"
                onClick={() => {
                  moveSectionUp(index);
                  updateIsSaved(false);
                }}>
                <FontAwesomeIcon icon={getFontAwesomeIcon("fa-sortUp")} />
              </SvgIcon>
              <SvgIcon
                className="section-icon-button"
                onClick={() => {
                  moveSectionDown(index);
                  updateIsSaved(false);
                }}>
                <FontAwesomeIcon icon={getFontAwesomeIcon("fa-sortDown")} />
              </SvgIcon>
              <Icon
                className={
                  isSelected
                    ? "trash-button section-icon-button"
                    : "trash-button invisible section-icon-button"
                }
                onClick={() => {
                  removeSection(section);
                  removeFieldsInFormDescription();
                  updateIsSaved(false);
                }}>
                delete
              </Icon>
            </div>
          </section>
          {openStatus && (
            <section
              className={openStatus || items[uuid].length === 0 ? "fields open-status" : "fields"}>
              <FieldsList items={items[uuid]} onSelectField={onSelectField} />
              {isSelected && (items[uuid] !== undefined ? items[uuid].length === 0 : true) && (
                <div className="section-message">
                  <p>{t("messages.empty_section")}</p>
                </div>
              )}
            </section>
          )}
        </article>
      </div>
    </div>
  );
};

export default SectionListItem;
