import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ListItem, Typography, Grid, Collapse, Icon, ClickAwayListener } from "@material-ui/core";
import { AddCircle, Description, Feedback, Help } from "@material-ui/icons";
import { isMenuItemActive } from "../navigationUtils";
import { getMenuItemStyles } from "../styles";

const MenuItem = (props: any) => {
  const floatDivSubMenu = useRef(null);
  const { menuItem, menuOpened, selectedMenuItem, selectMenuItem, openSubmenu, showItems } = props;
  const [showExpander, setShowExpander] = useState(false);
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const { label, icon, id } = menuItem;
  const stringId = String(id);
  const activeMenuItem = isMenuItemActive(selectedMenuItem, stringId);
  const { item, activeItem, activeText, activeIcon } = getMenuItemStyles(
    activeMenuItem,
    mouseIsOver
  );
  const { t } = useTranslation();

  const onClickItem = (menuItem: any) => {
    selectMenuItem(menuItem);
    setMouseIsOver(false);
  };

  useEffect(() => {
    const delayExpanderIcon = () => {
      if (menuOpened) {
        setTimeout(() => {
          setShowExpander(menuOpened);
        }, 400);
      } else {
        setShowExpander(false);
      }
    };
    delayExpanderIcon();
  }, [menuOpened]);

  const renderIcon = () => {
    switch (icon) {
      case "new_form":
        return <AddCircle style={activeIcon} />;
      case "my_forms":
        return <Description style={activeIcon} />;
      case "feedback":
        return <Feedback style={activeIcon} />;
      default:
        return <Help style={activeIcon} />;
    }
  };

  const onClickAwayMenu = () => {
    if (mouseIsOver) {
      setMouseIsOver(false);
    }
  };

  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={onClickAwayMenu}>
        <div
          ref={floatDivSubMenu}
          onMouseLeave={() => {
            setMouseIsOver(false);
          }}
          onMouseOver={(event) => {
            setMouseIsOver(true);
          }}
        >
          <ListItem
            id={`menu-item-${label}`}
            button
            onClick={() => onClickItem(menuItem)}
            style={{ ...item, ...activeItem }}
          >
            <Grid container direction="row" justifyContent="space-between">
              <Grid item md={3} lg={3} xs={3}>
                {renderIcon()}
              </Grid>
              <Grid item md={9} lg={9} xs={9}>
                {menuOpened && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle2" style={activeText}>
                      {t(label)}
                    </Typography>
                    {showItems && showExpander ? (
                      <Icon style={activeText}>
                        {showExpander && openSubmenu ? "expand_less" : "expand_more"}
                      </Icon>
                    ) : null}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </ListItem>
        </div>
      </ClickAwayListener>
      <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
        {showExpander && props.subMenu}
      </Collapse>
    </React.Fragment>
  );
};

const MenuItemMemo = React.memo(MenuItem, (prevProps, nextProps) => {
  return (
    prevProps.menuOpened === nextProps.menuOpened &&
    prevProps.menuItem.label.menuOpened === nextProps.menuItem.label.menuOpened &&
    prevProps.selectedMenuItem === nextProps.selectedMenuItem &&
    prevProps.openSubmenu === nextProps.openSubmenu
  );
});

export default MenuItemMemo;
