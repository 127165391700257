import { action, thunk } from "easy-peasy";
import {
  compareRules,
  compareSections,
  convertForm,
  convertFormAttributes,
  convertSectionAttributes,
  delete_form,
  duplicate,
  getFieldsInDescription,
  getForm,
  getFormLanguageList,
  getFormWithDefaultFormLocale,
  getNewForm,
  getUserForms,
  saveForm,
  toggleActive,
  updateForm,
  validateForm
} from "../FormManager/FormManager";
import {
  mapFormRules,
  parseFormApiToStore,
  parseFormStoreToApi,
  stringfyFormRulesTargetOptions
} from "../FormUtil";

import { CatalogItem } from "../../catalog/interface/CatalogItem";
import { Dictionary } from "../../../models/Dictionary";
import { Form } from "../interface/Form";
import { FormModelInterface } from "./FormModelInterface";
import { FormRule } from "../../rules/interface/rulesInterface";
import { FormUser } from "../interface/FormUser";
import { LanguageItem } from "../../languages/interface/LanguageItem";
import { ModalType } from "../../modal/enum/ModalType";
import UpdatePreference from "../../../helpers/updateOldPreferences";
import { ValidationResult } from "../../../repositories/interfaces";

const formModel: FormModelInterface = {
  form: null,
  pastForm: null,
  formLanguageList: [],
  items: [],
  isSaved: true,
  fieldsInDescription: [],
  initFieldsInDescription: action((state: any, fields: CatalogItem[]) => {
    state.fieldsInDescription = fields;
  }),

  setFieldsInDescription: action(
    (state: any, obj: { field: CatalogItem; action: "push" | "pop" }) => {
      if (obj.action === "push") {
        const findField = state.fieldsInDescription.find(
          (f: CatalogItem) => f.uuid === obj.field.uuid
        );

        if (!findField) {
          state.fieldsInDescription.push(obj.field);
        }
      }
      if (obj.action === "pop") {
        const filteredList = state.fieldsInDescription.filter(
          (f: CatalogItem) => f.uuid !== obj.field.uuid
        );
        state.fieldsInDescription = filteredList;
      }
    }
  ),
  createForm: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const { loading, sections, fields, rules, languages: languageActions } = getStoreActions();
    const { languages } = getStoreState();

    let languageList = languages.items;
    const formLanguageList = getFormLanguageList(payload, languageList);
    actions.setFormLanguageList(formLanguageList);
    try {
      payload = getFormWithDefaultFormLocale(payload, languageList);
      loading.setLoading(true);
      const { auth } = getStoreState();
      payload.ownerEmail = auth.userData?.unique_name ? auth.userData?.unique_name : "";
      const form = parseFormStoreToApi(payload, languageList, formLanguageList);
      const formDefaultLanguage = formLanguageList.find(
        (language: LanguageItem) => language.isoCode === form.defaultLanguage
      );
      languageActions.selectLanguage(formDefaultLanguage);
      languageActions.setFormDefaultLanguage(formDefaultLanguage);
      const responseForm = await saveForm(form);
      const parsedResponseForm = parseFormApiToStore(responseForm.data, languageList);

      actions.clearFormData();

      const { sections: sectionItems, fields: fieldItems } =
        convertFormAttributes(parsedResponseForm);
      const updatedFields: Dictionary<CatalogItem[]> = UpdatePreference(fieldItems);
      sections.setItems(sectionItems);
      sections.selectSection(sectionItems[0]);
      fields.setItems(updatedFields);
      rules.setRules([]);
      actions.initFieldsInDescription([]);

      return parsedResponseForm;
    } finally {
      loading.setLoading(false);
    }
  }),
  setForm: action((state, payload) => {
    state.form = { ...payload };
  }),
  setPastForm: thunk(async (actions, payload, { getStoreState }) => {
    const { form: payloadForm, t } = payload;

    const {
      fields: fieldsState,
      languages: languagesState,
      rules: rulesState,
      sections: sectionsState,
      form: formState
    } = getStoreState();

    formState.pastForm = getNewForm();
    formState.pastForm.formSections = convertSectionAttributes(
      sectionsState.items,
      fieldsState.items,
      languagesState.items,
      payloadForm.defaultLanguage,
      t
    );

    formState.pastForm.formRules = stringfyFormRulesTargetOptions(rulesState.rules);
  }),
  setItems: action((state, payload) => {
    state.items = payload.map((item) => item);
  }),
  getForms: thunk(async (actions, payload, { getStoreActions }) => {
    const { t } = payload;
    const { loading, notification } = getStoreActions();
    try {
      loading.setLoading(true);
      const forms = await getUserForms();
      actions.setItems(forms);
    } catch (error) {
      notification.setProperties({
        confirmText: t("common.ok").toUpperCase(),
        message: t("messages.error_support"),
        title: `${t("messages.oops")}!`,
        type: ModalType.Error,
        visible: true
      });
    } finally {
      loading.setLoading(false);
    }
  }),
  updateForm: action((state, payload) => {
    state.form = { ...payload };
    state.isSaved = false;
  }),
  updatePastForm: action((state) => {
    state.pastForm = getNewForm();
    state.pastForm!.formSections = [...state.form!.formSections];
    state.pastForm!.formRules = [...state.form!.formRules];
  }),
  clearFormData: action((state) => {
    state.form = getNewForm();
  }),
  selectForm: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const { sections, fields, loading, rules, languages: languageActions } = getStoreActions();

    const { languages } = getStoreState();
    let languageList = languages.items;
    const { form } = payload;
    try {
      loading.setLoading(true);
      const result: Form = await getForm(form.formId);
      const newItem: Form = convertForm(result, languageList);

      const formLanguageList = getFormLanguageList(result, languageList);

      let formDefaultLanguage = result.defaultLanguage;
      let languageToSelect = languageList.find(
        (language: LanguageItem) => language.isoCode === formDefaultLanguage
      );
      languageActions.setFormDefaultLanguage(languageToSelect);
      languageActions.selectLanguage(languageToSelect);

      const { sections: sectionItems, fields: fieldItems } = convertFormAttributes(newItem);
      const updatedFields: Dictionary<CatalogItem[]> = UpdatePreference(fieldItems);

      let formRules: FormRule[] = [];
      formRules = mapFormRules(newItem.formRules);

      sections.setItems(sectionItems);
      sections.selectSection(sectionItems[0]);
      fields.setItems(updatedFields);
      rules.setRules(formRules);
      actions.setForm(newItem);
      actions.setPastForm(payload);

      const fieldsInDescription: CatalogItem[] = getFieldsInDescription(fieldItems);

      actions.setFormLanguageList(formLanguageList);

      actions.initFieldsInDescription(fieldsInDescription);
    } catch (error: any) {
      throw error;
    } finally {
      loading.setLoading(false);
    }
  }),
  save: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const { form: payloadForm, t } = payload;
    const { loading, notification, form } = getStoreActions();
    const { languages } = getStoreState();
    let languageList = languages.items;

    try {
      loading.setLoading(true);
      const { fields, languages, rules, sections, form: formState } = getStoreState();
      const fieldsState = fields;
      const sectionsState = sections;
      const constructorSectionTitle = "constructor.section_title";
      const translatedSectionTitle = t(constructorSectionTitle).toString();

      sectionsState.items
        .filter((section) => section.title === constructorSectionTitle)
        .forEach((section) => (section.title = translatedSectionTitle));

      payloadForm.formSections = convertSectionAttributes(
        sectionsState.items,
        fieldsState.items,
        languages.items,
        payloadForm.defaultLanguage,
        t
      );
      payloadForm.formRules = stringfyFormRulesTargetOptions(rules.rules);
      const validateResult: ValidationResult = validateForm(
        payloadForm,
        sectionsState.items,
        fieldsState.items,
        t
      );

      if (!validateResult.success) {
        notification.setProperties({
          confirmText: t("common.ok").toUpperCase(),
          message: validateResult.message,
          title: validateResult.title,
          type: ModalType.Info,
          visible: true
        });
      } else {
        payloadForm.formUsers = payloadForm.formUsers.map((user: FormUser) => ({
          userEmail: user.userEmail,
          userGuid: user.userGuid,
          formId: user.formId
        }));

        if (formState.pastForm == null) {
          payloadForm.maintainUpdatedAt = false;
        } else {
          const formSections = formState.form!.formSections;
          const pastFormSections = formState.pastForm!.formSections;
          const isSectionsChanged = compareSections(formSections, pastFormSections);

          const formRules = formState.form!.formRules;
          const pastFormRules = formState.pastForm!.formRules;
          const isRulesChanged = compareRules(formRules, pastFormRules);

          payloadForm.maintainUpdatedAt = !(isSectionsChanged || isRulesChanged);
          
        }

        const parsedPayload = parseFormStoreToApi(
          JSON.parse(JSON.stringify(payloadForm)),
          languageList,
          formState.formLanguageList,
          t
        );

        const response = await updateForm(parsedPayload);
        if (!response.success) throw new Error(response.message);
        notification.setProperties({
          message: t("messages.saved"),
          title: `${t("common.saved")}!`,
          confirmText: t("common.ok").toUpperCase(),
          type: ModalType.Success,
          visible: true
        });

        form.updateIsSaved(true);
        if (response.data) {
          form.updateFormModificationInformation({
            updatedAt: response.data.updatedAt,
            updatedBy: response.data.updatedBy
          });
        }
        actions.updatePastForm();
      }
    } catch (error: any) {
      notification.setProperties({
        confirmText: t("common.ok").toUpperCase(),
        message:
          error?.response?.data?.message !== ""
            ? t(error?.response?.data?.message)
            : t("messages.error_support"),
        title: `${t("messages.oops")}`,
        type: ModalType.Error,
        visible: true
      });
    } finally {
      loading.setLoading(false);
    }
  }),
  toggleActive: thunk(async (actions, payload, { getStoreActions, getState }) => {
    const { form, t } = payload;
    const { loading, notification } = getStoreActions();
    try {
      const { items } = getState();
      loading.setLoading(true);
      const result = await toggleActive(form.formId);
      const updatedFormIndex = items.findIndex((upForm) => upForm.formId === form.formId);
      if (!result.success) throw new Error();
      items[updatedFormIndex].enable = !items[updatedFormIndex].enable;
      getState().items = [...items];
    } catch (error) {
      notification.setProperties({
        confirmText: t("common.ok").toUpperCase(),
        message: t("messages.error_support"),
        title: `${t("messages.oops")}`,
        type: ModalType.Error,
        visible: true
      });
    } finally {
      loading.setLoading(false);
    }
  }),
  duplicateForm: thunk(async (actions, payload, { getStoreActions, getState }) => {
    const { num, t, formTitle } = payload;
    const { loading, notification } = getStoreActions();
    try {
      const { items } = getState();
      loading.setLoading(true);
      const result = await duplicate(num,formTitle!);
      if (!result.success) throw new Error();
      getState().items = [...items];
    } catch (error) {
      notification.setProperties({
        confirmText: t("common.ok").toUpperCase(),
        message: t("messages.error_support"),
        title: `${t("messages.oops")}`,
        type: ModalType.Error,
        visible: true
      });
    } finally {
      loading.setLoading(false);
    }
  }),
  deleteForm: thunk(async (actions, payload, { getStoreActions, getState }) => {
    const { num, t } = payload;
    const { loading, notification } = getStoreActions();
    try {
      const { items } = getState();
      loading.setLoading(true);
      const result = await delete_form(num);
      if (!result.success) throw new Error();
      getState().items = [...items.filter((item) => item.formId !== num)];
    } catch (error) {
      notification.setProperties({
        confirmText: t("common.ok").toUpperCase(),
        message: t("messages.error_support"),
        title: `${t("messages.oops")}`,
        type: ModalType.Error,
        visible: true
      });
    } finally {
      loading.setLoading(false);
    }
  }),
  updateIsSaved: action((state, payload) => {
    state.isSaved = payload;
  }),
  updateFormModificationInformation: action((state, payload) => {
    state.form!.updatedAt = payload["updatedAt"];
    state.form!.updatedBy = payload["updatedBy"];
  }),
  updateTranslation: action((state, payload) => {
    const { language, text, type } = payload;
    if (state.form) {
      if (type === "ADD" && !state.form[`title-${language.isoCode}`]) {
        let formLanguageListToUpdate: LanguageItem[] = state.formLanguageList;
        formLanguageListToUpdate.push(language);
        if (!state.form.formLocales.find((locale: any) => locale.cultureId === language.id)) {
          state.form.formLocales.push({
            cultureId: language.id,
            description: "",
            formId: state.form.formId,
            title: ""
          });
        }

        state.form[`title-${language.isoCode}`] = "";
      }
      if (type === "UPDATE") {
        let localeToUpdate = state.form.formLocales?.find(
          (locale: any) => locale.cultureId === language.id
        );
        if (localeToUpdate) localeToUpdate.title = text;

        state.form[`title-${language.isoCode}`] = text;
      }
      if (type === "REMOVE") {
        state.formLanguageList = state.formLanguageList.filter((oldLanguage: LanguageItem) => {
          return oldLanguage.id !== language.id;
        });

        state.form.formLocales = state.form.formLocales.filter((locale: any) => {
          return locale.cultureId !== language.id;
        });
        delete state.form[`title-${language.isoCode}`];
      }
    }
  }),
  setFormLanguageList: action((state, payload) => {
    state.formLanguageList = payload;
  })
};

export default formModel;
