import "./styles/LanguageList.scss";

import { LanguageItem } from "../interface/LanguageItem";
import React from "react";

export interface LanguagesListProps {
  title: string;
  items: LanguageItem[];
  render: (items: LanguageItem[]) => JSX.Element[];
}

const LanguageList = (props: LanguagesListProps) => {
  const { title, items, render } = props;
  return (
    <article className="languages">
      <section className="languages-header">
        <p>{title}</p>
      </section>
      <section>{render(items)}</section>
    </article>
  );
};

export default LanguageList;
