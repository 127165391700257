import React from "react";
import { useStoreState, useStoreActions } from "../../../store/hooks";
import { ConfirmationModal } from "../../modal";
import { ModalType } from "../../modal/enum/ModalType";

const NotificationModalContainer = () => {
  const properties = useStoreState((state) => state.notification.properties);
  const setProperties = useStoreActions((actions) => actions.notification.setProperties);
  const handleVisibility = () => {
    setProperties({
      title: "",
      message: "",
      confirmText: "",
      visible: false,
      type: ModalType.Success
    });
  };

  return (
    <ConfirmationModal
      title={properties.title!}
      message={properties.message!}
      confirmationText={properties.confirmText!}
      type={properties.type!}
      visibility={properties.visible}
      setVisibility={handleVisibility}
    />
  );
};

export default NotificationModalContainer;
