import React from "react";
import "./SecondaryMenuItem.scss";
import { Icon } from "@material-ui/core";

interface SecondaryMenuItemProps {
  id?: string;
  icon: string;
  path: string;
  title: string;
  isSelected: boolean;
  onClick: () => void;
}

const SecondaryMenuItem = (props: SecondaryMenuItemProps) => {
  const { icon, title, isSelected, onClick } = props;

  return (
    <div className={isSelected ? "item selected" : "item"}>
      <Icon onClick={onClick}>{icon}</Icon>
      <div className={"tooltip-header tooltip-header-" + title.toLowerCase()}>
        <span className="tooltip-header-text">{title}</span>
      </div>
    </div>
  );
};

export default SecondaryMenuItem;
