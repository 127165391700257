import React from "react";
import Loading from "./Loading";
import { useStoreState } from "../../../store/hooks";

const LoadingContainer = () => {
  const isLoading = useStoreState((state) => state.loading.isLoading);
  return <Loading isLoading={isLoading}></Loading>;
};

export default LoadingContainer;
