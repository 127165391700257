import React, { useEffect } from "react";
import "./AddSectionButton.scss";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { Section } from "../../../../models/Section/Section";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { Icon } from "@material-ui/core";
import { RhimButton } from "rhim-ui";
import { LanguageItem } from "../../../../feature/languages/interface/LanguageItem";
import { FormSectionLocaleModel } from "../../../../models/Section/FormSectionLocaleModel";
import { getAllSectionIds, getUniqueId } from "../../../../helpers/util";

const AddSectionButton = () => {
  const { t } = useTranslation();
  const items = useStoreState((state) => state.sections.items);
  const addSections = useStoreActions((actions) => actions.sections.add);
  const selectSection = useStoreActions((actions) => actions.sections.selectSection);
  const formLanguages = useStoreState((state) => state.form.formLanguageList);

  useEffect(() => {}, [items]);

  const addSection = () => {
    let formSectionLocales: FormSectionLocaleModel[] = [];
    formLanguages.forEach((language: LanguageItem) => {
      formSectionLocales.push({
        cultureId: language.id,
        title: t("constructor.section_title"),
        formSectionId: 0
      });
    });

    let uuidSection = uuidv4();
    const allSectionIds = getAllSectionIds(items);
    const sectionId = getUniqueId("", t("constructor.section_title"), allSectionIds);
    const model: Section = {
      id: 0,
      title: t("constructor.section_title"),
      index: items.length,
      uuid: uuidSection,
      score: false,
      sectionId: sectionId,
      formSectionLocales: formSectionLocales
    };
    addSections({ section: model, t });
    selectSection(model);
  };
  if (items.length === 0) {
    addSection();
  }

  return (
    <RhimButton
      variant="outlined"
      icon={<Icon>add</Icon>}
      title={t("common.section").toUpperCase()}
      onClick={() => addSection()}
    />
  );
};

export default AddSectionButton;
