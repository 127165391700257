import React from "react";
import { GraphUser } from "../../../../models/Graph/GraphUser";
import "./DataListItem.scss";

export interface DataListItemProps {
  item: GraphUser;
  onClick: (value: GraphUser) => void;
}

const DataListItem = (props: DataListItemProps) => {
  const { item, onClick } = props;
  return (
    <li
      data-id={item.userPrincipalName}
      className={"data-list-item"}
      onClick={() => {
        onClick(item);
      }}
    >
      {item.userPrincipalName.toLowerCase()}
    </li>
  );
};

export default DataListItem;
