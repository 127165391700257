import React from "react";
import "../styles/GlobalizationSection.scss";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { getPlaceholderLanguage } from "../../../helpers/util";
import { useTranslation } from "react-i18next";
import { LanguageItem } from "../../languages/interface/LanguageItem";
import { CatalogItem, FormFieldLocaleModel } from "../../catalog/interface/CatalogItem";

const GlobalizationSectionFieldList = (props: GlobalizationSectionFieldListProps) => {
  const { t } = useTranslation();
  const { fieldItem, index, selectedLanguage, isDefaultLanguage } = props;

  const updateFieldTitleTranslation = useStoreActions(
    (action) => action.fields.updateFieldTitleTranslation
  );
  const updateFieldDescriptionTranslation = useStoreActions(
    (action) => action.fields.updateFieldDescriptionTranslation
  );
  const updateOptionTranslation = useStoreActions(
    (action) => action.fields.updateOptionTranslation
  );

  const defaultLanguage = useStoreState((state) => state.form.form)?.defaultLanguage;

  const languages = useStoreState((state) => state.languages.items);

  const defaultCultureId = languages.find(
    (language: LanguageItem) => language.isoCode === defaultLanguage
  )?.id;

  const getFieldTypeTranslatedName = (fieldItem: CatalogItem) => {
    let result = "";
    if (fieldItem?.type) {
      result = t(`fields.${fieldItem.type}`);
    }
    return result;
  };

  const getFieldOptions = () => {
    let listOptionsString = fieldItem.preferences.find(
      (preference: any) =>
        preference.key.includes("ListItems") && preference.key !== "FixedListItemsDropdown"
    );

    let listOptionsObj = listOptionsString ? JSON.parse(listOptionsString.value) : [];

    return listOptionsObj;
  };

  const fieldOptions = getFieldOptions();
  const Title = TitleComponent(isDefaultLanguage, index, t, fieldItem, defaultCultureId);
  const Description = DescriptionComponent(isDefaultLanguage, t, fieldItem, defaultCultureId);
  const TitleInput = TitleInputComponent(
    t,
    selectedLanguage,
    isDefaultLanguage,
    fieldItem,
    updateFieldTitleTranslation,
    index
  );
  const DescriptionInput = DescriptionInputComponent(
    t,
    selectedLanguage,
    isDefaultLanguage,
    fieldItem,
    updateFieldDescriptionTranslation,
    index
  );

  const FieldAnswer = (
    <span
      className={selectedLanguage ? "globalization-field-title" : "globalization-default-answer"}>
      {`${getFieldTypeTranslatedName(fieldItem)} ${t("common.answer")}`}
    </span>
  );
  return (
    <div className="globalization-field-container">
      {!isDefaultLanguage && selectedLanguage ? (
        <div>
          <div>{Title}</div>
          <div>{TitleInput}</div>
          <div>{Description}</div>
          <div>{DescriptionInput}</div>
        </div>
      ) : (
        <div>
          <div>{Title}</div>
          <div>{Description}</div>
          <div>{FieldAnswer}</div>
        </div>
      )}
      {fieldOptions?.length !== 0 &&
        fieldOptions.map((option: any, fieldIndex: number) => {
          const defaultOption = option?.formOptionLocales?.find(
            (locale: any) => locale.cultureId === defaultCultureId
          )?.option;
          const Options = (
            <div className="globalization-field-option-container" key={`option-${fieldIndex}`}>
              <span
                className={
                  !isDefaultLanguage
                    ? "globalization-field-option-title"
                    : "globalization-default-answer"
                }>
                {`${String.fromCharCode(65 + fieldIndex)} - ${
                  option?.formOptionLocales?.find(
                    (locale: any) => locale.cultureId === defaultCultureId
                  )?.option
                }`}
              </span>
              {!isDefaultLanguage && selectedLanguage && (
                <input
                  className="globalization-field-option-input"
                  placeholder={`${t("globalization.option")} ${t(
                    getPlaceholderLanguage(selectedLanguage.isoCode),
                    selectedLanguage
                  )}`}
                  readOnly={isDefaultLanguage}
                  value={
                    option?.formOptionLocales?.find(
                      (locale: any) => locale.cultureId === selectedLanguage.id
                    )?.option ?? ""
                  }
                  onChange={(e) => {
                    updateOptionTranslation({
                      language: selectedLanguage,
                      text: e.target.value,
                      type: "UPDATE",
                      fieldListUuid: fieldItem.fieldListUuid,
                      index: index - 1,
                      optionIndex: fieldIndex,
                      fieldId: fieldItem.id,
                      fieldUuid: fieldItem.uuid,
                      defaultOption: defaultOption
                    });
                  }}
                />
              )}
            </div>
          );
          return Options;
        })}
    </div>
  );
};

interface GlobalizationSectionFieldListProps {
  fieldItem: any;
  index: number;
  selectedLanguage: LanguageItem | undefined;
  languageName: string;
  isDefaultLanguage: boolean;
}

export default GlobalizationSectionFieldList;

function DescriptionInputComponent(
  t: any,
  selectedLanguage: LanguageItem | undefined,
  isDefaultLanguage: boolean,
  fieldItem: any,
  updateFieldDescriptionTranslation: Function,
  index: number
) {
  return (
    <span>
      <input
        className="globalization-field-input"
        placeholder={`${t("globalization.field.description")} ${t(
          getPlaceholderLanguage(selectedLanguage?.isoCode),
          selectedLanguage
        )}`}
        value={
          fieldItem.formFieldLocales.find(
            (locale: FormFieldLocaleModel) => locale.cultureId === selectedLanguage?.id
          )?.description ?? ""
        }
        onChange={(event) => {
          updateFieldDescriptionTranslation({
            language: selectedLanguage,
            description: event.target.value,
            fieldListUuid: fieldItem.fieldListUuid,
            index: index - 1,
            fieldId: fieldItem.id,
            fieldUuid: fieldItem.uuid
          });
        }}
      />
    </span>
  );
}

function TitleInputComponent(
  t: any,
  selectedLanguage: LanguageItem | undefined,
  isDefaultLanguage: boolean,
  fieldItem: any,
  updateFieldTitleTranslation: Function,
  index: number
) {
  return (
    <span>
      <input
        className="globalization-field-input"
        placeholder={`${t("globalization.field")} ${t(
          getPlaceholderLanguage(selectedLanguage?.isoCode),
          selectedLanguage
        )}`}
        value={
          fieldItem.formFieldLocales.find(
            (locale: FormFieldLocaleModel) => locale.cultureId === selectedLanguage?.id
          )?.title ?? ""
        }
        onChange={(event) => {
          updateFieldTitleTranslation({
            language: selectedLanguage,
            title: event.target.value,
            type: "UPDATE",
            fieldListUuid: fieldItem.fieldListUuid,
            index: index - 1,
            fieldId: fieldItem.id,
            fieldUuid: fieldItem.uuid
          });
        }}
      />
    </span>
  );
}

function TitleComponent(
  isDefaultLanguage: boolean,
  index: number,
  t: any,
  fieldItem: any,
  defaultCultureId: number | undefined
) {
  return (
    <span>
      <span
        className={
          !isDefaultLanguage ? "globalization-field-title" : "globalization-default-title"
        }>
        {`${index}. ${t(
          fieldItem.formFieldLocales.find((locale: any) => locale.cultureId === defaultCultureId)
            ?.title ?? ""
        )}`}
      </span>
    </span>
  );
}

function DescriptionComponent(
  isDefaultLanguage: boolean,
  t: any,
  fieldItem: any,
  defaultCultureId: number | undefined
) {
  return (
    <span>
      <span
        className={
          !isDefaultLanguage
            ? "globalization-field-description"
            : "globalization-default-description"
        }>
        {t(
          fieldItem.formFieldLocales.find((locale: any) => locale.cultureId === defaultCultureId)
            ?.description ?? ""
        )}
      </span>
    </span>
  );
}
