import React from "react";
import { useTranslation } from "react-i18next";
import { ListItem, List, Icon, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { MenuItem } from "../navigationUtils";
import { getSubMenuItemStyles } from "../styles";

const SubMenuItems = (props: any) => {
  const classes = getSubMenuItemStyles();
  const { items, selectedMenuItem, showItems, selectMenuItem, menuOpened } = props;
  const { t } = useTranslation();

  const renderFiber = (activeSubMenuItem: boolean) => {
    if (activeSubMenuItem) {
      return (
        <Icon color="secondary" style={{ fontSize: 12 }}>
          fiber_manual_record
        </Icon>
      );
    }
    return null;
  };

  const iconCol = menuOpened ? 3 : 2;
  const labelCol = menuOpened ? 9 : 10;

  if (showItems) {
    return (
      <List component="div">
        {items.map((item: MenuItem, idx: number) => {
          const activeSubMenuItem = selectedMenuItem === String(item.id);
          const translatedLabel = t(item.label);
          return (
            <ListItem
              button
              key={`${item.id}-${idx}`}
              onClick={() => selectMenuItem(item)}
              className={classes.root}
            >
              <Grid container direction="row">
                <Grid
                  container
                  alignItems="center"
                  item
                  md={iconCol}
                  lg={iconCol}
                  xs={iconCol}
                  style={{ paddingLeft: menuOpened ? 9 : 0 }}
                >
                  {renderFiber(activeSubMenuItem)}
                </Grid>
                <Grid item md={labelCol} lg={labelCol} xs={labelCol}>
                  <Typography
                    variant={activeSubMenuItem ? "subtitle2" : "body2"}
                    style={{ color: "#fff" }}
                  >
                    {translatedLabel}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
    );
  }
  return null;
};

export default SubMenuItems;
